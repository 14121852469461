<template>
  <div v-if="Object.keys(faqsDatas).length > 0">
    <page-banner-two>
      <div class="col-md-7">
        <banner-title
          :tagline="pageBanner?.page_sub_title"
          :title="pageBanner?.page_title"
          :description="pageBanner?.page_title_description"
        />
      </div>
    </page-banner-two>
    <Breadcrumb :data="breadcrumb"/>
    <faqs-content
      :faqs-categories="categories"
      :faqs-data="faqsData"
      :activeCategory="activeCategory"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import PageBannerTwo from "@/components/shared-components/page-banner/PageBannerTwo.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import FaqsContent from "@/containers/business/faqs-content/FaqsContent.vue";

import { mapState } from "vuex";

export default {
  components: { PageBannerTwo, BannerTitle, FaqsContent, Breadcrumb },
  name: "Faqs",

  computed: {
    ...mapState({
      faqsDatas: (state) => state.faqs.faqsDatas,
      pageBanner: (state) => state.faqs.faqsDatas.page_banner,
      categories: (state) => state.faqs.faqsDatas.faqsCategories,
      faqsData: (state) => state.faqs.faqsDatas.faqsData,
      activeCategory: (state) => state.faqs.activeCategory,
    }),
  },

  beforeCreate(){
    this.breadcrumb = [
      {label: 'Home', url: '/'},
      {label: 'FAQ', url: '*'}
    ];
  },
  
  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("faqs/getFaqsData");
  },
};
</script>

<style lang="scss" scoped></style>
