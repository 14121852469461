<template>
  <svg
    width="13"
    height="18"
    viewBox="0 0 13 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.74219 6.51087C1.74219 4.88088 2.30322 3.66313 3.17206 2.85061C4.04622 2.0331 5.27299 1.58789 6.66516 1.58789C8.05615 1.58789 9.28308 2.03681 10.1578 2.85683C11.0275 3.67207 11.5881 4.89031 11.5881 6.51087C11.5881 8.87623 10.3097 11.2867 8.97065 13.1497C8.30764 14.0721 7.64372 14.8412 7.14553 15.3795C6.95878 15.5813 6.7957 15.7503 6.66516 15.8823C6.53463 15.7503 6.37155 15.5813 6.1848 15.3795C5.68661 14.8412 5.02269 14.0721 4.35968 13.1497C3.02062 11.2867 1.74219 8.87623 1.74219 6.51087ZM7.00475 16.9491L6.66516 16.5821C7.00475 16.9491 7.00486 16.949 7.00499 16.9489L7.00535 16.9485L7.00646 16.9475L7.01013 16.9441L7.02324 16.9318C7.0345 16.9212 7.05075 16.9059 7.07165 16.8859C7.11345 16.8459 7.17388 16.7874 7.25029 16.7116C7.40307 16.5601 7.61992 16.3392 7.87948 16.0587C8.3981 15.4983 9.08992 14.6971 9.78265 13.7333C11.1551 11.824 12.5881 9.19882 12.5881 6.51087C12.5881 4.64522 11.9348 3.15197 10.8417 2.12727C9.75377 1.10736 8.26921 0.587891 6.66516 0.587891C5.06231 0.587891 3.57759 1.10222 2.48902 2.12023C1.39513 3.14321 0.742188 4.63696 0.742188 6.51087C0.742188 9.19882 2.17525 11.824 3.54768 13.7333C4.24041 14.6971 4.93223 15.4983 5.45085 16.0587C5.71041 16.3392 5.92726 16.5601 6.08004 16.7116C6.15645 16.7874 6.21688 16.8459 6.25868 16.8859C6.27958 16.9059 6.29583 16.9212 6.30709 16.9318L6.3202 16.9441L6.32387 16.9475L6.32497 16.9485L6.32534 16.9489C6.32547 16.949 6.32558 16.9491 6.66516 16.5821L6.32558 16.9491C6.51724 17.1264 6.81309 17.1264 7.00475 16.9491ZM4.84058 6.51103C4.84058 5.50359 5.65728 4.6869 6.66472 4.6869C7.67216 4.6869 8.48885 5.50359 8.48885 6.51103C8.48885 7.51847 7.67216 8.33517 6.66472 8.33517C5.65728 8.33517 4.84058 7.51847 4.84058 6.51103ZM6.66472 3.6869C5.10499 3.6869 3.84058 4.95131 3.84058 6.51103C3.84058 8.07076 5.10499 9.33517 6.66472 9.33517C8.22444 9.33517 9.48885 8.07076 9.48885 6.51103C9.48885 4.95131 8.22444 3.6869 6.66472 3.6869Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "LocationSmIcon",
};
</script>

<style lang="scss" scoped></style>
