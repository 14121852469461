<template>
  <svg
    width="14"
    height="4"
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2132 0.297852H1.02872C0.514058 0.297852 0.0966797 0.71523 0.0966797 1.22989V2.16193C0.0966797 2.67659 0.514058 3.09397 1.02872 3.09397H12.2132C12.7278 3.09397 13.1452 2.67659 13.1452 2.16193V1.22989C13.1452 0.71523 12.7278 0.297852 12.2132 0.297852Z"
      fill="#898989"
    />
  </svg>
</template>

<script>
export default {
  name: "MinusIcon",
};
</script>

<style lang="scss" scoped></style>
