<template>
  <CRow class="justify-content-center">
    <CCol md="4">
      <order-summary :order="orderDetail" />
    </CCol>
    <CCol md="8">
      <div class="mb-4">
        <heading title="Return Request" />
        <p>
          Your return request will valid only if it meets our
          <router-link :to="'/general-page/return-policies'"
            >return policies</router-link
          >
          .
        </p>
      </div>
      <CCard class="p-4">
        <heading title="Item Info" />
        <div class="return-item mb-4 border-bottom pb-2 d-flex flex-wrap">
          <div class="me-3">{{item.qty}} &nbsp;&nbsp;&nbsp;&nbsp;X</div>
          <div class="item-info d-flex">
            <div class="me-3">
              <img :src="item.image" alt="" />
            </div>
            <div>{{ item.name }}</div>
          </div>
          <div class="fw-bold ms-4">Rs. {{ item.amount }}</div>
        </div>

        <div class="reason-to">
          <FormKit type="form" @submit="submitHandler" :actions="false">
            <CRow>
              <CCol md="12" class="mb-4">
                <div>
                  <h6>Reason to return</h6>
                  <p>Please explain in detail</p>
                </div>
                <FormKit
                  type="textarea"
                  name="reason"
                  placeholder="Reason"
                  validation="required"
                  validation-visibility="submit"
                />
              </CCol>
              <div class="mb-4">
                <FormKit
                  type="checkbox"
                  v-model="value"
                  name="terms"
                  validation="required"
                  validation-visibility="dirty"
                />
                <span>
                  I agree with
                  <router-link :to="'/general-page/terms-of-service'"
                    >Terms and conditions</router-link
                  >
                </span>
              </div>
            </CRow>
            <div class="">
              <button
                class="btn btn-primary text-white py-2 px-3"
                type="submit"
              >
                Submit
              </button>
            </div>
          </FormKit>
        </div>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import OrderSummary from "@/components/shared-components/order-summary/OrderSummary.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import router from "@/router";
const toast = useToast();
export default {
  name: "ReturnItem",
  components: { Heading, OrderSummary },
  data() {
    return {
      orderDetail: ref(""),
      item: ref(""),
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getReturnItemDetail(
      this.$route.params.orderId,
      this.$route.params.productId
    );
  },

  methods: {
    submitHandler: function (values) {
      const url = UrlConfig.urlHelper.hostUrl + "/return-order";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {
        order_product_id: this.item.order_product_id,
        order_id: this.orderDetail.id,
        note: values.reason,
        terms_and_conditions: values.terms ? 1 : 0,
      };
      axios
        .post(url, data, header)
        .then((response) => {
          if (response.data.status_code === 200) {
            toast.success("Item Return Request Sent Successfully", {
              timeout: 2000,
            });
            router.push('/');
          }
        })
        .catch((error) => {
          let errorResponse = error.response.data;
          if (errorResponse.data !== "") {
            let loop = 0;
            Object.entries(errorResponse.data.error).forEach(([key, value]) => {
              loop = loop + 1;
              let errorTimeAdd = 1000 * parseInt(loop);
              toast.error(value, {
                timeout: 2000 + parseInt(errorTimeAdd),
              });
            });
          } else {
            toast.error(errorResponse.message, {
              timeout: 2000,
            });
          }
        });
    },

    getReturnItemDetail(orderId, OrderProductId) {
      const url = UrlConfig.urlHelper.hostUrl + "/get-order-product-detail";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {
        order_product_id: OrderProductId,
        order_id: orderId,
      };
      axios
        .post(url, data, header)
        .then((response) => {
          let orderItemDetail = response.data.data;
          if (response.data.status_code === 200 && orderItemDetail !== "") {
            this.orderDetail = {
              id: orderItemDetail.id,
              amount: orderItemDetail.amount,
              addressLineOne: orderItemDetail.addressLineOne,
              addressLineTwo: orderItemDetail.addressLineTwo,
              phone: orderItemDetail.phone,
              orderStatus: orderItemDetail,
            };

            this.item = {
              id: orderItemDetail.item.id,
              name: orderItemDetail.item.name,
              qty: orderItemDetail.item.qty,
              amount: orderItemDetail.item.amount,
              date: orderItemDetail.item.date,
              image: orderItemDetail.item.image,
              order_product_id: orderItemDetail.item.order_product_id,
              product_id: orderItemDetail.item.product_id,
            };
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },

  computed: {
    isActive() {
      if (this.termAndCondition == true) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
