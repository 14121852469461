import axios from "axios";
import UrlConfig from "@/config/UrlConfig";
export default {
    namespaced: true,
    state: {
        categoryDetail: [],
    },

    actions: {
        fetchProductCategory: async (context) => {
            const url = UrlConfig.urlHelper.hostUrl + "/category-list";
            const header = UrlConfig.unAuthorizedUrlHeader;
            try {
                const response = await axios.get(url,  header);
                context.commit("SET_CATEGORY", response.data.data);
                return response.data.data;
            } catch (error) {
                // if (error.response) {
                //     payload.toast.push({
                //         color: "danger",
                //         content: error.response.data.message,
                //     });
                // }
            }
        },
    },

    mutations: {
        SET_CATEGORY: (state, data) => {
            state.categoryDetail = data;
        },
    },
};
