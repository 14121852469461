<template>
  <svg
    width="27"
    height="33"
    viewBox="0 0 27 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.17887 8.50346C7.17887 4.99166 10.0258 2.14478 13.5376 2.14478C17.0494 2.14478 19.8962 4.99166 19.8962 8.50346C19.8962 12.0153 17.0494 14.8621 13.5376 14.8621C10.0258 14.8621 7.17887 12.0153 7.17887 8.50346ZM13.5376 0.644775C9.19732 0.644775 5.67887 4.16323 5.67887 8.50346C5.67887 12.8437 9.19732 16.3621 13.5376 16.3621C17.8778 16.3621 21.3962 12.8437 21.3962 8.50346C21.3962 4.16323 17.8778 0.644775 13.5376 0.644775ZM1.84717 28.6446C1.84717 25.7691 4.11509 23.4707 6.87297 23.4707H20.2018C22.9597 23.4707 25.2276 25.7691 25.2276 28.6446V30.8566H1.84717V28.6446ZM6.87297 21.9707C3.25108 21.9707 0.347168 24.9767 0.347168 28.6446V31.6066C0.347168 32.0208 0.682954 32.3566 1.09717 32.3566H25.9776C26.3918 32.3566 26.7276 32.0208 26.7276 31.6066V28.6446C26.7276 24.9767 23.8237 21.9707 20.2018 21.9707H6.87297Z"
      fill="#ED1B24"
    />
  </svg>
</template>

<script>
export default {
  name: "CustomerIcon",
};
</script>

<style lang="scss" scoped></style>
