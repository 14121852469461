<template>
  <svg
    width="51"
    height="37"
    viewBox="0 0 51 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8356 36.3773H3.2531C1.46111 36.3773 0 34.9831 0 33.2757V16.7471C0 15.0369 1.45832 13.6455 3.2531 13.6455H29.8356C31.6285 13.6455 33.0887 15.0397 33.0887 16.7471V33.2757C33.0859 34.9859 31.6285 36.3773 29.8356 36.3773ZM3.2531 14.7609C2.07641 14.7609 1.11535 15.6522 1.11535 16.7471V33.2757C1.11535 34.3706 2.07362 35.2619 3.2531 35.2619H29.8356C31.0132 35.2619 31.9734 34.3706 31.9734 33.2757V16.7471C31.9734 15.6522 31.016 14.7609 29.8356 14.7609H3.2531Z"
      fill="black"
    />
    <path
      d="M42.2027 29.9674C41.8789 29.9671 41.5568 29.9205 41.2463 29.8289L32.3746 27.2794C32.2361 27.2356 32.1201 27.1394 32.0514 27.0113C31.9827 26.8833 31.9666 26.7335 32.0067 26.5938C32.0468 26.4541 32.1399 26.3356 32.2661 26.2635C32.3922 26.1914 32.5416 26.1714 32.6823 26.2077L41.5586 28.7582C42.6907 29.0918 43.8618 28.5072 44.1704 27.4569L48.8381 11.6013C48.9108 11.3496 48.9309 11.0856 48.8972 10.8257C48.8634 10.5659 48.7766 10.3158 48.642 10.0909C48.3552 9.60486 47.8927 9.24751 47.35 9.0927L21.8457 1.5864C21.3058 1.42216 20.7235 1.47174 20.2192 1.72489C19.9841 1.84086 19.7754 2.00406 19.6062 2.20427C19.437 2.40449 19.3109 2.63744 19.2358 2.88857L15.9028 14.3581C15.8582 14.496 15.7616 14.6112 15.6336 14.6791C15.5055 14.7471 15.3561 14.7625 15.2168 14.7221C15.0776 14.6818 14.9596 14.5888 14.8877 14.4629C14.8159 14.337 14.7959 14.188 14.832 14.0476L18.166 2.5772C18.2832 2.18003 18.481 1.81129 18.7471 1.49401C19.0132 1.17673 19.3419 0.917718 19.7126 0.733157C20.4704 0.34979 21.3467 0.272581 22.1599 0.517522L47.6651 8.02475C48.4812 8.25963 49.176 8.79899 49.6058 9.5314C49.8174 9.88719 49.9534 10.2827 50.0053 10.6934C50.0573 11.104 50.0241 11.521 49.9079 11.9183L45.2411 27.7711C44.8479 29.1076 43.5857 29.9674 42.2027 29.9674Z"
      fill="black"
    />
    <path
      d="M23.0796 32.4748C22.2586 32.475 21.456 32.2317 20.7733 31.7757C20.0906 31.3198 19.5584 30.6716 19.2441 29.9132C18.9297 29.1548 18.8473 28.3202 19.0073 27.5149C19.1673 26.7097 19.5625 25.9699 20.1429 25.3893C20.7233 24.8086 21.4628 24.4131 22.268 24.2528C23.0731 24.0924 23.9078 24.1745 24.6663 24.4885C25.4249 24.8025 26.0733 25.3344 26.5296 26.0169C26.9858 26.6994 27.2295 27.5019 27.2297 28.3229C27.2284 29.4233 26.7909 30.4784 26.0129 31.2567C25.2349 32.035 24.1801 32.473 23.0796 32.4748ZM23.0796 25.2891C22.4791 25.2889 21.892 25.4669 21.3927 25.8004C20.8933 26.1339 20.5041 26.6081 20.2742 27.1628C20.0444 27.7176 19.9843 28.3281 20.1014 28.9171C20.2186 29.506 20.5078 30.047 20.9325 30.4716C21.3572 30.8961 21.8983 31.1852 22.4873 31.3022C23.0763 31.4192 23.6867 31.3589 24.2414 31.1288C24.7962 30.8988 25.2702 30.5094 25.6036 30.01C25.9369 29.5105 26.1147 28.9234 26.1143 28.3229C26.1131 27.5185 25.7929 26.7474 25.2241 26.1787C24.6552 25.61 23.884 25.2901 23.0796 25.2891Z"
      fill="black"
    />
    <path
      d="M28.5961 19.38H4.28424C4.13633 19.38 3.99449 19.3212 3.8899 19.2167C3.78532 19.1121 3.72656 18.9702 3.72656 18.8223C3.72656 18.6744 3.78532 18.5326 3.8899 18.428C3.99449 18.3234 4.13633 18.2646 4.28424 18.2646H28.5961C28.744 18.2646 28.8858 18.3234 28.9904 18.428C29.095 18.5326 29.1537 18.6744 29.1537 18.8223C29.1537 18.9702 29.095 19.1121 28.9904 19.2167C28.8858 19.3212 28.744 19.38 28.5961 19.38Z"
      fill="black"
    />
    <path
      d="M48.4955 14.1029C48.443 14.1027 48.3908 14.0952 48.3403 14.0806L18.5726 5.45893C18.5022 5.43855 18.4365 5.4045 18.3793 5.35874C18.3221 5.31298 18.2745 5.2564 18.2391 5.19223C18.2038 5.12806 18.1814 5.05756 18.1733 4.98475C18.1652 4.91194 18.1716 4.83824 18.1919 4.76788C18.2123 4.69751 18.2464 4.63185 18.2921 4.57464C18.3379 4.51743 18.3945 4.46979 18.4586 4.43445C18.5228 4.39911 18.5933 4.37675 18.6661 4.36865C18.7389 4.36056 18.8126 4.36688 18.883 4.38726L48.6507 13.008C48.7811 13.0443 48.8938 13.1267 48.9679 13.24C49.042 13.3532 49.0724 13.4895 49.0534 13.6235C49.0344 13.7575 48.9673 13.88 48.8647 13.9682C48.762 14.0564 48.6308 14.1043 48.4955 14.1029Z"
      fill="black"
    />
    <path
      d="M48.0776 16.1924C48.0251 16.1923 47.9728 16.1848 47.9224 16.1701L18.1546 7.54937C18.0802 7.53285 18.0099 7.50124 17.9482 7.45649C17.8864 7.41175 17.8345 7.35481 17.7957 7.28921C17.7568 7.22361 17.7318 7.15073 17.7222 7.07508C17.7126 6.99944 17.7186 6.92263 17.7399 6.8494C17.7612 6.77617 17.7972 6.70808 17.8459 6.64934C17.8945 6.5906 17.9546 6.54246 18.0226 6.50788C18.0906 6.47331 18.1649 6.45304 18.241 6.44833C18.3171 6.44362 18.3934 6.45457 18.4651 6.48049L48.2319 15.1012C48.3614 15.1381 48.4731 15.2207 48.5465 15.3335C48.6199 15.4464 48.65 15.5821 48.6312 15.7154C48.6124 15.8487 48.546 15.9707 48.4442 16.0589C48.3425 16.1471 48.2122 16.1955 48.0776 16.1952V16.1924Z"
      fill="black"
    />
    <path
      d="M14.3373 27.0216H4.28424C4.13633 27.0216 3.99449 26.9628 3.8899 26.8583C3.78532 26.7537 3.72656 26.6118 3.72656 26.4639C3.72656 26.316 3.78532 26.1742 3.8899 26.0696C3.99449 25.965 4.13633 25.9062 4.28424 25.9062H14.3373C14.4852 25.9062 14.627 25.965 14.7316 26.0696C14.8362 26.1742 14.8949 26.316 14.8949 26.4639C14.8949 26.6118 14.8362 26.7537 14.7316 26.8583C14.627 26.9628 14.4852 27.0216 14.3373 27.0216Z"
      fill="black"
    />
    <path
      d="M14.3373 30.9357H4.28424C4.13633 30.9357 3.99449 30.8769 3.8899 30.7723C3.78532 30.6677 3.72656 30.5259 3.72656 30.378C3.72656 30.2301 3.78532 30.0882 3.8899 29.9837C3.99449 29.8791 4.13633 29.8203 4.28424 29.8203H14.3373C14.4852 29.8203 14.627 29.8791 14.7316 29.9837C14.8362 30.0882 14.8949 30.2301 14.8949 30.378C14.8949 30.5259 14.8362 30.6677 14.7316 30.7723C14.627 30.8769 14.4852 30.9357 14.3373 30.9357Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "OnlinePaymentIcon",
};
</script>

<style lang="scss" scoped></style>
