<template>
  <div class="header-actions">
    <div class="drop-parent">
      <div class="action-item" @click="handleShowSearch">
        <magnify-icon/>
      </div>
    </div>
    <div class="drop-parent" v-if="showAuthorizedNavIcon" title="Cart">
      <div class="action-item">
        <div class="badge-holder">
          <router-link to="/cart">
            <span class="badge-spec" v-if="cartItems?.length > 0">{{
                cartItems?.length > 0 ? cartItems?.length : null
              }}</span>
            <cart-icon/>
          </router-link>
        </div>
      </div>

    </div>
    <div class="drop-parent" v-if="showUserAuthIcon">
      <div class="action-item">
        <user-icon/>
      </div>
      <div class="drop-item">
        <CCard class="p-3">
          <div class="auth-drop">
            <ul class="auth-drop-menu">
              <li>
                <router-link to="/login">Login</router-link>
              </li>
              <li>
                <router-link to="/register">Register</router-link>
              </li>
            </ul>
          </div>
        </CCard>
      </div>
    </div>

    <div class="drop-parent" v-if="showAuthorizedNavIcon" title="Wishlist">
      <div class="action-item">
        <div class="badge-holder">
          <router-link to="/wishlist">
            <span class="badge-spec" v-if="wishlistItems?.length > 0">{{
                wishlistItems?.length
              }}</span>
            <wishlist-icon/>
          </router-link>
        </div>
      </div>
      <!--      <div class="drop-item">-->
      <!--        <CCard class="p-3">-->
      <!--          <wishlist-dropdown />-->
      <!--        </CCard>-->
      <!--      </div>-->
    </div>

    <div class="drop-parent" v-if="showAuthorizedNavIcon">
      <div class="action-item">
        <router-link to="/dashboard/notifications">
          <div class="badge-holder">
            <span class="badge-spec" v-if="totalNotifications > 0">{{
                totalNotifications
              }}</span>
            <notification-icon/>
          </div>
        </router-link>
      </div>
      <div class="drop-item">
        <CCard>
          <notification-dropdown :notificationData="notifications"/>
        </CCard>
      </div>
    </div>

    <div class="drop-parent" v-if="showUserAvatar">
      <div class="action-item">
        <div class="header-profile-pic">
          <img :src="avatarLink || avatar" alt="alt"/>
        </div>
      </div>

      <div class="drop-item">
        <CCard class="p-3">
          <div class="auth-drop">
            <ul class="auth-drop-menu">
              <li>
                <router-link to="/dashboard/profile">Dashboard</router-link>
              </li>
              <li>
                <router-link to="/dashboard/settings">Settings</router-link>
              </li>
              <li>
                <router-link to="/dashboard/my-orders">Orders</router-link>
              </li>
            </ul>
            <div class="mt-3">
              <button class="btn btn-primary btn-sm w-100" @click="logOut()">
                Logout
              </button>
            </div>
          </div>
        </CCard>
      </div>
    </div>
  </div>
</template>

<script>
import MagnifyIcon from "@/assets/svg/header-icons/MagnifyIcon.vue";
import CartIcon from "@/assets/svg/header-icons/CartIcon.vue";
import WishlistIcon from "@/assets/svg/header-icons/WishlistIcon.vue";
import UserIcon from "@/assets/svg/header-icons/UserIcon.vue";
import NotificationIcon from "@/assets/svg/header-icons/NotificationIcon.vue";
import CartDropdown from "@/components/shared-components/cart-dropdown/CartDropdown.vue";
import WishlistDropdown from "@/components/shared-components/wishlist-dropdown/WishlistDropdown.vue";
import NotificationDropdown from "@/components/shared-components/notification-dropdown/NotificationDropdown.vue";
import {helper} from "@/helper/Helper";
import {ref} from "vue";
import router from "@/router";
import {mapState} from "vuex";
import urlConfig from "@/config/UrlConfig";

export default {
  components: {
    MagnifyIcon,
    CartIcon,
    CartDropdown,
    WishlistIcon,
    NotificationIcon,
    UserIcon,
    WishlistDropdown,
    NotificationDropdown,
  },
  name: "HeaderActions",

  data() {
    return {
      showUserAuthIcon: true,
      showUserAvatar: false,
      showAuthorizedNavIcon: false,
      loggedIn: false,
      avatar: ref(""),
      userDetails: {},
    };
  },

  created() {
    this.checkIfUserIsLoggedIn();
    this.initFacebook();
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
  },

  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch("product/fetchCartItems");
      this.$store.dispatch("product/fetchWishlistItems");
      this.$store.dispatch("notification/fetchNotificationDetail");
    }
    this.getUserDetails();
  },

  computed: {
    ...mapState({
      cartItems: (state) => state.product.cartItems,
      wishlistItems: (state) => state.product.wishlistItems,
      notifications: (state) => state.notification.notifications,
      totalNotifications: (state) => state.notification.totalNotification,
      avatarLink: (state) => state.profile.avatarLink,
    }),
  },

  methods: {
    getUserDetails() {
      const userDetail = JSON.parse(localStorage.getItem("USERDETAILS"));

      this.userDetails = userDetail;
    },
    //To handle show/hide Nav search box
    handleShowSearch() {
      this.$emit("show-search", true);
    },

    async logOut() {
      await window.FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
          window.FB.logout(function (response) {

          });
        }
      },true);
      localStorage.clear();
      this.showUserAuthIcon = true;
      this.showUserAvatar = false;
      this.showAuthorizedNavIcon = false;
      this.loggedIn = false;
      router.go();
      router.push("/");
    },

    initFacebook() {
      const facebookId = urlConfig.socialLogin.facebook_app_id;
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: facebookId,
          cookie: true,
          xfbml: true,
          version: "v13.0",
          status: true,
        });
      };
    },

    loadFacebookSDK(d, s, id) {
      var js,
          fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },

    checkIfUserIsLoggedIn() {
      let helperObj = new helper();
      let userDetail = helperObj.checkLogin();
      if (userDetail) {
        this.showUserAuthIcon = false;
        this.showAuthorizedNavIcon = true;
        this.showUserAvatar = true;
        this.avatar = localStorage.getItem("avatar");
        this.loggedIn = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
