<template>
  <div class="nav-item">
    <ul class="parent-nav-items">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/about">About</router-link>
      </li>
      <li>
        <router-link to="/shop">Shop</router-link>

        <div class="nav-drop">
          <CCard style="width: 100%">
            <div class="nav-drop-content d-flex">
              <div
                  class="category-list p-3"
                  v-for="(category, index) in categoryDetail"
              >
                <div class="cat-items">
                  <div class="cat-items-list pb-2">
                    <h6 class="clickable" @click.prevent="redirectToItemDetailPageByCategory(category.slug)">
                      <a>{{ category.name }}</a>
                    </h6>
                    <div class="meta">
                    </div>
                    <div class="border-top mt-2 ms-2">
                      <ul>
                        <li v-for="child in category.child_category">
                          <a class="clickable" @click="redirectToItemDetailPageBySubcategory(child.slug)"> {{ child.name }} </a>
                        </li>
                        <li class="clickable" @click.prevent="redirectToItemDetailPageByCategory(category.slug)" >
                          <a>View All</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CCard>
        </div>
      </li>
      <li>
        <router-link to="/blogs">Blogs</router-link>
      </li>
      <li>
        <router-link to="/contact-us">Contact Us</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {ref} from "vue";
import {useToast} from "vue-toastification";
import {helper} from "@/helper/Helper";
const toast = useToast();

export default {
  name: "ShopNav",

  data() {
    return {
      categoryDetail: ref([]),
    };
  },

  mounted() {
    if (this.categoryDetail.length < 1) {
      this.getCategoryDetail();
    }
  },

  methods: {
    getCategoryDetail() {
      try {
        this.$store
            .dispatch("categories/fetchProductCategory")
            .then((categoryData) => {
              if (categoryData.length > 0) {
                Object.entries(categoryData).forEach(([key, value]) => {
                  this.categoryDetail.push({
                    id: ++key,
                    name: value.name,
                    slug: value.slug,
                    child_category: value.child_category
                        ? value.child_category
                        : [],
                    image: value.image_path,
                  });
                });
              }
            });
      } catch (e) {
        toast.error('something went wrong', {
          timeout: 2000,
        });
      }
    },

    redirectToItemDetailPageByCategory(category){
      let helperObj = new helper();
      helperObj.resetLocalStorageProductDataFilter();
      this.$router.push('/category/' + category)
    },

    redirectToItemDetailPageBySubcategory(subcategory){
      let helperObj = new helper();
      helperObj.resetLocalStorageProductDataFilter();
      this.$router.push('/category/' + subcategory)
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
</style>
