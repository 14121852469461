<template>
  <CContainer>
    <div class="row justify-content-md-center">
      <CCol md="7" lg="5">

        <CCard class="p-4">
          <div class="text-end">
            Go back to
            <router-link to="/login">Login</router-link>
          </div>
          <CDropdownDivider class="my-4" />
          <FormKit type="form" @submit="submitHandler" :actions="false">
            <div class="mb-4">
              <FormKit
                type="password"
                name="password"
                label="Password"
                placeholder="Password"
                validation="required|?length:6"
                :validation-messages="{
                  length: 'Try to make your password longer!',
                }"
                validation-visibility="submit"
              />
            </div>
            <div class="mb-4">
              <FormKit
                type="password"
                name="password_confirm"
                label="Confirm Password"
                placeholder="Password Confirm"
                validation="required|confirm"
                validation-visibility="submit"
              />
            </div>
            <div
              class="d-flex flex-wrap align-items-center justify-content-end"
            >
              <button
                class="btn btn-primary text-white py-2 px-3 change-pass-btn"
                type="submit"
              >
                Change Password
              </button>
            </div>
          </FormKit>
        </CCard>
      </CCol>
    </div>
  </CContainer>
</template>

<script>
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CDropdownDivider,
  CButton,
} from "@coreui/vue";
import GoogleIcon from "@/assets/svg/GoogleIcon.vue";
import FacebookIcon from "@/assets/svg/FacebookIcon.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import router from "@/router";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    CContainer,
    CRow,
    CCol,
    CCard,
    CDropdownDivider,
    CButton,
    GoogleIcon,
    FacebookIcon,
  },

  name: "ChangePassForm",

  methods: {
    submitHandler: function (values) {
      const path = UrlConfig.urlHelper.hostUrl +'/reset-customer-password';
      const header = UrlConfig.authorizedUrlHeader;
      let formData = {
        password: values.password,
        password_confirmation: values.password_confirm
      };
      axios.post(path,formData,header)
          .then(response => {
              router.push('/');
          })
          .catch((error) => {
            let errorResponse = error.response.data;
            if(errorResponse.data !== ''){
              let loop = 0;
              Object.entries(errorResponse.data.error).forEach(([key, value]) => {
                loop = loop +1
                let errorTimeAdd =  1000 * parseInt(loop);
                toast.error(value, {
                  timeout: 2000 + parseInt(errorTimeAdd) ,
                });
              });
            }else{
              toast.error(errorResponse.message, {
                timeout: 2000,
              });
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped></style>
