<template>
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.48857 2.17834L10.4353 6.36617C10.9244 6.88549 10.9248 7.69924 10.4361 8.21898L8.52823 10.2482C9.72619 13.9106 12.4052 16.8406 15.8006 18.2262L16.1808 18.3737L17.9893 16.4502C18.4972 15.91 19.344 15.8866 19.8806 16.398L23.8273 20.5858C24.3164 21.1052 24.3168 21.9189 23.8281 22.4386L22.8467 23.4825C21.0541 25.389 18.3243 25.9264 15.9267 24.8446C12.5375 23.3155 9.8028 21.4464 7.72247 19.2376C5.64214 17.0287 3.88109 14.124 2.43932 10.5237C1.46015 8.07857 1.88485 5.30677 3.50631 3.40218L3.71521 3.1687L4.59726 2.23056C5.10521 1.69031 5.95198 1.66693 6.48857 2.17834Z"
      stroke="#CDCDCD"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "PhoneMutedIcon",
};
</script>

<style lang="scss" scoped></style>
