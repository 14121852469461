<template>
  <svg
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.03027 2.09375L7.03027 18.0938"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0234 8.09375L7.43956 1.29155C7.38843 1.22938 7.32555 1.17959 7.2551 1.14549C7.18465 1.11139 7.10824 1.09375 7.03094 1.09375C6.95364 1.09375 6.87723 1.11139 6.80678 1.14549C6.73633 1.17959 6.67345 1.22938 6.62232 1.29155L1.03735 8.09375"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UpArrowIcon",
};
</script>

<style lang="scss" scoped></style>
