<template>
  <section>
    <page-banner :image="teamBannerDetail.page_image_path">
      <div class="col-md-7">
        <banner-title
          :tagline="teamBannerDetail.page_title"
          :title="teamBannerDetail.page_sub_title"
          :description="teamBannerDetail.page_title_description"
        />
      </div>
    </page-banner>
    <Breadcrumb :data="breadcrumb"/>
  </section>

  <team-list />
  <career-linkbanner />
  <core-values />
  <newsletter-sub />
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import PageBanner from "@/components/shared-components/page-banner/PageBanner.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import CareerLinkbanner from "@/containers/business/career-link-banner/CareerLinkbanner.vue";
import CoreValues from "@/containers/business/core-values/CoreValues.vue";
import TeamList from "@/containers/business/team-list/TeamList.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    Breadcrumb,
    PageBanner,
    BannerTitle,
    CareerLinkbanner,
    CoreValues,
    TeamList,
    NewsletterSub,
  },
  name: "Team",

  data() {
    return {
      teamBannerDetail: ref(""),
    };
  },

  beforeCreate(){
    this.breadcrumb = [
      {label: 'Home', url: '/'},
      {label: 'Team', url: '*'}
    ];
  },
  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getPageBanner();
  },

  methods: {
    async getPageBanner() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-page-banner/team_detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let pageDetail = response.data.data;
          if (response.data.status_code === 200 && pageDetail !== "") {
            this.teamBannerDetail = pageDetail;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
