<template>
  <section class="bg-gray py-5 mb-5">
    <CContainer>
      <CRow>
        <CCol md="12" lg="8" class="mb-lg-0 mb-4">
          <logged-in-checkout />
        </CCol>
        <CCol md="12" lg="4">
          <CCard class="p-4">
            <div class="mb-4">
              <heading title="Order Summary" />
            </div>

            <div>
              <h6>Shipping Method</h6>
              <p>
                <van-icon />
                {{ cart?.deliveryType }}
              </p>
            </div>

            <div class="order-items border-top border-bottom py-2 mb-4">
              <div
                class="d-flex justify-content-between text-muted mb-2"
                v-for="(item, index) in cartItems"
              >
                <span>{{ item.qty }} x {{ item.name }}</span>
                <span>Rs {{ item.amount }}</span>
              </div>
            </div>

            <div>
              <h5>Applied Coupon</h5>
              <p>{{ cart.appliedCoupon }}</p>
            </div>

            <div class="mb-4">
              <div class="d-flex justify-content-between mb-2">
                <span>Sub Total</span>
                <span>NRs. {{ cart.cartItemPrice }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span>Discount Amount</span>
                <span>Rs {{ cart.discount }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span>Shipping</span>
                <span>NRs. {{ cart.shipping }}</span>
              </div>
            </div>

            <div class="cart-total border-top pt-3">
              <div class="d-flex mb-2 justify-content-between">
                <span class="fw-bold"> Total </span>
                <span class="fw-bold"> NRs. {{ cart.netTotal }} </span>
              </div>
            </div>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </section>

  <section class="mb-5">
    <recommended-item-carousel
      sectionTitle="Recommended Items"
      subTitle=" own it now, up to 6 months interest free!"
      prevEl="recomPrevArrow"
      nextEl="recomNextArrow"
    />
  </section>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import VanIcon from "@/assets/svg/VanIcon.vue";
import LoggedInCheckout from "@/containers/e-commerce/loggedin-checkout/LoggedInCheckout.vue";
import NonLoggedIncCheckout from "@/containers/e-commerce/non-loggedin-checkout/NonLoggedIncCheckout.vue";
import ItemCarousel from "@/containers/e-commerce/item-carousel/ItemCarousel.vue";
import RecommendedItemCarousel from "@/containers/e-commerce/item-carousel/RecommendedItemCarousel";
import { ref } from "vue";
export default {
  name: "Checkout",
  components: {
    Heading,
    VanIcon,
    LoggedInCheckout,
    NonLoggedIncCheckout,
    ItemCarousel,
    RecommendedItemCarousel,
  },

  data() {
    return {
      cart: ref(""),
      cartItems: ref([]),
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getCartDetail();
  },

  methods: {
    getCartDetail() {
      let cartData = localStorage.getItem("cartData")
        ? JSON.parse(localStorage.getItem("cartData"))
        : {};
      this.cartItems = cartData.cartItems;
      this.cart = {
        cartId: cartData.cartId,
        shipping: cartData.shippingCharge,
        cartItemPrice: cartData.cartItemPrice,
        discount: cartData.discount,
        deliveryType: cartData.deliveryType,
        appliedCoupon: cartData.couponApplied,
        netTotal: cartData.netTotal,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
