<template>
  <Countdown
    :flipAnimation="false"
    :labels="labels"
    :deadline="time"
  />
</template>

<script>
import { Countdown } from "vue3-flip-countdown";
export default {
  name: "TimeCountDown",
  components: { Countdown },
  props:['time'],
  data() {
    return {
      labels: { days: "Days", hours: "Hours", minutes: "Min", seconds: "Sec" },
    };
  },
};
</script>

<style lang="scss" scoped></style>
