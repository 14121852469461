<template>
  <section>
    <page-banner-two>
      <div class="col-md-5">
        <banner-title
          :tagline="careerBannerDetail.page_title"
          :title="careerBannerDetail.page_sub_title"
          :description="careerBannerDetail.page_title_description"
        />
      </div>
    </page-banner-two>
  </section>
  <Breadcrumb :key="componentKey" :data="breadcrumb"/>

  <career-list-block :positions="careerListDetail" />

  <who-we-are />
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import CareerListBlock from "@/containers/business/career-list-block/CareerListBlock.vue";
import WhoWeAre from "@/containers/business/who-we-are/WhoWeAre.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import PageBannerTwo from "@/components/shared-components/page-banner/PageBannerTwo.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    CareerListBlock,
    WhoWeAre,
    BannerTitle,
    PageBannerTwo,
    Breadcrumb,
  },
  name: "CareerList",

  data() {
    return {
      careerBannerDetail: ref(""),
      careerListDetail: ref([]),
      componentKey: ref(0),
    };
  },

  created(){  
    this.breadcrumb = [
                {label: 'Home', url: '/'},
                {label: 'Careers', url: '/'}
              ];  
  },

  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getCareerPageBannerDetail();
    this.getCareerPageListByDepartment(this.$route.params.id);
  },

  methods: {
    getCareerPageBannerDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-page-banner/career_list";
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
        .get(url, header)
        .then((response) => {
          let careerPageBannerDetail = response.data.data;
          if (
            response.data.status_code === 200 &&
            careerPageBannerDetail !== ""
          ) {
            Object.entries(careerPageBannerDetail).forEach(([key, value]) => {
              this.careerBannerDetail = careerPageBannerDetail;
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    getCareerPageListByDepartment(departmentId) {
      const url =
        UrlConfig.urlHelper.hostUrl +
        "/get-career-by-department/" +
        departmentId;
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
        .get(url, header)
        .then((response) => {
          let careerLists = response.data.data;
          if (response.data.status_code === 200 && careerLists.length > 0) {
            Object.entries(careerLists).forEach(([key, value]) => {
              this.careerListDetail.push(value);
            
              const title = value.title;
              this.breadcrumb.push({ label: title, url: `*` });
              this.componentKey += 1;
            });           
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
