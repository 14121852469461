<template>
  <section>
    <career-link-banner />
  </section>
  <Breadcrumb :data="breadcrumb"/>

  <section class="py-5">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <section-title
            className="text-center"
            :title="title"
            :subtitle="subtitle"
          />
        </CCol>
      </CRow>
    </CContainer>
  </section>
  <career-categories />
  <who-we-are />
  <newsletter-sub />
  <CToaster placement="top-end">
    <CToast :delay="time" v-for="(toast, index) in toasts" :key="index">
      <CAlert class="m-0" :color="toast.color"> {{ toast.content }}</CAlert>
    </CToast>
  </CToaster>
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import PageBanner from "@/components/shared-components/page-banner/PageBanner.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import CareerCategories from "@/containers/business/career-categories/CareerCategories.vue";
import WhoWeAre from "@/containers/business/who-we-are/WhoWeAre.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import CareerLinkbanner from "@/containers/business/career-link-banner/CareerLinkbanner";
import CareerLinkBanner from "@/containers/business/career-link-banner/CareerLinkbanner";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";

export default {
  components: {
    Breadcrumb,
    CareerLinkBanner,
    PageBanner,
    BannerTitle,
    SectionTitle,
    CareerCategories,
    WhoWeAre,
    NewsletterSub,
    CareerLinkbanner,
  },
  name: "Career",

  data() {
    return {
      title: ref(""),
      subtitle: ref(""),
      time: 5000,
      toasts: [],
      toastMessage: "Toast Message",
      toastColor: "success",
    };
  },

  beforeCreate(){
    this.breadcrumb = [
      {label: 'Home', url: '/'},
      {label: 'Career', url: '*'}
    ];
  },
  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getCareerSectionDetail();
  },

  methods: {
    getCareerSectionDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-about-us-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
        .get(url, header)
        .then((response) => {
          let aboutUsDetail = response.data.data;
          if (response.data.status_code === 200 && aboutUsDetail !== "") {
            this.title = aboutUsDetail.career_title;
            this.subtitle = aboutUsDetail.career_sub_title;
          }
        })
        .catch((error) => {
          this.toasts.push({
            color: "danger",
            content: error.response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
