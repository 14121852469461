import axios from "axios";
import UrlConfig from "@/config/UrlConfig";

export default {
    namespaced: true,
    state: {
        aboutDetail: {},
    },
    getters: {},
    actions: {
        fetchAboutContent: async ({commit}) => {
            const url = UrlConfig.urlHelper.hostUrl;
            const header = UrlConfig.unAuthorizedUrlHeader;
            const aboutBannerDetail = await axios.get(
                url + "/get-page-banner/about_us",
                header
            );
            const aboutDetail = await axios.get(url + "/get-about-us-detail", header);

            const youtubeSlidingContent = await axios.get(
                url + "/youtube-sliding-content",
                header
            );
            const coreValues = await axios.get(url + "/get-core-value", header);
            const slidingContent = await axios.get(url + "/sliding-content", header);
            const teamMembers = await axios.get(url + "/get-feature-team", header);
            const careerBanner = await axios.get(
                url + "/get-page-banner/career_list",
                header
            );
            const testimonials = await axios.get(
                url + "/get-testimonial-list",
                header
            );
            const riderStories = await axios.get(
                url + "/get-rider-story-list",
                header
            );
            const showRooms = await axios.get(url + "/get-featured-showroom", header);
            const brands = await axios.get(url + "/brand-list", header);

            const aboutBody = {
                title: aboutDetail.data.data.about_us_title,
                subtitle: aboutDetail.data.data.about_us_sub_title,
                description: aboutDetail.data.data.about_us_description,
            };

        },
    },
    mutations: {
        SET_ABOUTCONTENT: (state, data) => {

        },
    },
};
