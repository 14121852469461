<template>
  <CCard class="p-4 mt-4 mt-md-0">
    <heading
        title="My Past Orders"
        :description="
        'There are ' + totalPastOrderItem + ' products in past orders'
      "
    />

    <div>
      <CRow class="align-items-end">
        <CCol md="4" class="mb-4">
          <label>Start Date</label>
          <datepicker
              v-model="startDate"
              :clear-button="true"
              name="startDate"
          />
        </CCol>
        <CCol md="4" class="mb-4">
          <label>End Date</label>
          <datepicker v-model="endDate" name="endDate"/>
        </CCol>
        <CCol md="4" class="mb-4">
          <button class="btn btn-primary" @click.prevent="getAllMyPastOrders">
            Filter
          </button>
        </CCol>
      </CRow>
    </div>

    <div class="table-responsive desktop-orders">
      <table class="table orders-listing">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Item</th>
          <th scope="col">Qty</th>
          <th scope="col">Amount</th>
          <th scope="col">Date</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in ordersData" :key="item.id">
          <th>{{ item.id }}</th>
          <td>
            <div class="d-flex" style="max-width: 200px">
              <div class="me-3">
                <img :src="item.image" alt=""/>
              </div>
              <div>
                {{ item.name }}
              </div>
            </div>
          </td>
          <td>{{ item.qty }}</td>
          <td>Rs.{{ item.amount }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.status }}</td>
          <td v-if="item.return_status">
            <router-link
                :to="'/dashboard/' + item.orderId + '/return/' + item.id"
            >Return
            </router-link
            >
          </td>
          <td v-else> - </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="resp-orders">
      <CCard class="p-4 mt-3" v-for="item in ordersData" :key="item.id">
        <div class="mb-4">Order Id: {{ item.id }}</div>
        <div class="mb-2">
          <div class="d-flex">
            <div class="me-3">
              <img style="width: 50px" :src="item.image" alt=""/>
            </div>
            <div>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div>Quantity: {{ item.qty }}</div>
        <div>Amount: {{ item.amount }}</div>
        <div>Date: {{ item.date }}</div>
        <div>Status: {{ item.status }}</div>
        <div class="mt-4" v-if="item.return_status">
          <router-link
              :to="'/dashboard/' + item.orderId + '/return/' + item.id"
          >Return
          </router-link>
        </div>
        <div v-else> - </div>
      </CCard>
    </div>
    <Pagination
        :data="pageDetail"
        @pagination-change-page="getAllMyPastOrders"
    />
  </CCard>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import Datepicker from "vue3-datepicker";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {ref} from "vue";
import Pagination from "laravel-vue-pagination";
import moment from "moment";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  name: "PastOrders",
  components: {Heading, Datepicker, Pagination},

  data() {
    return {
      totalPastOrderItem: 0,
      ordersData: ref([]),
      pageDetail: ref(""),
      startDate: new Date(""),
      endDate: new Date(""),
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getAllMyPastOrders();
  },

  methods: {
    async getAllMyPastOrders(page = 1) {
      let fromDate =
          this.startDate == "Invalid Date"
              ? null
              : this.formatDate(this.startDate);
      let toDate =
          this.endDate == "Invalid Date" ? null : this.formatDate(this.endDate);
      const url =
          UrlConfig.urlHelper.hostUrl +
          "/get-past-order?page=" +
          page +
          "&start_date=" +
          fromDate +
          "&end_date=" +
          toDate;
      const header = UrlConfig.authorizedUrlHeader;

      await axios
          .get(url, header)
          .then((response) => {
            let pastOrderDetail = response.data.data;
            if (response.data.status_code === 200 && pastOrderDetail !== "") {
              this.ordersData = [];
              let pastOrderItem = pastOrderDetail.order_list;
              this.pageDetail = pastOrderDetail.page_details;
              this.totalPastOrderItem = pastOrderDetail.page_details.total ?? 0;
              Object.entries(pastOrderItem).forEach(([key, value]) => {
                this.ordersData.push({
                  orderId: value.id,
                  name: value.name,
                  qty: value.qty,
                  amount: value.amount,
                  status: value.status,
                  date: value.date,
                  image: value.image,
                  return_status: value.return_status,
                  id: value.order_product_id,
                });
              });
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
    },

    formatDate(dateString) {
      return moment(String(dateString)).format("MM/DD/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped></style>
