<template>
  <section class="bg-gray py-5 mb-5">
    <CContainer v-if="showPaymentSection">
      <CRow>
        <CCol md="12" lg="8" class="mb-4">
          <CCard class="p-4">
            <div>
              <h6>Select Payment Method</h6>
              <CRow>
                <CCol md="6" class="mb-md-0 mb-4">
                  <CCard
                    :class="
                      selectedPayment === 'cashOnDelivery'
                        ? 'h-100 selected-block'
                        : 'h-100'
                    "
                    @click="() => handlePayment('cashOnDelivery')"
                  >
                    <div class="text-center p-4 block-card">
                      <div class="mb-2">
                        <cash-on-delivery-icon />
                      </div>
                      <h6>Cash on delivery</h6>
                    </div>
                  </CCard>
                </CCol>
                <CCol md="6" class="mb-md-0 mb-4">
                  <CCard
                    :class="
                      selectedPayment === 'onlinePayment'
                        ? 'h-100 selected-block'
                        : 'h-100'
                    "
                    @click="() => handlePayment('onlinePayment')"
                  >
                    <div class="text-center p-4 block-card">
                      <div class="mb-2">
                        <online-payment-icon />
                      </div>
                      <h6>Online Payment</h6>
                    </div>
                  </CCard>
                </CCol>
              </CRow>

              <div class="mt-3" v-if="selectedPayment === 'onlinePayment'">
                <div>
                  <h6>Please select your preferred payment Gateway</h6>
                </div>
                <span
                  v-for="(payment, index) in paymentGateways"
                  :key="payment.id"
                  @click="() => handlePaymentGateway(payment)"
                  :class="
                    selectedPaymentGateway === index
                      ? 'payment-gateways-selection seleted-payment me-3'
                      : 'payment-gateways-selection me-3'
                  "
                  ><img :src="payment.icon" alt=""
                /></span>
              </div>

              <div class="mt-4" v-show="showPlaceOrderBtn">
                <button
                  class="btn btn-primary"
                  :disabled="!isActive"
                  @click.prevent="confirmOrderWithCOD"
                >
                  <CSpinner
                    v-if="confirming"
                    component="span"
                    size="sm"
                    aria-hidden="true"
                  />

                  {{ confirming ? "wait..." : "CONFIRM ORDER" }}
                </button>
              </div>
            </div>
          </CCard>
        </CCol>

        <CCol md="12" lg="4">
          <CCard class="p-4">
            <div class="mb-4">
              <heading title="Order Summary" />
            </div>

            <div>
              <h6>Shipping Method</h6>
              <p>
                <van-icon />
                {{ orderDetail?.deliveryType }}
              </p>
            </div>

            <div class="order-items border-top border-bottom py-2 mb-4">
              <div
                class="d-flex justify-content-between text-muted mb-2"
                v-for="(item, index) in orderItems"
              >
                <!--                  <p>{{item}}</p>-->
                <span>{{ item.qty }} x {{ item.name }} </span>
                <div>
                  <div class="item-attr">
                    <div class="color-holder" v-if="item.productColor">
                      <div
                        class="color"
                        :style="`background: ${item.productColor}`"
                      ></div>
                    </div>
                    <div class="size-holder ms-2" v-if="item.productSize">
                      <div class="size">
                        {{ item.productSize }}
                      </div>
                    </div>
                  </div>
                </div>
                <span>Rs {{ item.amount }}</span>
              </div>
            </div>

            <div>
              <h5>Applied Coupon</h5>
              <p>{{ orderDetail?.appliedCoupon }}</p>
            </div>

            <div class="mb-4">
              <div class="d-flex justify-content-between mb-2">
                <span>Sub Total</span>
                <span>NRs. {{ orderDetail?.cartItemPrice }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span>Discount Amount</span>
                <span>Rs {{ orderDetail?.discount }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span>Shipping</span>
                <span>NRs. {{ orderDetail?.shipping }}</span>
              </div>
            </div>

            <div class="cart-total border-top pt-3">
              <div class="d-flex mb-2 justify-content-between">
                <span class="fw-bold"> Total </span>
                <span class="fw-bold"> NRs. {{ orderDetail?.netTotal }} </span>
              </div>
            </div>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <CContainer v-if="!showPaymentSection">
      <CRow>
        <CCol md="12" lg="12" class="mb-4">
          <div class="text-center">
            <div class="no-order-display mx-auto mb-5">
              <img src="/img/no-order.svg" alt="" />
            </div>

            <div class="greet-text text-center">
              <p>Order detail not found !!</p>
              <div class="mt-3">
                <router-link to="/shop">Continue Shopping</router-link>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>

    <div class="loading-overlay" v-if="verifyingSection">
      <h5>
        <span class="me-3"> <CSpinner color="light" /></span>
        Verifying...
      </h5>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import CashOnDeliveryIcon from "@/assets/svg/CashOnDeliveryIcon.vue";
import OnlinePaymentIcon from "@/assets/svg/OnlinePaymentIcon.vue";
import UrlConfig from "@/config/UrlConfig";
import { useToast } from "vue-toastification";
import axios from "axios";
import router from "@/router";
const toast = useToast();

export default {
  name: "Payment",
  components: {
    CashOnDeliveryIcon,
    OnlinePaymentIcon,
  },

  data() {
    return {
      paymentGateways: [
        { id: 0, name: "Esewa", icon: "/img/payments/esewa.png" },
      ],
      selectedPayment: ref(""),
      selectedPaymentGateway: "",
      paymentGateway: ref(""),
      orderItems: ref([]),
      orderDetail: ref(""),
      confirming: false,
      showPaymentSection: true,
      verifyingSection: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getOrderDetail();
  },

  created() {
    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("payment") == "su") {
      this.verifyingSection = true;
      let payload = {
        oid: urlParams.get("oid"),
        amt: urlParams.get("amt"),
        refId: urlParams.get("refId"),
      };
      this.verifyOnlinePayment(payload);
    }

    if (urlParams.get("payment") == "fu") {
      toast.error("Payment failed, please try again...", {
        timeout: 3000,
      });
      history.replaceState(null, null, "/payment");
    }
  },

  methods: {
    getOrderDetail() {
      let cartData = localStorage.getItem("orderDetail")
        ? JSON.parse(localStorage.getItem("orderDetail"))
        : {};
      const url = UrlConfig.urlHelper.hostUrl + "/get-order-detail";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {
        order_code: cartData.order_code,
      };
      axios
        .post(url, data, header)
        .then((response) => {
          let orderResponseDetail = response.data.data;
          if (response.data.status_code == 200 && orderResponseDetail !== "") {
            this.showPaymentSection = true;
            this.orderItems = orderResponseDetail.item;
            this.orderDetail = {
              shipping: orderResponseDetail.shippingCharge,
              cartItemPrice: orderResponseDetail.cartItemPrice,
              discount: orderResponseDetail.discount,
              deliveryType: orderResponseDetail.deliveryType ?? "pick up",
              appliedCoupon: orderResponseDetail.couponApplied,
              netTotal: orderResponseDetail.netTotal,
              orderId: orderResponseDetail.orderId,
              subTitle: orderResponseDetail.sub_total,
            };
          }
        })
        .catch((error) => {
          this.showPaymentSection = false;
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    confirmOrderWithCOD() {
      this.confirming = true;
      const url = UrlConfig.urlHelper.hostUrl + "/order-payment";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {
        order_code: this.orderDetail.orderId,
      };
      axios
        .post(url, data, header)
        .then((response) => {
          if (response.data.status_code == 200) {
            toast.success(response.data.message, {
              timeout: 2000,
            });
            localStorage.removeItem("orderDetail");
          }
          this.confirming = false;
          router.push("/dashboard/my-orders");
        })
        .catch((error) => {
          this.confirming = false;
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    handlePayment(type) {
      this.selectedPayment = type;
    },

    handlePaymentGateway(payment) {
      this.selectedPaymentGateway = payment.id;
      this.paymentGateway = payment.name;
      let path = UrlConfig.urlHelper.esewa_url;
      let successUrl = UrlConfig.urlHelper.esewa_success_url;
      let failureUrl = UrlConfig.urlHelper.esewa_failure_url;
      let mercentCode = UrlConfig.urlHelper.merchant_code;
      let params = {
        amt: parseInt(this.orderDetail.netTotal),
        psc: 0,
        pdc: 0,
        txAmt: 0,
        tAmt: parseInt(this.orderDetail.netTotal),
        pid: this.orderDetail.orderId,
        scd: mercentCode,
        su: successUrl,
        fu: failureUrl,
      };
      this.pay(path, params);
    },

    pay(path, params) {
      let form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", path);
      for (let key in params) {
        let hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }
      document.body.appendChild(form);
      form.submit();
    },

    verifyOnlinePayment(payload) {
      const url = UrlConfig.urlHelper.hostUrl + "/check-esewa-transaction";
      const header = UrlConfig.authorizedUrlHeader;
      axios
        .post(url, payload, header)
        .then((response) => {
          if (response.data.status_code == 200) {
            toast.success(response.data.message, {
              timeout: 2000,
            });
            localStorage.removeItem("orderDetail");
          }
          this.verifyingSection = false;
          router.push("/dashboard/my-orders");
        })
        .catch((error) => {
          this.verifyingSection = false;
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },

  computed: {
    showPlaceOrderBtn() {
      if (this.selectedPayment == "onlinePayment") {
        return false;
      }
      return true;
    },

    isActive() {
      if (this.selectedPayment !== "") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
