<template>
  <div id="breadcrumb">breadcrumb here</div>
  <center>
    <h1>Not Found!</h1>
    <section>
      <item-carousel
        sectionTitle="Featured Items"
        subTitle="own it now, up to 6 months interest free!"
        prevEl="featuredPrevArrow"
        nextEl="featuredNextArrow"
      />
    </section>
  </center>
</template>

<script>
import ItemCarousel from "@/containers/e-commerce/item-carousel/ItemCarousel.vue";

export default {
  components: {
    ItemCarousel,
  },

  name: "PageNotFound",
};
</script>

<style scoped>
center {
  margin: 10vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
