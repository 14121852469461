<template>
  <section class="py-5">
    <CContainer>
      <CRow>
        <CCol :md="{ span: 3, offset: 2 }">
          <ul class="faqs-menu">
            <li
              v-for="category in faqsCategories"
              :key="category.id"
              @click="this.$store.dispatch('faqs/setActiveQna', category.name)"
              class="mb-4"
            >
              <span v-if="category.icon === 'home'" class="menu-icn me-2">
                <img src="/img/svgs/faq-general.svg" alt="" />
              </span>
              <span v-if="category.icon === 'shop'" class="menu-icn me-2">
                <img src="/img/svgs/faq-shopping.svg" alt="" />
              </span>
              <span v-if="category.icon === 'lock'" class="menu-icn me-2">
                <img src="/img/svgs/faq-security.svg" alt="" />
              </span>
              <span>
                {{ category.name }}
              </span>
            </li>
          </ul>
        </CCol>
        <CCol md="6">
          <div v-for="qna in activeCategory" :key="qna.question"></div>
          <CAccordion>
            <CAccordionItem
              v-for="qna in activeCategory"
              :key="qna.id"
              :item-key="qna.id"
              class="mb-3 border rounded"
            >
              <CAccordionHeader> {{ qna.question }}</CAccordionHeader>
              <CAccordionBody>
                <div class="p-3" v-html="qna.answer"></div>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
export default {
  name: "FaqsContent",
  props: ["faqsCategories", "faqsData", "activeCategory"],
};
</script>

<style lang="scss" scoped></style>
