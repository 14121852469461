<template>
  <div class="nav-search">
    <div class="info-to-shop">
      Go to <router-link to="/shop">Shop</router-link> for advanced search
      <span style="margin: 0 2rem">|</span>
    </div>
    <div class="search-box border rounded d-flex align-items-center px-3">
      <SearchIconMuted />
      <input @input="handleSearch" type="text" placeholder="Search here..." />

      <span @click="handleShowSearch" class="cursor-pointer">
        <cross-icon />
      </span>
      <!-- Nav search list -->
      <div
        v-show="searchListView"
        class="search-result border rounded p-3"
      >
        <div class="mb-3">
          <span>Your search result</span>
          <span class="mx-3">-</span>
          <span class="items-found">{{ searchList.length }} items found</span>
        </div>
        <div class="result-list">
          <ul>
            <li v-for="item in searchList" :key="item.id">
              <router-link class="py-2 px-2" :to="'/items/' + item.slug"
                ><div class="d-flex search-list-item">
                  <div class="mr-3">
                    <img alt="" :src="item.image" />
                  </div>
                  <div class="flex-grow-1">
                    <div class="product-name">{{ item.name }}</div>
                    <span
                      v-if="item.oldPrice !== ''"
                      class="text-muted product-old-price mr-2"
                      >Rs. {{ item.oldPrice }}</span
                    >
                    <span class="text-muted product-new-price"
                      >Rs. {{ item.newPrice }}</span
                    >
                  </div>
                  <!-- <div class="tags">test</div> -->
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="goto-adv-search text-center mt-2">
          <router-link to="/shop">Go to Advanced Search</router-link>
        </div>
      </div>
    </div>
  </div>
  <CToaster placement="bottom-end">
    <CToast :delay="time" v-for="(toast, index) in toasts" :key="index">
      <CAlert class="m-0" :color="toast.color"> {{ toast.content }} </CAlert>
    </CToast>
  </CToaster>
</template>

<script>
import SearchIconMuted from "@/assets/svg/SearchIconMuted.vue";
import CrossIcon from "@/assets/svg/CrossIcon.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {ref} from "vue";
import debounce from 'lodash.debounce';

export default {
  name: "NavSearch",
  components: {
    SearchIconMuted,
    CrossIcon,
  },
  data() {
    return {
      time: 4000,
      toasts: [],
      toastMessage: "Toast Message",
      toastColor: "success",
      searchList: ref([]),
      searchListView:false
    };
  },
  methods: {
    handleShowSearch() {
      this.$emit("show-search", false);
    },


    handleSearch : debounce(function(e) {
      if (e.target.value.length <= 0) {
        this.searchList = [];
      } else {
        const url = UrlConfig.urlHelper.hostUrl+'/product-search?title='+e.target.value;
        const header = UrlConfig.unAuthorizedUrlHeader;
          axios.get(url, header)
            .then(response => {
              let searchListData = response.data.data;
              if (response.data.status_code === 200 && searchListData.length > 0) {
                this.searchList = [];
                Object.entries(searchListData).forEach(([key, value]) => {
                  this.searchList.push(value);
                })
                this.searchListView = true;
              }
            }).catch((error) => {
              this.toasts.push({
                color: "danger",
                content: error.response.data.message,
              });
            });
      }
    },500),

    hideSearchBox() {
      this.searchList = [];
    },
  },

  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.$emit("show-search", false);
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
