<template>
  <footer>
    <CContainer>
      <div class="text-center">
        <div class="auth-footer-nav mt-5 mb-2">
          <ul>
            <li v-for="(link, index) in navLinks" :key="link.id">
              <router-link :to="'/general-page' + link.slug" >{{ link.title }}</router-link>
              <span v-if="index !== navLinks.length - 1" class="mx-3 bar"
                >|</span
              >
            </li>
          </ul>
        </div>
        <p>Copyright © {{new Date().getFullYear()}} Helmets Nepal. All rights reserved.</p>
      </div>
    </CContainer>
  </footer>
</template>

<script>
import { CContainer } from "@coreui/vue";
export default {
  name: "AuthFooter",
  data() {
    return {
      navLinks: [
        { id: 1, title: "Terms of Service", slug: "/terms-of-service" },
        { id: 2, title: "Privacy Policy", slug: "/privacy-policies" },
        { id: 3, title: "Insurance Policy", slug: "/insurance-policy" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
