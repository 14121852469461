<template>
  <div class="mb-4 category-banner-img">
    <CContainer>
      <CCol md="12">
        <h1><img :src="shopPageBannerImage" alt="" /></h1>
      </CCol>
    </CContainer>
  </div>
  <section class="hero-slider-business mb-5">
    <swiper :space-between="0" :breakpoints="swiperOptions" :autoHeight="false">
      <swiper-slide v-for="slide in slides" :key="slide.id">
        <div class="single-slide">
          <div class="overlay"></div>
          <div class="main-hero-image">
            <img :src="slide.image" alt="" class="lg-slide-img" />
            <!--<img src="/img/slide-mobile.jpg" alt="" class="sm-slide-img" />-->
          </div>

          <!--<div class="slider-content-wrapper">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <span class="tagline py-1 px-2 mb-4 d-inline-flex">{{
                    slide.tag
                  }}</span>
                  <div class="slide-content">
                    <h1 v-html="slide.title"></h1>
                    <p>{{ slide.description }}</p>
                  </div>
                  <div class="slide-action">
                    <a
                      :href="slide.url"
                      class="btn btn-primary px-3 py-2"
                      target="_blank"
                      >View Detail</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from "axios";
import UrlConfig from "@/config/UrlConfig";
import { ref } from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "BusinessHeroSlider",
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      // slides: ref([]),
      shopPageBannerImage: "",
    };
  },

  mounted() {
    this.getPageBanner();
  },

  methods: {
    async getPageBanner() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-page-banner/shop";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let pageDetail = response.data.data;
          if (response.data.status_code === 200 && pageDetail !== "") {
            this.shopPageBannerImage = pageDetail.page_image_path;
          }
        })
        .catch((error) => {
          toast.error("Sorry ! some error occurred", {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
