<template>
  <section>
    <CContainer>
      <CRow>
        <CCol md="12">
          <swiper
            :breakpoints="breakpoints"
            :autoHeight="false"
            :slides-per-view="5"
            :space-between="50"
            :pagination="{ clickable: true }"
          >
            <swiper-slide
              v-show="categories != null"
              v-for="category in categories"
              :key="category.id"
            >
              <category-tile :category="category" />
            </swiper-slide>
          </swiper>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import { CRow, CCol, CContainer } from "@coreui/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import CategoryTile from "@/components/shared-components/category-tile/CategoryTile.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "CategorieTiles",
  components: { CContainer, CRow, CCol, Swiper, SwiperSlide, CategoryTile },
  data() {
    return {
      breakpoints: {
        0: {
          slidesPerView: 3,
          spaceBetween: 10,
        },

        425: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        767: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
      },
      categories: ref([]),
    };
  },

  created() {
    this.getCategoryTileDetail();
  },

  methods: {
    async getCategoryTileDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/category-list";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let categoryTileDetail = response.data.data;
          if (
            response.data.status_code === 200 &&
            categoryTileDetail.length > 0
          ) {
            Object.entries(categoryTileDetail).forEach(([key, value]) => {
              this.categories.push({
                id: ++key,
                name: value.name,
                url: "/" + value.slug,
                image: value.image_path,
                slug: "/" + value.slug,
              });
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
