<template>
  <CContainer>
    <div class="row justify-content-md-center">
      <CCol md="7" lg="5">
        <div class="mb-4">
          <Breadcrumb :data="breadcrumb" />
          <auth-title
            title="Join as a Customer or Vendor"
            description="Lorem ipsum dolor consectetur adipiscing elit eiusmod tempor elit eiusmod tempor."
          />
        </div>
      </CCol>
    </div>
  </CContainer>
  <section class="signup-step">
    <register />
  </section>
</template>

<script>
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CDropdownDivider,
  CButton,
} from "@coreui/vue";
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import AuthTitle from "@/components/shared-components/auth-title/AuthTitle.vue";
import Register from "@/containers/auth/register/Register.vue";
export default {
  components: {
    AuthTitle,
    CContainer,
    CRow,
    CCol,
    CCard,
    CDropdownDivider,
    CButton,
    Register,
    Breadcrumb,
  },
  name: "PageRegister",
  beforeCreate() {
    this.breadcrumb = [
      { label: "Home", url: "/" },
      { label: "Register", url: "*" },
    ];
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
