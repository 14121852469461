<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6431 5.41517C16.6431 10.5668 9.21842 14.9027 9.21842 14.9027C9.21842 14.9027 1.79309 10.5027 1.79309 5.42561C1.79309 3.35266 3.4431 1.70266 5.5056 1.70266C7.56811 1.70266 9.21811 4.17767 9.21811 4.17767C9.21811 4.17767 10.8681 1.70266 12.9306 1.70266C14.9931 1.70266 16.6431 3.35266 16.6431 5.41517Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "HeartWhiteIcon",
};
</script>

<style lang="scss" scoped></style>
