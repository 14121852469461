<template>
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1079 1.64478L17.4433 0.973955L17.1079 0.80625L16.7725 0.973955L17.1079 1.64478ZM32.9639 9.57275H33.7139V9.10923L33.2993 8.90193L32.9639 9.57275ZM32.9639 25.4287L33.2993 26.0995L33.7139 25.8922V25.4287H32.9639ZM17.1079 33.3567L16.7725 34.0275L17.1079 34.1952L17.4433 34.0275L17.1079 33.3567ZM1.25195 25.4287H0.501953V25.8922L0.916543 26.0995L1.25195 25.4287ZM1.25195 9.57275L0.916543 8.90193L0.501953 9.10923V9.57275H1.25195ZM25.1668 19.2625C25.1668 19.6767 25.5026 20.0125 25.9168 20.0125C26.331 20.0125 26.6668 19.6767 26.6668 19.2625H25.1668ZM25.9168 13.0963H26.6668V12.6328L26.2522 12.4255L25.9168 13.0963ZM10.3962 4.4975C10.0257 4.31226 9.57524 4.46243 9.39 4.83291C9.20476 5.2034 9.35492 5.6539 9.72541 5.83914L10.3962 4.4975ZM16.3579 32.4758C16.3579 32.89 16.6937 33.2258 17.1079 33.2258C17.5221 33.2258 17.8579 32.89 17.8579 32.4758H16.3579ZM16.7725 2.3156L32.6285 10.2436L33.2993 8.90193L17.4433 0.973955L16.7725 2.3156ZM32.2139 9.57275V25.4287H33.7139V9.57275H32.2139ZM32.6285 24.7579L16.7725 32.6859L17.4433 34.0275L33.2993 26.0995L32.6285 24.7579ZM17.4433 32.6859L1.58736 24.7579L0.916543 26.0995L16.7725 34.0275L17.4433 32.6859ZM2.00195 25.4287V9.57275H0.501953V25.4287H2.00195ZM1.58736 10.2436L17.4433 2.3156L16.7725 0.973955L0.916543 8.90193L1.58736 10.2436ZM32.6285 8.90193L16.7725 16.8299L17.4433 18.1716L33.2993 10.2436L32.6285 8.90193ZM17.4433 16.8299L1.58736 8.90193L0.916543 10.2436L16.7725 18.1716L17.4433 16.8299ZM26.6668 19.2625V13.0963H25.1668V19.2625H26.6668ZM26.2522 12.4255L10.3962 4.4975L9.72541 5.83914L25.5814 13.7671L26.2522 12.4255ZM17.8579 32.4758V17.5007H16.3579V32.4758H17.8579Z"
      fill="#ED1B24"
    />
  </svg>
</template>

<script>
export default {
  name: "VendorIcon",
};
</script>

<style lang="scss" scoped></style>
