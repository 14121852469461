<template>
  <section>
    <page-banner :image="banner_detail?.page_image_path">
      <div class="col-md-7">
        <banner-title
          :tagline="banner_detail?.page_title"
          :title="banner_detail?.page_sub_title"
          :description="banner_detail?.page_title_description"
        />
      </div>
    </page-banner>
    <Breadcrumb :data="breadcrumb"/>
  </section>
  <section class="pt-5">
    <CContainer>
      <CRow>
        <CCol
          sm="6"
          md="4"
          lg="3"
          class="mb-4"
          v-for="showroom in showrooms"
          :key="showroom.id"
        >
          <showroom-card :showroom="showroom" />
        </CCol>
      </CRow>
      <Pagination
        :data="pageDetail"
        @pagination-change-page="getAllShowRooms"
      />
    </CContainer>
  </section>
  <newsletter-sub />
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import PageBanner from "@/components/shared-components/page-banner/PageBanner.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import ShowroomCard from "@/components/shared-components/showroom-card/ShowroomCard.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import Pagination from "laravel-vue-pagination";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    Breadcrumb,
    PageBanner,
    BannerTitle,
    ShowroomCard,
    NewsletterSub,
    Pagination,
  },
  name: "Showrooms",
  data() {
    return {
      banner_detail: ref(""),
      showrooms: ref([]),
      pageDetail: ref(""),
    };
  },

  beforeCreate(){
    this.breadcrumb = [
      {label: 'Home', url: '/'},
      {label: 'Showroom', url: '*'}
    ];
  },
  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getPageBanner();
    this.getAllShowRooms();
  },

  methods: {
    async getPageBanner() {
      const url =
        UrlConfig.urlHelper.hostUrl + "/get-page-banner/showroom_list";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let showRoomBannerDetail = response.data.data;
          if (
            response.data.status_code === 200 &&
            showRoomBannerDetail !== ""
          ) {
            this.banner_detail = showRoomBannerDetail;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 5000,
          });
        });
    },

    async getAllShowRooms(page = 1) {
      this.showrooms = [];
      const url = UrlConfig.urlHelper.hostUrl + "/get-showroom?page=" + page;
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let showroomsPageDetail = response.data.data;
          let showRoomsDetail = showroomsPageDetail.showroom;
          this.pageDetail = showroomsPageDetail.page_details;

          if (response.data.status_code === 200 && showRoomsDetail.length > 0) {
            Object.entries(showRoomsDetail).forEach(([key, value]) => {
              this.showrooms.push({
                id: ++key,
                address: value.address,
                showroomName: value.name,
                phone: value.contact_no,
                contactPerson: value.contact_person,
                image: value.image_path,
              });
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 5000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
