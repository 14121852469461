<template>
  <CCard class="p-4 mt-4 mt-md-0">
    <heading
        title="My Orders"
        :description="
        'There are ' + totalPendingOrderItem + ' products in  orders'
      "
    />

    <div class="table-responsive desktop-orders">
      <table class="table orders-listing">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Item</th>
          <th scope="col">Qty</th>
          <th scope="col">Amount</th>
          <th scope="col">Date</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in ordersData" style="position: relative">
          <div
              class="block-confirm bg-danger px-4"
              v-if="selectedRow === key"
              style="z-index: 9"
          >
            <div class="text-white" v-if="cancelling">
              cancelling order...
            </div>
            <div class="confirm-content text-white" v-else>
              <div class="confirm-message">
                Are you sure to cancel this ordered item: {{ item.name }} ?
              </div>
              <div class="confirm-action">
                <CButton
                    color="light"
                    variant="outline"
                    @click="cancelItemOrder(item, --key)"
                >Ok
                </CButton
                >
                <CButton
                    class="ms-3"
                    color="light"
                    variant="outline"
                    @click="handleConfirmVisibility(null)"
                >Cancel
                </CButton
                >
              </div>
            </div>
          </div>
          <th>{{ ++key }}</th>
          <td>
            <div class="d-flex" style="max-width: 200px">
              <div class="me-3">
                <img :src="item.image" alt=""/>
              </div>
              <div>
                {{ item.name }}
              </div>
            </div>
          </td>
          <td>{{ item.qty }}</td>
          <td>Rs.{{ item.amount }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.status }}</td>
          <td v-if="item.status == 'Pending'">
            <button
                class="cancel-btn rounded"
                @click="handleConfirmVisibility(--key)"
            >
              Cancel
            </button>
          </td>
          <td v-else>-</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="resp-orders">
      <CCard class="p-4 mt-3" v-for="(item, key) in ordersData" :key="item.id">
        <div
            class="block-confirm bg-danger px-4"
            v-if="selectedRow === key"
            style="z-index: 9"
        >
          <div class="text-white" v-if="cancelling">
            cancelling order...
          </div>
          <div class="confirm-content text-white" v-else>
            <div class="confirm-message">
              Are you sure to cancel this ordered item: {{ item.name }} ?
            </div>
            <div class="confirm-action">
              <CButton
                  color="light"
                  variant="outline"
                  @click="cancelItemOrder(item, --key)"
              >Ok
              </CButton
              >
              <CButton
                  class="ms-3"
                  color="light"
                  variant="outline"
                  @click="handleConfirmVisibility(null)"
              >Cancel
              </CButton
              >
            </div>
          </div>
        </div>
        <div class="mb-4">{{ ++key }}</div>
        <div class="mb-2">
          <div class="d-flex">
            <div class="me-3">
              <img style="width: 50px" :src="item.image" alt=""/>
            </div>
            <div>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div>Quantity: {{ item.qty }}</div>
        <div>Amount: {{ item.amount }}</div>
        <div>Date: {{ item.date }}</div>
        <div>Status: {{ item.status }}</div>
        <div class="mt-4" v-if="item.status == 'Pending'">
          <button class="cancel-btn rounded" @click="handleConfirmVisibility(--key)" >  Cancel </button>
        </div>
        <div v-else>-</div>
      </CCard>
    </div>
  </CCard>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import Pagination from "laravel-vue-pagination";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  name: "MyOrders",
  components: {Heading, Pagination},

  data() {
    return {
      totalPendingOrderItem: 0,
      ordersData: ref([]),
      cancelling: false,
      selectedRow: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getAllMyPendingOrders();
  },

  methods: {
    async getAllMyPendingOrders() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-order";
      const header = UrlConfig.authorizedUrlHeader;
      await axios
          .get(url, header)
          .then((response) => {
            let pendingOrderDetail = response.data.data;
            if (
                response.data.status_code === 200 &&
                pendingOrderDetail.length > 0
            ) {
              this.totalPendingOrderItem = pendingOrderDetail.length ?? 0;
              Object.entries(pendingOrderDetail).forEach(([key, value]) => {
                this.ordersData.push({
                  id: value.id,
                  name: value.name,
                  qty: value.qty,
                  amount: value.amount,
                  status: value.status,
                  date: value.date,
                  image: value.image,
                  order_product_id: value.order_product_id,
                });
              });
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
    },

    async cancelItemOrder(productDetail, key) {
      this.cancelling = true;
      const url = UrlConfig.urlHelper.hostUrl + "/cancel-order";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {
        order_product_id: productDetail.order_product_id,
        order_id: productDetail.id,
      };
      await axios
          .post(url, data, header)
          .then((response) => {
            if (response.data.status_code === 200) {
              this.ordersData.splice(key, 1);
              this.totalPendingOrderItem = this.ordersData.length;
              toast.success("Item Order Cancelled Successfully.", {
                timeout: 2000,
              });
            }
            this.selectedRow = null;
            this.cancelling = false;
          })
          .catch((error) => {
            this.cancelling = false;
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
    },

    handleConfirmVisibility(index) {
      this.selectedRow = index;
    },
  },
};
</script>

<style lang="scss" scoped></style>
