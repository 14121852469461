<template>
  <CCard class="p-4">
    <heading title="Settings"> </heading>

    <div class="setting-controllers">
      <div class="d-flex justify-content-between border-bottom pb-2 mb-2">
        <div>Profile</div>
        <div>
          <router-link to="/dashboard/profile/edit">Edit</router-link>
        </div>
      </div>
      <div class="d-flex justify-content-between border-bottom pb-2 mb-2">
        <div>Email</div>
        <div>
          <router-link to="/change-email">Change</router-link>
        </div>
      </div>
      <div class="d-flex justify-content-between border-bottom pb-2 mb-2">
        <div>Password</div>
        <div>
          <router-link to="/change-password">Reset</router-link>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>Allow Notifications</div>
        <div>
          <Toggle v-model="value" @change.prevent="onChange" />
        </div>
      </div>
    </div>
  </CCard>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import Toggle from "@vueform/toggle";
export default {
  components: { Toggle, Heading },
  name: "Settings",

  method: {
    click: function () {},
  },
  data() {
    return {
      value: true,
    };
  },
  methods: {
    onChange: function (value) {},
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>
