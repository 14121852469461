<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.48371 1.71597L5.96236 4.34601C6.26952 4.67216 6.26973 5.1832 5.96284 5.50961L4.76466 6.78399C5.517 9.08409 7.1995 10.9242 9.33188 11.7943L9.57063 11.887L10.7064 10.679C11.0254 10.3397 11.5572 10.325 11.8942 10.6462L14.3728 13.2762C14.68 13.6024 14.6802 14.1134 14.3733 14.4398L13.7569 15.0954C12.6312 16.2928 10.9168 16.6302 9.41103 15.9508C7.28258 14.9905 5.56511 13.8167 4.25863 12.4295C2.95214 11.0423 1.84616 9.21811 0.940703 6.95702C0.325764 5.42144 0.592484 3.68069 1.61079 2.48456L1.74199 2.33794L2.29593 1.74877C2.61493 1.40948 3.14672 1.3948 3.48371 1.71597Z"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "PhoneSmIcon",
};
</script>

<style lang="scss" scoped></style>
