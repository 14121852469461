<template>
  <CContainer>
    <CRow>
      <CCol
        md="6"
        lg="3"
        class="mb-lg-0 mb-4"
        v-for="(callout,index) in calloutTwoData"
        :key="callout.id"
      >
        <callout-two :callout="callout" />
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import CalloutTwo from "@/components/shared-components/callout/CalloutTwo.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "ECallouts",
  components: { CalloutTwo },
  data() {
    return {
      calloutTwoData: ref([])
    }
  },

  mounted() {
    this.getShopCalloutsDetail();
  },

  methods: {
    async getShopCalloutsDetail(){
      const url = UrlConfig.urlHelper.hostUrl +'/get-shop-callout' ;
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios.get(url,header)
          .then(response => {
            let callOutsDetail = response.data.data ;
            if(response.data.status_code === 200 && callOutsDetail.length > 0){
              Object.entries(callOutsDetail).forEach(([key, value]) => {
                this.calloutTwoData.push({
                  id:++key,
                  title:value.title,
                  icon:value.image_path,
                  description:value.description
                });
              });
            }
          }).catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
