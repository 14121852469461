<template>
  <template v-if="userType === 'customer'">
    <customer-form/>
  </template>
  <template v-else-if="userType === 'vendor'">
    <vendor-form/>
  </template>
</template>

<script>
import {useRoute} from "vue-router";
import CustomerForm from "@/containers/auth/customer-form/CustomerForm.vue";
import VendorForm from "@/containers/auth/vendor-form/VendorForm.vue";

export default {
  components: {CustomerForm, VendorForm},
  name: "UserRegistration",

  data() {
    return {
      userType: "",
    };
  },

  created() {
    const route = useRoute();
    this.userType = route.params.type;
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
