<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 9.92822V14.9282C17 16.0328 16.1046 16.9282 15 16.9282H3C1.89543 16.9282 1 16.0328 1 14.9282L1 9.92822M13 4.92822L9 0.928223M9 0.928223L5 4.92822M9 0.928223L9 12.9282"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UploadIcon",
};
</script>

<style lang="scss" scoped></style>
