import axios from "axios";
import UrlConfig from "@/config/UrlConfig";
export default {
  namespaced: true,
  state: {
    faqsDatas: {},
    activeCategory: [],
  },
  getters: {},
  actions: {
    getFaqsData: async ({ commit }) => {
      const url = UrlConfig.urlHelper.hostUrl + "/get-faq";
      const header = UrlConfig.unAuthorizedUrlHeader;
      const response = await axios.get(url, header);
      commit("SET_FAQSDATA", response.data.data);
    },

    setActiveQna: (context, payload) => {
      const selectedItem = context.state.faqsDatas.faqsData.find(
        (faq) => faq.category === payload
      );
      context.commit("SET_SELECTEDQNA", selectedItem.qna);
    },
  },
  mutations: {
    SET_FAQSDATA: (state, data) => {
      state.faqsDatas = data;
      state.activeCategory = data.faqsData[0].qna;
    },
    SET_SELECTEDQNA: (state, data) => {
      state.activeCategory = data;
    },
  },
};
