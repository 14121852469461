<template>
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3775 13.5027C14.987 13.1122 14.3538 13.1122 13.9633 13.5027C13.5728 13.8933 13.5728 14.5264 13.9633 14.9169L15.3775 13.5027ZM20.4344 21.3881C20.825 21.7786 21.4581 21.7786 21.8487 21.3881C22.2392 20.9976 22.2392 20.3644 21.8487 19.9739L20.4344 21.3881ZM9.2778 15.3669C5.66053 15.3669 2.72815 12.4345 2.72815 8.81723H0.728149C0.728149 13.5391 4.55596 17.3669 9.2778 17.3669V15.3669ZM15.8275 8.81723C15.8275 12.4345 12.8951 15.3669 9.2778 15.3669V17.3669C13.9996 17.3669 17.8275 13.5391 17.8275 8.81723H15.8275ZM9.2778 2.26758C12.8951 2.26758 15.8275 5.19996 15.8275 8.81723H17.8275C17.8275 4.09539 13.9996 0.267578 9.2778 0.267578V2.26758ZM9.2778 0.267578C4.55596 0.267578 0.728149 4.09539 0.728149 8.81723H2.72815C2.72815 5.19996 5.66053 2.26758 9.2778 2.26758V0.267578ZM13.9633 14.9169L20.4344 21.3881L21.8487 19.9739L15.3775 13.5027L13.9633 14.9169Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "MagnifyIcon",
};
</script>

<style lang="scss" scoped></style>
