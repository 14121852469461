export default {
  namespaced: true,
  state: {
    avatarLink: "",
  },
  getters: {},
  actions: {
    setAvatar: (context, payload) => {
      context.commit("SET_AVATAR", payload);
    },
  },
  mutations: {
    SET_AVATAR: (state, data) => {
      state.avatarLink = data;
    },
  },
};
