<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7564 5.29114H8.53917V1.07389C8.53917 0.556394 8.11949 0.136719 7.602 0.136719H6.66483C6.14734 0.136719 5.72766 0.556394 5.72766 1.07389V5.29114H1.51041C0.992918 5.29114 0.573242 5.71082 0.573242 6.22831V7.16548C0.573242 7.68297 0.992918 8.10264 1.51041 8.10264H5.72766V12.3199C5.72766 12.8374 6.14734 13.2571 6.66483 13.2571H7.602C8.11949 13.2571 8.53917 12.8374 8.53917 12.3199V8.10264H12.7564C13.2739 8.10264 13.6936 7.68297 13.6936 7.16548V6.22831C13.6936 5.71082 13.2739 5.29114 12.7564 5.29114Z"
      fill="#898989"
    />
  </svg>
</template>

<script>
export default {
  name: "PlusIcon",
};
</script>

<style lang="scss" scoped></style>
