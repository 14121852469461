import axios from "axios";
import UrlConfig from "@/config/UrlConfig";
export default {
    namespaced: true,
    state: {
        notifications: [],
        totalNotification: 0
    },
    getters: {},
    actions: {
        fetchNotificationDetail: async (context,payload) => {
            const url = UrlConfig.urlHelper.hostUrl + "/get-notification";
            const header = UrlConfig.authorizedUrlHeader;
            try {
                const response = await axios.get(url, header);
                context.commit("SET_NOTIFICATIONDETAIL", response.data.data);
            } catch (error) {
                if (error.response) {
                    payload.toast.push({
                        color: "danger",
                        content: error.response.data.message,
                    });
                };
            }
        }
    },
    mutations: {
        SET_NOTIFICATIONDETAIL: (state, data) => {
            state.notifications = data.notification;
            state.totalNotification = data.page_details.total;
        },
    },
};
