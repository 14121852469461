<template>
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7564 17.3036C12.3422 17.3036 12.0064 17.6394 12.0064 18.0536C12.0064 18.4678 12.3422 18.8036 12.7564 18.8036V17.3036ZM20.3141 18.8036C20.7283 18.8036 21.0641 18.4678 21.0641 18.0536C21.0641 17.6394 20.7283 17.3036 20.3141 17.3036V18.8036ZM29.7612 31.2796V32.0296C30.1755 32.0296 30.5112 31.6938 30.5112 31.2796H29.7612ZM3.30934 31.2796H2.55934C2.55934 31.6938 2.89513 32.0296 3.30934 32.0296V31.2796ZM12.7564 18.8036H20.3141V17.3036H12.7564V18.8036ZM29.0112 10.4959V31.2796H30.5112V10.4959H29.0112ZM29.7612 30.5296H3.30934V32.0296H29.7612V30.5296ZM4.05934 31.2796V10.4959H2.55934V31.2796H4.05934ZM3.30934 1.79883H29.7612V0.298828H3.30934V1.79883ZM30.9007 2.93825V8.60651H32.4007V2.93825H30.9007ZM29.7612 9.74593H3.30934V11.2459H29.7612V9.74593ZM2.16992 8.60651V2.93825H0.669922V8.60651H2.16992ZM3.30934 9.74593C2.68006 9.74593 2.16992 9.2358 2.16992 8.60651H0.669922C0.669922 10.0642 1.85163 11.2459 3.30934 11.2459V9.74593ZM30.9007 8.60651C30.9007 9.2358 30.3905 9.74593 29.7612 9.74593V11.2459C31.219 11.2459 32.4007 10.0642 32.4007 8.60651H30.9007ZM29.7612 1.79883C30.3905 1.79883 30.9007 2.30896 30.9007 2.93825H32.4007C32.4007 1.48054 31.219 0.298828 29.7612 0.298828V1.79883ZM3.30934 0.298828C1.85163 0.298828 0.669922 1.48054 0.669922 2.93825H2.16992C2.16992 2.30896 2.68006 1.79883 3.30934 1.79883V0.298828Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "ShipedIcon",
};
</script>

<style lang="scss" scoped></style>
