<template>
  <section class="who-we-are">
    <CContainer>
      <CRow>
        <CCol md="6">
          <CRow>
            <CCol xl="9" xxl="9">
              <section-title
                :tagline="sloganDetail.slogan_title"
                :title="sloganDetail.slogan_sub_title"
                :description="sloganDetail.slogan_description"
              />
            </CCol>
          </CRow>
        </CCol>

        <CCol md="6">
          <CRow class="row h-100 align-items-end">
            <CCol md="6" class="mb-md-0 mb-4">
              <section-title
                :tagline="sloganDetail.slogan_title_1"
                :subtitle="sloganDetail.slogan_description_1"
              />
            </CCol>
            <CCol md="6">
              <section-title
                :tagline="sloganDetail.slogan_title_2"
                :subtitle="sloganDetail.slogan_description_2"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CContainer>

    <CContainer>
      <CRow>
        <CCol md="12">
          <div class="pt-5">
            <swiper-nav
              leftClassName="WhoPrevArrow"
              RightClassName="whoNextArrow"
            />
          </div>
          <div class="pt-5 margin-outside-vw">
            <swiper
              :breakpoints="breakpoints"
              :autoHeight="false"
              :slides-per-view="3"
              :space-between="50"
              :navigation="{ nextEl: '.whoNextArrow', prevEl: '.WhoPrevArrow' }"
            >
              <swiper-slide v-for="item in whoWeAreCards" :key="item.id">
                <image-card :cardContent="item" />
              </swiper-slide>
            </swiper>
          </div> </CCol></CRow
    ></CContainer>
  </section>
</template>

<script>
import { CRow, CCol, CContainer } from "@coreui/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import ImageCard from "@/components/shared-components/image-card/ImageCard.vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "WhoWeAre",
  components: {
    CRow,
    CCol,
    CContainer,
    SectionTitle,
    Swiper,
    SwiperSlide,
    ImageCard,
    SwiperNav,
  },

  data() {
    return {
      sloganDetail: ref(""),
      whoWeAreCards: ref([]),
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },

        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    };
  },

  mounted() {
    this.getSectionDetail();
    this.imageDetail();
  },

  methods: {
    async getSectionDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-about-us-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let aboutUsDetail = response.data.data;
          if (response.data.status_code === 200 && aboutUsDetail !== "") {
            this.sloganDetail = aboutUsDetail;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    async imageDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/sliding-content";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let imageData = response.data.data;
          if (response.data.status_code === 200 && imageData.length > 0) {
            Object.entries(imageData).forEach(([key, value]) => {
              this.whoWeAreCards.push({
                id: ++key,
                title: value.title,
                tagline: value.sub_title,
                image: value.image,
              });
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
