<template>
  <CContainer>
    <CRow>
      <CCol md="12">
        <div
          class="mb-4 d-flex flex-wrap align-items-center justify-content-sm-between"
        >
          <div class="flex-grow-1">
            <item-group-title
              :title="sectionTitle"
              :subTitle="sectionSubTitle"
            />
          </div>
          <div>
            <swiper-nav :leftClassName="prevEl" :RightClassName="nextEl" />
          </div>
        </div>

        <div>
          <swiper
            :breakpoints="breakpoints"
            :autoHeight="false"
            :slides-per-view="5"
            :space-between="50"
            :pagination="false"
            :navigation="{
              nextEl: `.${nextEl}`,
              prevEl: `.${prevEl}`,
            }"
          >
            <swiper-slide v-for="item in items" :key="item.id">
              <item-card :item="item" />
            </swiper-slide>
          </swiper>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import ItemGroupTitle from "@/components/shared-components/item-group-title/ItemGroupTitle.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import ItemCard from "@/components/shared-components/item-card/ItemCard.vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    ItemGroupTitle,
    Swiper,
    SwiperSlide,
    ItemCard,
    SwiperNav,
  },

  props: ["sectionTitle", "subTitle", "nextEl", "prevEl"],
  name: "RecommendedItemCarousel",

  data() {
    return {
      items: ref([]),
      sectionSubTitle: ref(""),
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },

        767: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    };
  },

  created() {
    this.getRecommendedItemList();
  },

  methods: {
    async getRecommendedItemList() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-recommended-item-list";
      const header = UrlConfig.authorizedHeaderWithoutImage;
      await axios
        .get(url, header)
        .then((response) => {
          let recommendedItemData = response.data.data.product;
          this.sectionSubTitle = response.data.data.section_sub_title;
          if (
            response.data.status_code === 200 &&
            recommendedItemData.length > 0
          ) {
            Object.entries(recommendedItemData).forEach(([key, value]) => {
              if (value.images.length > 0) {
                this.items.push(value);
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.datas.message, {
              timeout: 2000,
            });
          }
        });
    },
  },
};
</script>

<style scoped></style>
