import axios from "axios";
import UrlConfig from "@/config/UrlConfig";
import {useToast} from "vue-toastification";
import router from "@/router";
const toast = useToast();

export default {
    namespaced: true,
    state: {
        cartItems: [],
        wishlistItems: [],
    },
    getters: {},
    actions: {
        fetchCartItems: async (context, payload) => {
            const url = UrlConfig.urlHelper.hostUrl + "/get-cart-details";
            const header = UrlConfig.authorizedUrlHeader;
            try {
                const response = await axios.post(url, {}, header);
                context.commit("SET_CARTITEMS", response.data.data.cartItems);
            } catch (error) {
            }
        },

        addToCart: async (context, payload) => {
            const path = UrlConfig.urlHelper.hostUrl + "/add-cart";
            const header = UrlConfig.authorizedHeaderWithoutImage;
            const data = payload.cartItemDetail;
            try {
                const response = await axios.post(path, data, header);
                context.commit("SET_CARTITEMS", response.data.data.cartItems);
                return response;
            } catch (error) {
                return error;
            }
        },

        clearCart: ({commit}) => {
            commit("CLEAR_CART");
        },

        fetchWishlistItems: ({commit}) => {
            const path = UrlConfig.urlHelper.hostUrl + "/get-wishlist";
            const header = UrlConfig.authorizedUrlHeader;

            axios.get(path, header).then((response) => {
                commit("SET_WISHLISTITEMS", response.data.data);
            });
        },

        addToWishlist: async (context, payload) => {
            const path = UrlConfig.urlHelper.hostUrl + "/save-wishlist";
            const header = UrlConfig.authorizedUrlHeader;

            try {
                const response = await axios.post(path, payload, header);
                context.commit("SET_WISHLISTITEMS", response.data.data);

                return response.data.data;
            } catch (error) {
                if (error.response) {
                    payload.toast.push({
                        color: "danger",
                        content: error.response.data.message,
                    });
                }
            }
        },

        removeFromWishlist: (context, payload) => {
            const url = UrlConfig.urlHelper.hostUrl + "/delete-wishlist";
            const header = UrlConfig.authorizedUrlHeader;

            return axios
                .post(url, payload, header)
                .then((response) => {
                    context.commit("SET_WISHLISTITEMS", response.data.data);
                    return response.data.data;
                })
                .catch((error) => {
                });
        },
    },

    mutations: {
        SET_CARTITEMS: (state, data) => {
            state.cartItems = data;
        },

        CLEAR_CART: (state, data) => {
            state.cartItems = [];
        },
        SET_WISHLISTITEMS: (state, data) => {
            state.wishlistItems = [...data];
        },

        CLEAR_WISHLIST: (state, data) => {
            state.wishlistItems = [];
        },
    },
};
