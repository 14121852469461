<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7588 12.1799L11.2273 9.64844C11.1131 9.53418 10.9582 9.4707 10.7957 9.4707H10.3818C11.0826 8.57441 11.499 7.44707 11.499 6.2207C11.499 3.30332 9.13516 0.939453 6.21777 0.939453C3.30039 0.939453 0.936523 3.30332 0.936523 6.2207C0.936523 9.13809 3.30039 11.502 6.21777 11.502C7.44414 11.502 8.57148 11.0855 9.46777 10.3848V10.7986C9.46777 10.9611 9.53125 11.116 9.64551 11.2303L12.177 13.7617C12.4156 14.0004 12.8016 14.0004 13.0377 13.7617L13.7562 13.0432C13.9949 12.8045 13.9949 12.4186 13.7588 12.1799ZM6.21777 9.4707C4.42266 9.4707 2.96777 8.01836 2.96777 6.2207C2.96777 4.42559 4.42012 2.9707 6.21777 2.9707C8.01289 2.9707 9.46777 4.42305 9.46777 6.2207C9.46777 8.01582 8.01543 9.4707 6.21777 9.4707Z"
      fill="#858585"
    />
  </svg>
</template>

<script>
export default {
  name: "SearchIconMuted",
};
</script>

<style lang="scss" scoped></style>
