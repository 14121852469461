import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import CoreuiVue from "@coreui/vue";

import { plugin, defaultConfig } from "@formkit/vue";
import SwiperCore, { Autoplay, Navigation, Parallax, Pagination } from "swiper";

import store from "./store";
import VueLazyload from "vue-lazyload";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vue3GoogleLogin from 'vue3-google-login';

import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "vue-select/dist/vue-select.css";
import "multi-range-slider-vue/MultiRangeSliderBarOnly.css";
import urlConfig from "@/config/UrlConfig";

SwiperCore.use([Autoplay, Navigation, Parallax, Pagination]);

createApp(App)
    .use(router)
    .use(CoreuiVue)
    .use(plugin, defaultConfig)
    .use(store)
    .use(VueLazyload)
    .use(Toast)
    .use(vue3GoogleLogin, {
        clientId: urlConfig.socialLogin.google_client_id
    })
    .mount("#app");
