<template>

  <component :is="$route.meta.layout">
    <router-view :key="$route.fullPath"/>
  </component>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "./assets/scss/global.scss";
</style>
