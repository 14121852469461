let token = localStorage.getItem("token") !== "" ? localStorage.getItem("token") : "";

let isLive = process.env.VUE_APP_IS_LIVE == "true";
let isPaymentLive = process.env.VUE_APP_IS_PAYMENT_LIVE == "true";

let urlHelper = {
        hostUrl: isLive ? process.env.VUE_APP_LIVE_API_URL : process.env.VUE_APP_STAGING_API_URL,
        esewa_success_url: isLive ? process.env.VUE_APP_LIVE_ESEWA_SUCCESS_URL : process.env.VUE_APP_STAGING_ESEWA_SUCCESS_URL,
        esewa_failure_url: isLive ? process.env.VUE_APP_LIVE_ESEWA_FAILURE_URL : process.env.VUE_APP_STAGING_ESEWA_FAILURE_URL,
        esewa_url: isPaymentLive ? process.env.VUE_APP_LIVE_ESEWA_URL : process.env.VUE_APP_STAGING_ESEWA_URL,
        merchant_code: isLive ? process.env.VUE_APP_LIVE_ESEWA_MERCHANT_CODE : process.env.VUE_APP_STAGING_ESEWA_MERCHANT_CODE
    },

    socialLogin = {
        facebook_login_url: isLive ? process.env.VUE_APP_LIVE_FACEBOOK_LOGIN_URL : process.env.VUE_APP_STAGING_FACEBOOK_LOGIN_URL,
        google_login_url: isLive ? process.env.VUE_APP_LIVE_GOOGLE_LOGIN_URL : process.env.VUE_APP_STAGING_GOOGLE_LOGIN_URL,
        facebook_app_id: "895509881569964",
        google_client_id: "683536977452-laejl9qs0jopbhan3o6q0klgfmmjg3re.apps.googleusercontent.com"
    },

    authorizedUrlHeader = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
        },
    },

    authorizedHeaderWithoutImage = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    },

    unAuthorizedUrlHeader = {
        headers: {
            Accept: "application/json",
            "content-type": "multipart/form-data",
        },
    };

export default {
    urlHelper,
    socialLogin,
    authorizedUrlHeader,
    unAuthorizedUrlHeader,
    authorizedHeaderWithoutImage,
};

