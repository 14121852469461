<template>
  <svg
    width="9"
    height="7"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.44533 0.250042C8.32255 0.122917 8.11998 0.119393 7.99285 0.242167C7.99019 0.244736 7.98756 0.247361 7.98498 0.250042L2.77122 5.46381L1.07746 3.77005C0.950337 3.64728 0.747762 3.6508 0.624987 3.77793C0.505213 3.90194 0.505213 4.09853 0.624987 4.22255L2.54499 6.14254C2.66995 6.26748 2.87251 6.26748 2.99746 6.14254L8.43745 0.702536C8.56458 0.579742 8.5681 0.377167 8.44533 0.250042Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "TickIcon",
};
</script>

<style lang="scss" scoped></style>
