<template>
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.07413 6.12073C6.07413 4.29041 7.55789 2.80664 9.38821 2.80664C11.2185 2.80664 12.7023 4.29041 12.7023 6.12073C12.7023 7.95105 11.2185 9.43482 9.38821 9.43482C7.55789 9.43482 6.07413 7.95105 6.07413 6.12073ZM9.38821 0.806641C6.45332 0.806641 4.07413 3.18584 4.07413 6.12073C4.07413 9.05562 6.45332 11.4348 9.38821 11.4348C12.3231 11.4348 14.7023 9.05562 14.7023 6.12073C14.7023 3.18584 12.3231 0.806641 9.38821 0.806641ZM2.83862 18.344C2.83862 16.8868 3.98395 15.749 5.34382 15.749H13.4327C14.7926 15.749 15.9379 16.8868 15.9379 18.344V19.1416H2.83862V18.344ZM5.34382 13.749C2.83195 13.749 0.838623 15.8303 0.838623 18.344V20.1416C0.838623 20.6939 1.28634 21.1416 1.83862 21.1416H16.9379C17.4902 21.1416 17.9379 20.6939 17.9379 20.1416V18.344C17.9379 15.8303 15.9446 13.749 13.4327 13.749H5.34382Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "UserIcon",
};
</script>

<style lang="scss" scoped></style>
