<template>
  <div class="header-actions-mobile">

    <div class="action-item" @click="handleShowSearch">
      <magnify-icon />
    </div>

    <div class="action-item">
      <div class="badge-holder">
        <router-link to="/cart">
           <span class="badge-spec" v-if="cartItems?.length > 0">{{
               cartItems?.length > 0 ? cartItems?.length : null
             }}</span>
          <cart-icon />
        </router-link>
      </div>
    </div>

    <div class="action-item" v-if="showUserAuthIcon" >
      <router-link to="/login">
        <user-icon />
      </router-link>
    </div>

    <div class="action-item" v-if="showAuthorizedNavIcon">
      <div class="badge-holder">
        <router-link to="/wishlist">
           <span class="badge-spec" v-if="wishlistItems?.length > 0">{{
               wishlistItems?.length
             }}</span>
          <wishlist-icon />
        </router-link>
      </div>
    </div>

    <div class="action-item" v-if="showAuthorizedNavIcon">
      <div class="badge-holder">
        <router-link to="/dashboard/notifications">
           <span class="badge-spec" v-if="totalNotifications > 0">{{
               totalNotifications
             }}</span>
          <notification-icon />
        </router-link>
      </div>
    </div>

    <div class="action-item" v-if="showUserAvatar">
      <div class="header-profile-pic">
        <router-link to="/dashboard/profile">
          <img :src="avatarLink || avatar" alt="alt" />
        </router-link>
      </div>
    </div>

    <div class="action-item" @click="handleMenu">
      <menu-bar-icon />
    </div>

  </div>
  <mobile-menu-content :logged-in="loggedIn" v-if="showMobileMenu"
                       @handleMenu="handleMenu"
                       @logoutUserMobile="logoutUser"
  />
</template>

<script>
import MagnifyIcon from "@/assets/svg/header-icons/MagnifyIcon.vue";
import CartIcon from "@/assets/svg/header-icons/CartIcon.vue";
import WishlistIcon from "@/assets/svg/header-icons/WishlistIcon.vue";
import UserIcon from "@/assets/svg/header-icons/UserIcon.vue";
import NotificationIcon from "@/assets/svg/header-icons/NotificationIcon.vue";
import MenuBarIcon from "@/assets/svg/MenuBarIcon.vue";
import MobileMenuContent from "./MobileMenuContent.vue";
import {helper} from "@/helper/Helper";
import { ref } from "vue";
import { mapState } from "vuex";
import router from "@/router";
import urlConfig from "@/config/UrlConfig";

export default {
  name: "HeaderActionsMobile",
  components: {
    MagnifyIcon,
    CartIcon,
    WishlistIcon,
    NotificationIcon,
    UserIcon,
    MenuBarIcon,
    MobileMenuContent,
  },

  data() {
    return {
      showMobileMenu: false,
      showUserAuthIcon: true,
      showUserAvatar: false,
      showAuthorizedNavIcon: false,
      loggedIn: false,
      avatar: ref(""),
      userDetails: {},
    };
  },

  created() {
    this.checkIfUserIsLoggedIn();
    this.initFacebook();
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
  },


  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch("product/fetchCartItems");
      this.$store.dispatch("product/fetchWishlistItems");
      this.$store.dispatch("notification/fetchNotificationDetail");
    }
    this.getUserDetails();
  },

  computed: {
    ...mapState({
      cartItems: (state) => state.product.cartItems,
      wishlistItems: (state) => state.product.wishlistItems,
      notifications: (state) => state.notification.notifications,
      totalNotifications: (state) => state.notification.totalNotification,
      avatarLink: (state) => state.profile.avatarLink,
    }),
  },

  methods: {

    getUserDetails() {
      const userDetail = JSON.parse(localStorage.getItem("USERDETAILS"));
      this.userDetails = userDetail;
    },

    checkIfUserIsLoggedIn() {
      let helperObj = new helper();
      let userDetail = helperObj.checkLogin();
      if (userDetail) {
        this.showUserAuthIcon = false;
        this.showAuthorizedNavIcon = true;
        this.showUserAvatar = true;
        this.avatar = localStorage.getItem("avatar");
        this.loggedIn = true;
      }
    },

    async logoutUser() {
      await window.FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
          window.FB.logout(function (response) {

          });
        }
      },true);
      localStorage.clear();
      this.showUserAuthIcon = true;
      this.showUserAvatar = false;
      this.showAuthorizedNavIcon = false;
      this.loggedIn = false;
      router.go();
      router.push("/");
    },

    initFacebook() {
      const facebookId = urlConfig.socialLogin.facebook_app_id;
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: facebookId,
          cookie: true,
          xfbml: true,
          version: "v13.0",
          status: true,
        });
      };
    },

    loadFacebookSDK(d, s, id) {
      var js,
          fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },

    handleMenu: function () {
      this.showMobileMenu = !this.showMobileMenu;
      document.body.style.overflow = "hidden";
    },

    handleShowSearch() {
      this.$emit("show-search", true);
    },
  }
};
</script>

<style lang="scss" scoped></style>
