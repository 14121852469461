<template>
  <section class="page-banner-bg py-5">
    <div class="container">
      <div class="row justify-content-center">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageBannerTwo",
};
</script>

<style lang="scss" scoped></style>
