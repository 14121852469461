<template>
  <page-banner-two>
    <div class="col-md-7">
      <banner-title
        tagline="KNOW IN DETAIL"
        title="Riders stories"
        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque"
      />
    </div>
  </page-banner-two>
  <Breadcrumb :key="componentKey" :data="breadcrumb"/>

  <rider-story-block
    :name="riderStoryDetail.name"
    :image="riderStoryDetail.image_path"
    :designation="riderStoryDetail.designation"
    :description="riderStoryDetail.description"
  />
  <newsletter-sub />
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import RiderStoryBlock from "@/containers/business/rider-story-block/RiderStoryBlock.vue";
import PageBannerTwo from "@/components/shared-components/page-banner/PageBannerTwo.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    RiderStoryBlock,
    PageBannerTwo,
    BannerTitle,
    NewsletterSub,
    Breadcrumb,
  },
  name: "RiderStory",

  data() {
    return {
      riderStoryDetail: ref(""),
      componentKey: ref(0),
    };
  },

  created(){  
    this.breadcrumb = [
                {label: 'Home', url: '/'},
                {label: 'Rider Story', url: '/'}
              ];  
  },

  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getRiderStoryDetail(this.$route.params.id);
  },

  methods: {
    async getRiderStoryDetail(id) {
      const url = UrlConfig.urlHelper.hostUrl + "/rider-story-detail/" + id;
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let riderStory = response.data.data;
          if (response.data.status_code === 200 && riderStory !== "") {
            this.riderStoryDetail = riderStory;

            this.breadcrumb.push({label: this.riderStoryDetail.name, url: '*'});
            this.componentKey += 1;
          }         
        })        
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
