<template>
  <div class="mb-4">
    <Breadcrumb :data="breadcrumb" />
    <auth-title title="Sign in to your account" />
  </div>
  <login-form />
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import AuthTitle from "@/components/shared-components/auth-title/AuthTitle.vue";
import LoginForm from "@/containers/auth/login-form/LoginForm.vue";
export default {
  components: { AuthTitle, LoginForm, Breadcrumb },
  name: "PageLogin",
  beforeCreate() {
    this.breadcrumb = [
      { label: "Home", url: "/" },
      { label: "Login", url: "*" },
    ];
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
