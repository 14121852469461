<template>
  <section>
    <CContainer>
      <CRow>
        <CCol md="12" lg="4" class="mb-lg-0 mb-4">
          <section-title
            tagline="Flash Sale"
            :title="sloganDetail.flash_sale_title"
            :subtitle="sloganDetail.flash_sale_description"
          />
          <div class="mt-4">
            <time-count-down :time="flashEndTime" />
          </div>
        </CCol>
        <CCol md="8" lg="5" class="mb-md-0 mb-4">
          <flash-sale-card
            tagline="LIMITED OFFER BUY NOW!!"
            :item="flashSaleProduct"
          />
        </CCol>
        <CCol md="4" lg="3">
          <ad-vertical />
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import { CRow, CCol, CContainer } from "@coreui/vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import TimeCountDown from "@/components/shared-components/count-down/TimeCountDown.vue";
import FlashSaleCard from "@/components/shared-components/flash-sale-card/FlashSaleCard.vue";
import AdVertical from "@/components/shared-components/ad-vertical/AdVertical.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "FlashSale",
  components: {
    CContainer,
    CRow,
    CCol,
    SectionTitle,
    TimeCountDown,
    FlashSaleCard,
    AdVertical,
  },

  data() {
    return {
      flashSaleProduct: ref(""),
      flashEndTime: "2022-1-13 13:03:00",
      sloganDetail: ref(""),
    };
  },

  mounted() {
    this.getFlashSaleProductWithTimeCountDown();
    this.getSectionDetail();
  },

  updated() {
    this.getFlashSaleProductWithTimeCountDown();
  },

  methods: {
    async getFlashSaleProductWithTimeCountDown() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-flash-sales";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let flashSaleDetail = response.data.data;
          if (flashSaleDetail.sale_end_date !== "") {
            this.flashEndTime = flashSaleDetail.sale_end_date;
          }
          if (response.data.status_code === 200 && flashSaleDetail.length !== ""){
            let flashProduct = flashSaleDetail.flashSaleProduct;
            if (Object.keys(flashProduct).length > 0) {
              Object.entries(flashProduct).forEach(([key, value]) => {
                this.flashSaleProduct = {
                  image: value.image,
                  tagline: value.tagline,
                  name: value.name,
                  price: value.price,
                  colors: value.colors,
                  sizes: value.size,
                  slug: value.slug,
                };
              });
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          }
        });
    },

    async getSectionDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-about-us-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let aboutUsDetail = response.data.data;
          if (response.data.status_code === 200 && aboutUsDetail !== "") {
            this.sloganDetail = aboutUsDetail;
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
