<template>
  <CCard>
    this is from non logged in checkout
    </CCard>
</template>

<script>
export default {
  name: "NonLoggedIncCheckout",
};
</script>

<style lang="scss" scoped></style>
