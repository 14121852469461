<template>
  <section class="core-values py-5">
    <CContainer>
      <CRow>
        <CCol :md="{ span: 4, offset: 1 }" class="mb-md-0 mb-4">
          <section-title
            tagline="What We Believe"
            :title="core_value_title"
            :subtitle="core_value_sub_title"
          />
        </CCol>

        <CCol md="6">
          <div
            class="core-value-list mb-4"
            v-for="value in coreValues"
            :key="value.id"
          >
            <media-list-item
              :image="value.image"
              :title="value.title"
              :description="value.description"
            />
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import MediaListItem from "@/components/shared-components/media-list-item/MediaListItem.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import {useToast} from "vue-toastification";
const toast = useToast();

export default {
  components: { SectionTitle, MediaListItem },
  name: "CoreValues",

  data() {
    return {
      core_value_title: ref(""),
      core_value_sub_title: ref(""),
      coreValues: ref([]),
    };
  },

  mounted() {
    this.getCoreValueSectionDetail();
    this.getAllCoreValues();
  },

  methods: {
    getCoreValueSectionDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-about-us-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
        .get(url, header)
        .then((response) => {
          let aboutUsDetail = response.data.data;
          if (response.data.status_code === 200 && aboutUsDetail !== "") {
            this.core_value_title = aboutUsDetail.core_value_title;
            this.core_value_sub_title = aboutUsDetail.core_value_sub_title;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    getAllCoreValues() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-core-value";
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
        .get(url, header)
        .then((response) => {
          let coreValueDetails = response.data.data;
          if (
            response.data.status_code === 200 &&
            coreValueDetails.length > 0
          ) {
            Object.entries(coreValueDetails).forEach(([key, value]) => {
              this.coreValues.push({
                id: ++key,
                title: value.title,
                description: value.description,
                image: value.image_path,
              });
            });
          }
        })
        .catch((error) => {
          toast.error('Something went wrong', {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
