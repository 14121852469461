<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.3008"
      cy="10.647"
      r="9.25"
      fill="white"
      stroke="#CCCCCC"
      stroke-width="1.5"
    />
    <path
      d="M7.30078 7.64697L13.8008 14.147"
      stroke="#A2A2A2"
      stroke-linecap="round"
    />
    <path
      d="M13.8008 7.64697L7.30078 14.147"
      stroke="#A2A2A2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CrossIcon",
};
</script>

<style lang="scss" scoped></style>
