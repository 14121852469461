<template>
  <div>
    <CContainer>
      <CCard class="p-3">
        <div class="mb-4">
          <div class="flex-grow-1">
            <item-group-title
              title="Advanced Search"
              subTitle="Filter in depth to get more out of it"
            />
          </div>
        </div>

        <div class="advanced-search">
          <div class="input-holder">
            <input type="text" v-model="keyword" placeholder="Keyword" />
          </div>

          <div class="input-holder">
            <select
              id=""
              placeholder="Product Category"
              name="model"
              @change="filteredSubCategories"
              v-model="productCategory"
            >
              <option value="" disabled>Category</option>
              <option v-for="category in categoryDetail" :value="category.slug">
                {{ category.name }}
              </option>
            </select>
          </div>

          <div class="input-holder">
            <select name="model" v-model="productSubCategory">
              <option value="" disabled>Sub Category</option>
              <option
                v-for="subcategory in subCategories"
                :value="subcategory.slug"
              >
                {{ subcategory.name }}
              </option>
            </select>
          </div>

          <div class="input-holder">
            <select  name="model" v-model="priceRangeProduct">
              <option value="" disabled>Price Range</option>
              <option value="asc">Price: Low to High</option>
              <option value="desc">Price: High to Low</option>
            </select>
          </div>

          <div class="input-holder mt-2">
            <button
              class="btn btn-primary"
              @click.prevent="productAdvanceSearch"
            >
              Search
            </button>
          </div>
        </div>

        <div class="mt-5" v-show="showSearchDetailDiv">
          <div class="flex-grow-1">
            <span
              class="cursor-pointer pull-left"
              @click.prevent="resetAdvanceSearch"
              title="Close search result section"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10.3008"
                  cy="10.647"
                  r="9.25"
                  fill="white"
                  stroke="#CCCCCC"
                  stroke-width="1.5"
                ></circle>
                <path
                  d="M7.30078 7.64697L13.8008 14.147"
                  stroke="#A2A2A2"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M13.8008 7.64697L7.30078 14.147"
                  stroke="#A2A2A2"
                  stroke-linecap="round"
                ></path>
              </svg>
            </span>
          </div>

          <div class="mt-4">
            <CRow>
              <CCol
                class="mb-4"
                sm="6"
                md="6"
                lg="4"
                v-for="(item, index) in products"
                :key="index"
              >
                <item-card :item="item" />
              </CCol>
            </CRow>
            <Pagination
              :data="pageDetail"
              @pagination-change-page="productAdvanceSearch"
            />
          </div>
        </div>

      </CCard>
    </CContainer>
  </div>
</template>

<script>
import ItemGroupTitle from "@/components/shared-components/item-group-title/ItemGroupTitle.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import ItemCard from "@/components/shared-components/item-card/ItemCard.vue";
import Pagination from "laravel-vue-pagination";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    ItemGroupTitle,
    ItemCard,
    Pagination,
  },
  name: "AdvancedSearch",

  data() {
    return {
      categoryDetail: ref([]),
      categories: ref([]),
      subCategories: ref([]),
      priceRange: ["0-5000", "5000-10000", "10000-15000", "16000-30000"],
      productCategory : "",
      productSubCategory: "",
      priceRangeProduct: "",
      keyword: "",
      products: ref([]),
      pageDetail: ref(""),
      showSearchDetailDiv: false,
      searchItemError: false,
    };
  },

  mounted() {
    this.getProductCategoryDetail();
  },

  methods: {
    getProductCategoryDetail() {
      this.$store
        .dispatch("categories/fetchProductCategory")
        .then((categoryData) => {
          if (categoryData.length > 0) {
            Object.entries(categoryData).forEach(([key, value]) => {
              this.categoryDetail.push({
                id: ++key,
                name: value.name,
                slug: value.slug,
                child_category: value.child_category
                  ? value.child_category
                  : [],
                image: value.image_path,
              });
            });
          }
        });
    },

    alertClosed() {
      this.searchItemError = true;
    },

    async productAdvanceSearch(page = 1) {
      this.products = [];
      const url = UrlConfig.urlHelper.hostUrl + "/get-product-by-category";
      const header = UrlConfig.unAuthorizedUrlHeader;
      let productCat = this.productCategory !== "" ? [ this.productCategory ] : [];
      let productSubCat = this.productSubCategory !== "" ? [ this.productSubCategory ] : [];
      let catSlug = productSubCat.length < 1 ? productCat : productSubCat ;
      if (this.keyword !== "" && catSlug.length < 1) {
        return toast.error("Please select category ....", {
          timeout: 2000,
        });
      }
      const values = {
        keyword: this.keyword,
        productCategory: this.productCategory,
        productSubCategory: this.productSubCategory,
        priceRangeProduct: this.priceRangeProduct,
      };

      const emptyCondition = (values) => {
        let empty = true;
        for (let key in values) {
          if (values[key] !== "") {
            empty = false;
          }
        }
        return empty;
      };

      if (emptyCondition(values)) {
        toast.error("Please fill keyword or select any option...", {
          timeout: 2000,
        });
      } else {
        await axios
          .get(
            url,
            {
              params: {
                page: page,
                product_model_id: [],
                brand_id: [],
                product_graphic_id: [],
                slug: catSlug,
                min_price: "",
                max_price: "",
                sort_by_price:this.priceRangeProduct,
                search: this.keyword,
              },
            },
            header
          )
          .then((response) => {
            let productDetails = response.data.data.product;
            this.pageDetail = response.data.data.page_details;
            if (
              response.data.status_code === 200 &&
              productDetails.length > 0
            ) {
              Object.entries(productDetails).forEach(([key, value]) => {
                this.products.push(value);
              });
              this.showSearchDetailDiv = true;
            } else {
              this.showSearchDetailDiv = false;
            }

            if (productDetails.length <= 0) {
              this.searchItemError = true;
              toast.error("No items found!", { timeout: 2000 });
            }
          })
          .catch((error) => {
            toast.error(error.response?.data?.message, {
              timeout: 2000,
            });
          });
      }
    },

    resetAdvanceSearch() {
      this.productCategory = "";
      this.productSubCategory = "";
      this.priceRangeProduct = "";
      this.subCategories = "";
      this.keyword = "";
      this.products = ref([]);
      this.pageDetail = ref("");
      this.showSearchDetailDiv = false;
    },
  },

  computed: {
    filteredSubCategories: function () {
      this.subCategories = [];
      this.productSubCategory = "";
      return (
        this.categoryDetail.filter((category) => {
          if (category.slug === this.productCategory) {
            this.subCategories = category.child_category;
          }
        }).length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
