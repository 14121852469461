<template>
  <svg
    width="23"
    height="21"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.1491 6.7386C21.1491 13.4733 11.4428 19.1416 11.4428 19.1416C11.4428 19.1416 1.73572 13.3895 1.73572 6.75225C1.73572 4.0423 3.89276 1.88525 6.58907 1.88525C9.28537 1.88525 11.4424 5.12082 11.4424 5.12082C11.4424 5.12082 13.5995 1.88525 16.2958 1.88525C18.9921 1.88525 21.1491 4.0423 21.1491 6.7386Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WishlistIcon",
};
</script>

<style lang="scss" scoped></style>
