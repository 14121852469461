<template>
  <div class="vertical-advt" :style="{ backgroundImage: 'url(' + homeAdDetail.image_path + ')' }">
    <div class="advt-content">
      <div class="tagline mb-2">
        <span class="px-2 py-0"> {{homeAdDetail.title}} </span>
      </div>
      <div class="title mb-2">
        <h3>{{homeAdDetail.sub_title}}</h3>
      </div>
      <div class="description mb-3">
       {{homeAdDetail.description}}
      </div>
      <div class="action">
        <a :href="homeAdDetail.link" class="btn btn-primary px-3 py-2" target="_blank">View Detail</a>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorButton from "../anchor-button/AnchorButton.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: { AnchorButton },
  name: "AdVertical",

  data() {
    return {
      homeAdDetail : ref('')
    };
  },

  mounted() {
    this.getVerticalAdDetail();
  },

  methods: {
    getVerticalAdDetail(){
      const url = UrlConfig.urlHelper.hostUrl +'/get-home-ad-block';
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios.get(url,header)
          .then(response => {
            let homeAdBlockDetail = response.data.data.homeSectionBody;
            if(response.data.status_code === 200 && homeAdBlockDetail !== ''){
              this.homeAdDetail = homeAdBlockDetail;
            }
          }).catch((error) => {
              toast.error(error.response.data.message, {
                timeout: 2000,
              });
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>
