<template>
  <section class="ceo-message pb-5">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="12" lg="8">
          <CRow>
            <CCol md="8" lg="6">
              <div class="rich-text">
                <blockquote>
                  consectetuer adipiscing elit. Aenean commodo ligula eget
                  dolor. Aenean massa. Cum sociis natoque penatibus et magnis
                  dis parturient montes, nascetur ridiculus mus. Donec quam
                  felis, ultricies nec, Aenean massa. Cum sociis natoque
                  penatibus et magnis dis parturient montes, nascetur ridiculus
                  mus. Donec quam felis, ultricies nec,
                </blockquote>

                <div class="mt-5 d-flex align-items-center mb-lg-0 mb-4">
                  <div class="text-avatar me-3">SJ</div>
                  <div>
                    <h6>Sajal Joshi</h6>
                    <p>Co - Founder/ C.E.O</p>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol md="4" class="ceo-img">
              <img src="/img/team/team01.png" alt="" />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
export default {
  name: "CeoMessage",
};
</script>

<style lang="scss" scoped></style>
