<template>
  <div
    class="horizontal-advt"
    :style="{ backgroundImage: 'url(' + adData?.image_path + ')' }"
  >
    <div class="advt-content">
      <div class="tagline mb-2">
        <span class="px-2 py-0"> {{adData?.sub_title}} </span>
      </div>
      <div class="title mb-2">
        <h3>{{adData?.title}}</h3>
      </div>
      <div class="description">
       {{adData?.description}}
      </div>
    </div>
  </div>
</template>

<script>

export default  {
  name: "AdHorizontal",
  components: {},
  props: ["adData"]

};
</script>
<style lang="scss" scoped></style>
