<template>
  <div class="auth-nav">
    <ul>
      <li>
        <router-link :to="'/general-page/' + helpPage.slug" >{{ helpPage.title }}</router-link>
        <span  class="mx-3 bar">|</span>
      </li>
      <li v-for="(link, index) in navLinks" :key="link.id">
        <router-link :to="link.slug">{{ link.title }}</router-link>
        <span v-if="index !== navLinks.length - 1" class="mx-3">|</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AuthNav",
  data() {
    return {
      helpPage:{
        id:1,
        title:"Help",
        slug:'help'
      },
      navLinks: [
        { id: 2, title: "FAQs", slug: "/faqs" },
        { id: 3, title: "Contact", slug: "/contact-us" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
