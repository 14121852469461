import { createStore } from "vuex";

import faqs from "./modules/faqs";
import about from "./modules/about";
import itemPopup from "./modules/itemPopup";
import product from "./modules/product";
import notification from "./modules/notification";
import categories from "./modules/productCategory";
import profile from "./modules/profile";

export default createStore({
  modules: {
    faqs,
    about,
    itemPopup,
    product,
    notification,
    categories,
    profile,
  },
});
