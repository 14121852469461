<template>
  <TeamBanner />
  <Breadcrumb :key="componentKey" :data="breadcrumb"/>
  <section class="py-5">
    <CContainer>
      <CRow>
        <CCol md="4">
          <div class="team-thumb">
            <img :src="teamMemberDetail.image_path" alt="" />
          </div>
        </CCol>
        <CCol md="8">
          <div>
            <h4>{{ teamMemberDetail.name }}</h4>
            <p>{{ teamMemberDetail.designation_name }}</p>
          </div>
          <div class="rich-text">
            <p v-html="teamMemberDetail.description"></p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>

  <career-linkbanner />
  <core-values />
  <newsletter-sub />
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import CoreValues from "@/containers/business/core-values/CoreValues.vue";
import CareerLinkbanner from "@/containers/business/career-link-banner/CareerLinkbanner.vue";
import PageBannerTwo from "@/components/shared-components/page-banner/PageBannerTwo.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import TeamBanner from "@/views/business-views/TeamBanner";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: {
    NewsletterSub,
    CoreValues,
    CareerLinkbanner,
    PageBannerTwo,
    BannerTitle,
    TeamBanner,
    Breadcrumb,
  },
  name: "TeamDetail",

  data() {
    return {
      teamMemberDetail: ref(""),
      componentKey: ref(0),
    };
  },

  created() {
    window.scrollTo(0, 0);
    this.getTeamMemberDetail(this.$route.params.id);
    
    this.breadcrumb = [
                {label: 'Home', url: '/'},
                {label: 'Team', url: '/'}
              ];  
  },

  methods: {
    async getTeamMemberDetail(slug) {
      const url = UrlConfig.urlHelper.hostUrl + "/get-team-detail/" + slug;
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let teamMemberDetail = response.data.data;
          if (response.data.status_code === 200 && teamMemberDetail !== "") {
            this.teamMemberDetail = teamMemberDetail;
             
            this.breadcrumb.push({label: this.teamMemberDetail.name, url: '*'});
            this.componentKey += 1;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
