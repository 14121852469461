<template>
  <CContainer>
    <CRow>
      <!-- Image Carousel -->
      <CCol md="6">
        <div class="image-swiper">
          <div class="item-main-img-holder">
            <swiper
                :breakpoints="swiperOptions"
                :autoHeight="false"
                :slides-per-view="1"
                :space-between="50"
                :navigation="{
                nextEl: '.prevImageNextArrow',
                prevEl: '.prevImagePrevArrow',
              }"
            >
              <swiper-slide
                  v-for="(image, index) in itemData?.product?.product_images"
                  :key="index"
              >
                <div class="item-preview-img">
                  <img :src="image.image" alt="" />
                </div>
              </swiper-slide>
            </swiper>
          </div>

          <div class="text-center mt-4">
            <swiper-nav
                leftClassName="prevImagePrevArrow"
                RightClassName="prevImageNextArrow"
            />
          </div>
        </div>
      </CCol>
      <!-- Image Carousel -->

      <CCol md="6">
        <div class="item-detail-content">
          <div>
            <h1>
              {{ itemData?.product?.title }} –
              {{ itemData?.product?.sub_title }}
            </h1>
          </div>

          <!-- indicators -->
          <div class="indicators">
            <CRow>
              <CCol md="4">
                <div class="category">
                  <div class="indi-text">Category</div>
                  <p>{{ itemData?.product?.category_name }}</p>
                </div>
              </CCol>
              <CCol md="4" v-show="itemData?.product?.brand_name !== ''">
                <div class="brand">
                  <div class="indi-text">Brand</div>
                  <p>
                    <img :src="itemData?.product?.brand_image" alt="" />
                    {{ itemData?.product?.brand_name }}
                  </p>
                </div>
              </CCol>

              <CCol md="4">
                <div class="item-ratings">
                  <star-rating
                      :increment="0.5"
                      :show-rating="false"
                      :read-only="true"
                      :rating="itemData?.review_star"
                      :star-points="[
                      23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                      34, 46, 19, 31, 17,
                    ]"
                      :star-size="15"
                  ></star-rating>

                  <div v-if="itemData?.review?.length > 0">
                    <p>Reviews ({{ itemData?.review?.length }})</p>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
          <!-- indicators -->

          <div class="py-4 mb-4 border-top border-bottom">
            <div>
              <div class="mb-2">
                <span class="item-price-new">
                  ₨ {{ itemData?.product?.vendor_price }}
                </span>
              </div>
              <div class="stock-alerts">
                <span class="text-success"
                >{{ itemData?.product?.product_quantity }} left in stock</span
                >
                - Order Soon.<br />

                <span class="text-success"
                >minimum order quantity:
                  {{ itemData?.product?.vendor_minimum_quantity }}</span
                >
              </div>
            </div>
          </div>

          <!-- Variations -->

          <!-- {{ JSON.stringify(initVariations) }} -->
          <div class="my-4">
            <CCard
                v-for="(variation, index) in initVariations"
                :class="index === initVariations.length - 1 ? '' : 'mb-4'"
            >
              <CCardHeader>
                <div class="d-flex justify-content-between align-items-center">
                  <div>Variation {{ index + 1 }}</div>
                  <div
                      class="remove-variation"
                      v-if="index !== 0"
                      @click="() => removeVaration(index)"
                  >
                    &#9587;
                  </div>
                </div>
              </CCardHeader>
              <div class="p-3">
                <CRow>
                  <CCol md="6">
                    <h6>Sizes</h6>
                    <div>
                      <span
                          v-for="size in variation?.sizes"
                          :class="
                          size.selected
                            ? 'size-palatte size-palatte-selected me-3 mb-2'
                            : 'size-palatte me-3 mb-2'
                        "
                          :key="size.id"
                          @click="
                          () =>
                            sizeClick({
                              index,
                              id: size.product_size_id,
                              sizeID: size.product_size_id,
                              sizeName: size.size_name,
                            })
                        "
                      >{{ size.size_name }}
                      </span>
                    </div>
                  </CCol>

                  <CCol md="6">
                    <h6>Colors</h6>
                    <div>
                      <span
                          v-for="color in variation.colors"
                          :key="color.id"
                          :class="
                          color.selected
                            ? 'color-palatte-holder color-palatte-selected'
                            : 'color-palatte-holder'
                        "
                          @click="
                          () =>
                            colorClick({
                              index,
                              color,
                              colorID: color.id,
                              colorName: color.color,
                              qty: color.quantity
                            })
                        "
                      >
                        <span
                            class="color-palatte"
                            :style="{
                          background: `linear-gradient(to right, ${color.color[0]} , ${color.color[1]})`,
                        }"
                        ></span>
                      </span>
                    </div>
                  </CCol>

                  <CCol md="12">
                    <div class="item-qty mt-4">
                      <div class="mb-3">
                        <h6>Quantity</h6>
                      </div>
                      <div class="d-flex">
                        <button
                            class="qty-ctrlor"
                            @click="() => decreaseItemQty(index)"
                        >
                          <minus-icon />
                        </button>
                        <span class="qty-display mx-3">{{
                            variation.qty
                          }}</span>
                        <button
                            class="qty-ctrlor"
                            @click="() => increaseItemQty(index)"
                        >
                          <plus-icon />
                        </button>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>
            </CCard>
            <div
                class="mt-4 text-end"
                v-if="totalNumberOfItems < itemData?.product?.product_quantity"
            >
              <button
                  class="btn btn-primary btn-sm"
                  @click="() => addVariation(2)"
              >
                Add Variation
              </button>
            </div>
            <!-- Variations -->
          </div>
          <!-- /// -->
          <div class="attributes-meta mt-4">
            <div class="item-purchase-actions mt-4">
              <CButton
                  v-show="itemData?.product?.product_quantity > 0"
                  :disabled="addingToCart || btnDisable"
                  class="item-cart me-3"
                  @click="addToCartAPI"
              >
                <CSpinner
                    v-if="addingToCart"
                    component="span"
                    size="sm"
                    aria-hidden="true"
                />

                {{ addingToCart ? "Adding..." : "Add To Cart" }}
              </CButton>
            </div>
          </div>
          <!-- /// -->
          <div class="about-item mt-4">
            <h6>About Item</h6>
            <div class="rich-text" v-html="itemData?.product?.details"></div>
          </div>

          <div class="item-spec my-5">
            <h6>Item Specifications</h6>
            <table class="table">
              <tbody>
              <tr v-show="itemData?.product?.brand_name !== ''">
                <td>Brand</td>
                <td>{{ itemData?.product?.brand_name }}</td>
              </tr>
              <tr v-show="itemData?.product?.product_graphic !== ''">
                <td>Graphics</td>
                <td>{{ itemData?.product?.product_graphic }}</td>
              </tr>
              <tr v-show="itemData?.product?.product_model !== ''">
                <td>Product Model</td>
                <td>{{ itemData?.product?.product_model }}</td>
              </tr>
              <tr v-show="itemData?.product?.manufacture !== ''">
                <td>Manufacturer</td>
                <td>{{ itemData?.product?.manufacture }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CCol>
    </CRow>
    <CToaster placement="top-end">
      <CToast :delay="time" v-for="(toast, index) in toasts" :key="index">
        <CAlert class="m-0" :color="toast.color"> {{ toast.content }} </CAlert>
      </CToast>
    </CToaster>
  </CContainer>
</template>

<script>
import vSelect from "vue-select";
import StarRating from "vue-star-rating";
import { Swiper, SwiperSlide } from "swiper/vue";
import ShowroomCardTwo from "@/components/shared-components/showroom-card/ShowroomCardTwo.vue";
import MinusIcon from "@/assets/svg/MinusIcon.vue";
import PlusIcon from "@/assets/svg/PlusIcon.vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import HeartIcon from "@/assets/svg/HeartIcon.vue";
import HorizontalAdBlock from "@/containers/e-commerce/horizontal-ads-block/HorizontalAdBlock.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import router from "@/router";

import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    vSelect,
    StarRating,
    Swiper,
    SwiperSlide,
    ShowroomCardTwo,
    MinusIcon,
    PlusIcon,
    SwiperNav,
    HeartIcon,
    HorizontalAdBlock,
  },
  name: "VendorItemBlock",
  data() {
    return {
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 2.5,
          spaceBetween: 30,
        },
      },
      itemData: {},
      initVariations: [],
      variations: [],
      cartItemToAdd: [],
      addingToCart: null,
      totalNumberOfItems: 0,
    };
  },

  mounted() {
    this.getItemDetailForVendor(this.$route.params.id);
  },

  methods: {
    getItemDetailForVendor: function (itemSlug) {
      const url = UrlConfig.urlHelper.hostUrl + "/get-product-detail/" + itemSlug;
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios.get(url, header)
          .then((response) => {
            const item = response.data.data;
            this.itemData = item;
            //For Variations
            const { product } = item;
            this.initVariations = [
              {
                sizes: product.size.map((size) => ({
                  ...size,
                  selected: false,
                })),
                colors: product.color_product_images.map((color) => ({
                  ...color,
                  color: color.color,
                  selected: false,
                })),
                qty: product.vendor_minimum_quantity,
                colorID: null,
                sizeID: null,
                colorName: null,
                sizeName: null,
              },
            ];
          })
          .catch((error) => {
            toast.success(error.response.data.message, {
              timeout: 2000,
            });
          });
    },

    addVariation: function (id) {
      const item = this.itemData;

      const { product } = item;

      this.initVariations = [
        ...this.initVariations,
        {
          colors: product?.color_product_images?.map((color) => ({
            ...color,
            color: color.color,
            selected: false,
          })),
          sizes: product?.size?.map((size) => ({
            ...size,
            selected: false,
          })),
          qty: 1,
          colorID: null,
          sizeID: null,
          colorName: null,
          sizeName: null,
        },
      ];
    },

    sizeClick: function (options) {
      const { index, id, sizeID, sizeName } = options;

      this.initVariations = this.initVariations.map((variation, idx) => {
        if (idx === index) {
          return {
            ...variation,
            sizes: variation.sizes.map((size) => {
              if (size.product_size_id === id) {
                return {
                  ...size,
                  selected: true,
                };
              } else {
                return {
                  ...size,
                  selected: false,
                };
              }
            }),
            sizeID,
            sizeName,
          };
        } else {
          return {
            ...variation,
          };
        }
      });
    },

    colorClick: function (options) {
      console.log(options);
      const { index, color, colorID, colorName } = options;

      this.initVariations = this.initVariations.map((variation, idx) => {
        if (idx === index) {
          return {
            ...variation,
            colors: variation.colors.map((c) => {
              if (c === color) {
                return {
                  ...c,
                  selected: true,
                };
              } else {
                return {
                  ...c,
                  selected: false,
                };
              }
            }),
            colorID,
            colorName,
          };
        } else {
          return {
            ...variation,
          };
        }
      });
      this.itemData.product.product_quantity = options.qty;
    },

    getNumberOfItems: function () {
      const result = this.initVariations.reduce((pv, cv) => {
        return pv + cv.qty;
      }, 0);

      this.totalNumberOfItems = result;
    },

    increaseItemQty: function (index) {
      if (
          this.totalNumberOfItems >= this.itemData?.product?.product_quantity
      ) {
        return;
      }

      this.initVariations = this.initVariations.map((v, i) => {
        if (i === index) {
          return {
            ...v,
            qty: v.qty + 1,
          };
        } else {
          return v;
        }
      });

      this.getNumberOfItems();
    },

    decreaseItemQty: function (index) {
      if (
          this.totalNumberOfItems ===
          this.itemData?.product?.vendor_minimum_quantity
      ) {
        return;
      }

      this.initVariations = this.initVariations.map((v, i) => {
        if (i === index) {
          if (v.qty === 1) {
            return {
              ...v,
            };
          } else {
            return {
              ...v,
              qty: v.qty - 1,
            };
          }
        } else {
          return v;
        }
      });

      this.getNumberOfItems();
    },

    removeVaration: function (index) {
      this.initVariations = this.initVariations.filter(
          (variation, idx) => idx !== index
      );
    },

    addToCartAPI: function () {
      if (localStorage.getItem("token") == null) {
        router.push("/login");
      }
      let qty = 0;
      const cartItems = this.initVariations.map((variation) => {
        qty = qty + variation.qty;
        return {
          ...variation,
          product_id: this.itemData?.product?.id,
          quantity: variation.qty,
          product_color_id: variation.colorID,
          product_size_id: variation.sizeID,
          product_color: variation.colorName,
          product_size: variation.sizeName,
          note: "",
          product_custom_attributes: "",
          product_custom_attribute_value: "",
        };
      });
      const payload = {
        cartItemDetail: [...cartItems],
      };
      if(qty > this.itemData?.product?.product_quantity){
        return toast.error("Order quantity cannot be more than stock quantity.", {
          timeout: 4000,
        });
        this.addingToCart = false;
      }
      this.addingToCart = true;
      this.$store.dispatch("product/addToCart", payload).then((res) => {
        this.addingToCart = false;
        toast.success("Item Added To Cart Successfully", {
          timeout: 2000,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
