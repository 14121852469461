<template>
  <page-banner-two>
    <div class="col-md-7">
      <banner-title
        tagline="From Our Blogs"
        :title="banner_detail.page_title"
        :description="banner_detail.page_title_description"
      />
    </div>
  </page-banner-two>
  <Breadcrumb :data="breadcrumb"/>
  <section class="pt-5">
    <CContainer>
      <CRow>
        <CCol md="8">
          <CRow>
            <CCol md="6" class="mb-4" v-for="blog in blogs" :key="blog.id">
              <article-card :article="blog" />
            </CCol>
          </CRow>
          <Pagination
            :data="pageDetail"
            @pagination-change-page="blogByCategory"
          />
        </CCol>

        <CCol md="4">
          <div class="mb-4">
            <h5>Categories</h5>
            <CListGroup>
              <CListGroupItem>
                <router-link to="/blogs" @click="selectedBlogCategory('all')"
                  >All Category
                </router-link>
              </CListGroupItem>

              <CListGroupItem
                v-for="category in blogCategory"
                :key="blogCategory.id"
              >
                <router-link
                  to="/blogs"
                  @click="selectedBlogCategory(category.slug)"
                  >{{ category.name }}
                </router-link>
              </CListGroupItem>
            </CListGroup>
          </div>
          <div class="side-pouplar-blogs">
            <h5>Popular Blogs</h5>
            <div
              class="d-flex py-3 blog-item"
              v-for="blogData in popularBlog"
              :key="blogData.id"
            >
              <div class="thumb-img me-3">
                <router-link :to="'/blogs/' + blogData.slug">
                  <img :src="blogData.image"
                /></router-link>
              </div>
              <div>
                <div class="meta">
                  <div class="date mb-2">{{ blogData.date }}</div>
                  <div class="title">
                    <h6>
                      <router-link :to="'/blogs/' + blogData.slug">
                        {{ blogData.title }}
                      </router-link>
                    </h6>
                  </div>
                  <div class="category">{{ blogData.category }}</div>
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>

  <newsletter-sub />
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import PageBannerTwo from "@/components/shared-components/page-banner/PageBannerTwo.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import ArticleCard from "@/components/shared-components/article-card/ArticleCard.vue";
import Avatar from "@/components/shared-components/avatar/Avatar.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import Pagination from "laravel-vue-pagination";
import { useToast } from "vue-toastification";
import { helper } from "@/helper/Helper";

const toast = useToast();

export default {
  components: {
    Breadcrumb,
    PageBannerTwo,
    BannerTitle,
    ArticleCard,
    Avatar,
    NewsletterSub,
    Pagination,
  },
  name,
  Avatar: "Blogs",

  data() {
    return {
      banner_detail: ref(""),
      blogs: ref([]),
      popularBlog: ref([]),
      blogCategory: ref([]),
      pageDetail: ref(""),
      selectedCat: "all",
      progress: {
        background: "blue",
      },
    };
  },

  beforeCreate(){
    this.breadcrumb = [
      {label: 'Home', url: '/'},
      {label: 'Blogs', url: '*'}
    ];
  },
  
  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    let helperObj = new helper();
    helperObj.resetLocalStorageProductDataFilter();
    this.getPageBanner();
    this.getBlogCategory();
    this.getPopularBlog();
    this.blogByCategory();
  },

  methods: {
    async getPageBanner() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-page-banner/blog_list";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let pageDetail = response.data.data;
          if (response.data.status_code === 200 && pageDetail !== "") {
            this.banner_detail = pageDetail;
          }
        })
        .catch((error) => {
          toast.error("Sorry ! some error occurred", {
            timeout: 2000,
          });
        });
    },

    selectedBlogCategory(categoryId) {
      this.selectedCat = categoryId;
      this.blogByCategory();
    },

    async getBlogCategory() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-blog-category";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let blogCategory = response.data.data;
          if (response.data.status_code === 200 && blogCategory.length > 0) {
            Object.entries(blogCategory).forEach(([key, value]) => {
              this.blogCategory.push({
                id: value.id,
                name: value.name,
                slug: value.slug,
              });
            });
          }
        })
        .catch((error) => {
          toast.error("Sorry ! some error occurred", {
            timeout: 2000,
          });
        });
    },

    async blogByCategory(page = 1) {
      let categoryId = this.selectedCat ?? "all";
      const url =
        UrlConfig.urlHelper.hostUrl +
        "/get-blog-by-category/" +
        categoryId +
        "?page=" +
        page;
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let blogDetail = response.data.data;
          this.pageDetail = blogDetail.page_details;
          if (response.data.status_code === 200 && blogDetail.blog.length > 0) {
            this.blogs = [];
            Object.entries(blogDetail.blog).forEach(([key, value]) => {
              this.blogs.push({
                id: value.blog_id,
                category: value.blog_category_name,
                date: value.blog_publish_date,
                title: value.title,
                author: value.blog_created_by,
                readTime: value.blog_read_time,
                image: value.blog_image_path,
                authorImage: value.blog_creator_image_path,
                slug: value.slug,
              });
            });
          }
        })
        .catch((error) => {
          toast.error("Sorry ! some error occurred", {
            timeout: 2000,
          });
        });
    },

    async getPopularBlog() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-feature-blog";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let popularBlogDetail = response.data.data;
          if (
            response.data.status_code === 200 &&
            popularBlogDetail.length > 0
          ) {
            Object.entries(popularBlogDetail).forEach(([key, value]) => {
              this.popularBlog.push({
                id: value.blog_id,
                category: value.blog_category_name,
                date: value.blog_publish_date,
                title: value.title,
                image: value.blog_image_path,
                slug: value.slug,
              });
            });
          }
        })
        .catch((error) => {
          toast.error("Sorry ! some error occurred", {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
