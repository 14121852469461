<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8988 9.7438C18.8988 15.0801 15.2445 18.8778 9.84783 18.8778C4.67367 18.8778 0.495117 14.6992 0.495117 9.52507C0.495117 4.35091 4.67367 0.172363 9.84783 0.172363C12.367 0.172363 14.4865 1.09632 16.1194 2.61991L13.5738 5.06745C10.2438 1.85434 4.05141 4.26794 4.05141 9.52507C4.05141 12.7872 6.65735 15.4309 9.84783 15.4309C13.5512 15.4309 14.939 12.7759 15.1578 11.3994H9.84783V8.18251H18.7518C18.8385 8.66145 18.8988 9.12155 18.8988 9.7438Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "GoogleIcon",
};
</script>

<style lang="scss" scoped></style>
