<template>
  <div class="logo">
    <router-link to="/">
      <img :src="logo" alt="" :class="class" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: ["logo", "class"],
};
</script>

<style lang="scss" scoped></style>
