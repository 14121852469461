<template>
  <section class="career-detail-page">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <div class="career-title my-4">
            <h1>{{ careerDetail.title }}</h1>
            <span>{{ careerDetail.department_name }}</span>
          </div>

          <div class="rich-text" v-html="careerDetail.description"></div>
        </CCol>
        <CCol md="6" class="career-apply-area">
          <div class="mt-5 p-4">
            <div class="mb-4">
              <span>OPENING SOON</span>
            </div>
            <div>
              <h6>OFFERED SALARY</h6>
              <p>Rs. {{ careerDetail.salary_details }}</p>
            </div>

            <div class="career-apply-form mt-5">
              <FormKit
                  type="form"
                  id="careerApply"
                  @submit="submitHandler"
                  :actions="false"
              >
                <div class="mb-4">
                  <FormKit
                      type="text"
                      name="name"
                      label="Full Name"
                      placeholder="Full Name"
                      validation="required"
                      validation-visibility="submit"
                  />
                </div>
                <div class="mb-4">
                  <FormKit
                      type="text"
                      name="email"
                      label="Email Address"
                      placeholder="Email Address"
                      validation="required"
                      validation-visibility="submit"
                  />
                </div>

                <div class="mt-4 mb-3">
                  <span>
                    <b> Upload CV </b>
                  </span>
                  <input
                      class="form-control mt-2"
                      name="cv"
                      type="file"
                      accept="application/pdf,application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      @change="onFile"
                      ref="uploadCv"
                      required
                      value=""
                  />
                </div>

                <div>
                  <button
                      class="btn btn-primary text-white py-2 px-3"
                      type="submit"
                  >
                    <CSpinner
                        v-if="careerApplying"
                        component="span"
                        size="sm"
                        aria-hidden="true"
                    />

                    {{ careerApplying ? "Submitting..." : "Submit" }}
                  </button>
                </div>
              </FormKit>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
  <newsletter-sub/>
</template>

<script>
import UploadIcon from "@/assets/svg/UploadIcon.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {ref} from "vue";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  components: {UploadIcon, NewsletterSub},
  name: "CareerDetail",

  data() {
    return {
      careerDetail: ref(""),
      cv: "",
      careerApplying: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getCareerDetail(this.$route.params.id);
  },

  methods: {
    submitHandler: function (values) {
      this.careerApplying = true;
      let formData = {
        name: values.name,
        email: values.email,
        cv_file: this.cv,
        career_id: this.$route.params.id,
      };
      const path = UrlConfig.urlHelper.hostUrl + "/save-application-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
          .post(path, formData, header)
          .then((response) => {
            const data = response.data.data;
            if (
                response.data.status_code === 200 &&
                response.data.message !== ""
            ) {
              this.careerApplying = false;
              this.$formkit.reset("careerApply");
              this.$refs.uploadCv.value = "";

              toast.success(response.data.message, {
                timeout: 2000,
              });
            }
          })
          .catch((error) => {
            this.careerApplying = false;
            let errorResponse = error.response.data;
            if (errorResponse.data !== "") {
              let loop = 0;
              Object.entries(errorResponse.data.error).forEach(([key, value]) => {
                loop = loop + 1;
                let errorTimeAdd = 1000 * parseInt(loop);
                toast.error(value, {
                  timeout: 2000 + parseInt(errorTimeAdd),
                });
              });
            } else {
              toast.error(errorResponse.message, {
                timeout: 2000,
              });
            }
          });
    },

    getCareerDetail(careerId) {
      const url =
          UrlConfig.urlHelper.hostUrl + "/get-career-detail/" + careerId;
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
          .get(url, header)
          .then((response) => {
            let careerDetail = response.data.data;
            if (response.data.status_code === 200 && careerDetail !== "") {
              this.careerDetail = careerDetail;
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
    },

    onFile(e) {
      const files = e.target.files;
      this.cv = files[0];
    },
  },
};
</script>

<style lang="scss" scoped></style>
