<template>
  <section class="rider-story-block py-5">
    <CContainer>
      <CRow>
        <CCol md="4">
          <div class="rider-thumb">
            <img :src="image" alt="" />
          </div>
        </CCol>
        <CCol md="8">
          <div class="rider-detail">
            <h4>{{name}}</h4>
            <p>{{designation}}</p>
          </div>
          <div class="rich-text">
            <p v-html="description"></p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
export default {
  name: "RiderStoryBlock",

  props:['name','image','designation','description']
};
</script>

<style lang="scss" scoped></style>
