<template>
  <CContainer>
    <div class="row justify-content-md-center">
      <CCol md="7" lg="5">
        <div class="mb-4">
          <auth-title title="Change Email" />
        </div>
      </CCol>
    </div>
  </CContainer>
  <section class="change-pass-form">
    <change-email-form />
  </section>
</template>

<script>
import { CContainer, CCol } from "@coreui/vue";
import AuthTitle from "@/components/shared-components/auth-title/AuthTitle.vue";
import ChangeEmailForm from "@/containers/auth/change-email-form/ChangeEmailForm";

export default {
  components: {
    CContainer,
    CCol,
    AuthTitle,
    ChangeEmailForm,
  },
  name: "ChangeEmail",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
