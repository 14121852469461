<template>
  <FormKit
      type="form"
      :value="values"
      @submit="submitHandler"
      :actions="false"
      enctype="multipart/form-data"
  >
    <CCard class="p-4">
      <heading title="Account Information"/>
      <div class="user-profile-imag">
        <div>
          <p>Profile Picture</p>
        </div>
        <img :src="avatar" alt=""/>

        <div class="mt-4">
          <input
              class="form-control"
              name="avatar"
              type="file"
              accept="image/png,image/jpg,image/jpeg"
              ref="file"
              @change="onFile"
          />
          <button
              class="btn btn-primary mt-2"
              @click.prevent="uploadProfileImage"
          >
            Upload
          </button>
        </div>
      </div>
      <div class="info-inputs mt-4">
        <CRow>
          <CCol md="6" class="mb-4">
            <FormKit
                type="text"
                name="fname"
                v-model="values.fname"
                label="First Name"
                placeholder="First Name"
                validation="required"
                validation-visibility="submit"
            />
          </CCol>

          <CCol md="6" class="mb-4">
            <FormKit
                type="text"
                name="mname"
                v-model="values.middle_name"
                label="Middle Name"
                placeholder="Middle Name"
                validation="string"
                validation-visibility="submit"
            />
          </CCol>

          <CCol md="6" class="mb-4">
            <FormKit
                type="text"
                name="lname"
                v-model="values.lname"
                label="Last Name"
                placeholder="Last Name"
                validation="required"
                validation-visibility="submit"
            />
          </CCol>

          <!--            <CCol md="6" class="mb-4">-->
          <!--              <FormKit-->
          <!--                  type="text"-->
          <!--                  name="mobile"-->
          <!--                  v-model="values.mobile"-->
          <!--                  label="Mobile Number"-->
          <!--                  placeholder="Mobile Number"-->
          <!--                  validation="number"-->
          <!--                  validation-visibility="submit"-->
          <!--              />-->
          <!--            </CCol>-->
        </CRow>
      </div>
    </CCard>
    <div class="mt-4">
      <CCard class="p-4 mb-3">
        <heading title="Delivery Address 1"/>
        <CRow>
          <CCol md="6" class="mb-4">
            <FormKit
                type="text"
                name="primaryAddressOne"
                v-model="values.primaryAddressOne"
                label="Address Line 1"
                placeholder="Address Line 1"
                validation="required"
                validation-visibility="submit"
            />
          </CCol>
          <CCol md="6" class="mb-4">
            <FormKit
                type="text"
                name="primaryAddressTwo"
                v-model="values.primaryAddressTwo"
                label="Address Line 2"
                placeholder="Address Line 2"
                validation="required"
                validation-visibility="submit"
            />
          </CCol>
        </CRow>
      </CCard>
    </div>
    <div class="mt-4">
      <CCard class="p-4 mb-3">
        <heading title="Phone Number 1"/>
        <CRow>
          <CCol md="6" class="mb-4">
            <FormKit
                type="text"
                name="phoneNumberOne"
                v-model="values.phoneNumberOne"
                label="Primary"
                placeholder="Phone Number 1"
                validation="number"
                validation-visibility="submit"
            />
          </CCol>
          <CCol md="6" class="mb-4">
            <FormKit
                type="text"
                name="phoneNumberTwo"
                v-model="values.phoneNumberTwo"
                label="Secondary"
                placeholder="Phone Number 2"
                validation="number"
                validation-visibility="submit"
            />
          </CCol>
        </CRow>
      </CCard>

      <CCard class="p-4 mb-3">
        <heading title="Phone Number 2 (Optional)"/>
        <CRow>
          <CCol md="6" class="mb-4">
            <FormKit
                type="text"
                name="optionalPhoneNumberOne"
                v-model="values.optionalPhoneNumberOne"
                label="Primary"
                placeholder="Phone Number 1"
                validation-visibility="submit"
            />
          </CCol>
          <CCol md="6" class="mb-4">
            <FormKit
                type="text"
                name="optionalPhoneNumberTwo"
                v-model="values.optionalPhoneNumberTwo"
                label="Secondary"
                placeholder="Phone Number 2"
                validation-visibility="submit"
            />
          </CCol>
        </CRow>
      </CCard>
    </div>

    <div class="mt-4" v-if="addressShowHide === false">
      <button class="btn btn-primary text-white py-2 px-3" type="submit">
        <CSpinner
            v-if="updating"
            component="span"
            size="sm"
            aria-hidden="true"
        />
        {{ updating ? "updating..." : "update" }}
      </button>
    </div>
  </FormKit>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {reactive, ref} from "vue";
import router from "@/router";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  name: "EditProfile",
  components: {Heading},

  data() {
    return {
      values: reactive({
        fname: "",
        lname: "",
        mname: "",
        mobile: "",
        primaryAddressOne: "",
        primaryAddressTwo: "",
        phoneNumberOne: "",
        phoneNumberTwo: "",
        optionalPhoneNumberOne: "",
        optionalPhoneNumberTwo: "",
        upload_profile: "",
      }),
      avatar: ref(""),
      addressShowHide: false,
      updating: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getUserProfileDetail();
  },

  methods: {
    submitHandler: function (values) {
      this.updating = true;
      const path = UrlConfig.urlHelper.hostUrl + "/update-customer-profile";
      const header = UrlConfig.authorizedUrlHeader;
      let formData = {
        first_name: values.fname,
        middle_name: values.mname,
        last_name: values.lname,
        mobile: values.mobile,
        address_line1: values.primaryAddressOne,
        address_line2: values.primaryAddressTwo,
        primary_contact_1: values.phoneNumberOne,
        primary_contact_2: values.phoneNumberTwo,
        secondary_contact_1: values.optionalPhoneNumberOne,
        secondary_contact_2: values.optionalPhoneNumberTwo,
      };
      axios
          .post(path, formData, header)
          .then((response) => {
            this.updating = false;
            if (
                response.data.status_code === 200 &&
                response.data.message !== ""
            ) {
              toast.success(response.data.message, {
                timeout: 2000,
              });
              router.push("/dashboard/profile");
            }
          })
          .catch((error) => {
            this.updating = false;
            let errorResponse = error.response.data;
            if (errorResponse.data !== "") {
              let loop = 0;
              Object.entries(errorResponse.data.error).forEach(([key, value]) => {
                loop = loop + 1;
                let errorTimeAdd = 1000 * parseInt(loop);
                toast.error(value, {
                  timeout: 2000 + parseInt(errorTimeAdd),
                });
              });
            } else {
              toast.error(errorResponse.message, {
                timeout: 2000,
              });
            }
          });
    },

    async uploadProfileImage() {
      this.updating = true;
      const path = UrlConfig.urlHelper.hostUrl + "/update-customer-image";
      const header = UrlConfig.authorizedUrlHeader;
      let formData = {
        profile_image: this.values.upload_profile,
      };
      await axios
          .post(path, formData, header)
          .then((response) => {
            this.updating = false;
            if (
                response.data.status_code === 200 &&
                response.data.message !== ""
            ) {
              let changeProfileImage = response.data.data;
              localStorage.removeItem("avatar");
              localStorage.setItem("avatar", changeProfileImage);
              toast.success("Profile picture updated successfully.", {
                timeout: 2000,
              });
              router.push("/dashboard/profile");

              this.$store.dispatch("profile/setAvatar", changeProfileImage);
            }
          })
          .catch((error) => {
            this.updating = false;
            let errorResponse = error.response.data;
            if (errorResponse.data !== "") {
              let loop = 0;
              Object.entries(errorResponse.data.error).forEach(([key, value]) => {
                loop = loop + 1;
                let errorTimeAdd = 1000 * parseInt(loop);
                toast.error(value, {
                  timeout: 2000 + parseInt(errorTimeAdd),
                });
              });
            } else {
              toast.error(errorResponse.message, {
                timeout: 2000,
              });
            }
          });
    },

    async getUserProfileDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-customer-details";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {};
      await axios
          .post(url, data, header)
          .then((response) => {
            let profileDetail = response.data.data;
            if (response.data.status_code === 200 && profileDetail !== "") {
              this.avatar = profileDetail.profile_image;
              this.values.fname = profileDetail.first_name;
              this.values.lname = profileDetail.last_name;
              this.values.mname = profileDetail.middle_name;
              this.values.mobile = profileDetail.mobile;
              this.values.primaryAddressOne = profileDetail.address_line1;
              this.values.primaryAddressTwo = profileDetail.address_line2;
              this.values.phoneNumberOne = profileDetail.primary_contact_1;
              this.values.phoneNumberTwo = profileDetail.primary_contact_2;
              this.values.optionalPhoneNumberOne =
                  profileDetail.secondary_contact_1;
              this.values.optionalPhoneNumberTwo =
                  profileDetail.secondary_contact_2;
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
    },

    onFile(e) {
      const files = e.target.files;
      this.values.upload_profile = files[0];
      if (!files.length) return;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => (this.avatar = reader.result);
    },
  },
};
</script>

<style lang="scss" scoped></style>
