<template>
  <business-hero-slider />
  <Breadcrumb :data="breadcrumb"/>
  <div class="my-5">
    <advanced-search />
  </div>

  <category-tiles />

  <div class="mt-5">
    <body-ad>
      <div class="text-center">
        <p class="m-0">own it now, up to 12 months free insurance learn more</p>
      </div>
    </body-ad>
  </div>

  <section class="my-5">
    <item-carousel
      sectionTitle="Featured Items"
      subTitle="own it now, up to 6 months interest free!"
      prevEl="featuredPrevArrow"
      nextEl="featuredNextArrow"
      @popupControl="popupControl"
    />
  </section>

  <section class="pb-5">
    <horizontal-ad-block
      :shop-ad-detail-one="shopAdBlockDetail.shopTopSectionOne"
      :shop-ad-detail-two="shopAdBlockDetail.shopTopSectionTwo"
    />
  </section>

  <section class="pb-5">
    <recommended-item-carousel
      sectionTitle="Recommended Items"
      subTitle=" own it now, up to 6 months interest free!"
      prevEl="recomPrevArrow"
      nextEl="recomNextArrow"
    />
  </section>

  <section class="pb-5">
    <flash-sale />
  </section>

  <section class="pb-5">
    <item-helmet-carousel
      sectionTitle="Helmets"
      subTitle=" own it now, up to 6 months interest free!"
      prevEl="helmetsPrevArrow"
      nextEl="helmetsdNextArrow"
    />
  </section>

  <section class="py-5 bg-gray">
    <body-banner :banner-detail="shopBodyBanner" />
  </section>

  <section class="py-5">
    <item-riding-gears-carousel
      sectionTitle="Riding Gears"
      subTitle=" own it now, up to 6 months interest free!"
      prevEl="gearsPrevArrow"
      nextEl="gearsdNextArrow"
    />
  </section>

  <section class="pb-5">
    <feature-item-apparels-carousel
      sectionTitle="Apparaels"
      subTitle=" own it now, up to 6 months interest free!"
      prevEl="aperalsPrevArrow"
      nextEl="aperalsdNextArrow"
    />
  </section>

  <section class="pt-5">
    <horizontal-ad-block
      :shop-ad-detail-one="shopAdBlockDetail.shopBottomSectionOne"
      :shop-ad-detail-two="shopAdBlockDetail.shopBottomSectionTwo"
    />
  </section>

  <section class="mt-5">
    <e-callouts />
  </section>

  <section class="pt-5">
    <featured-accessories
      sectionTitle="Accessories"
      subTitle=" own it now, up to 6 months interest free!"
      prevEl="accessPrevArrow"
      nextEl="accessNextArrow"
    />
  </section>

  <newsletter-sub />

  <CModal
    :visible="itemPopupVisible"
    size="lg"
    scrollable="true"
    @close="
      this.$store.dispatch('itemPopup/popupHandler', {
        popupVisible: false,
      })
    "
  >
    <CModalHeader>
      <CModalTitle>Product name</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <item-popup :item="adData" />
    </CModalBody>
  </CModal>
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import BusinessHeroSlider from "@/containers/business/hero-slider/BusinessHeroSlider.vue";
import CategoryTiles from "@/containers/business/category-tiles/CategoryTiles.vue";
import BodyAd from "@/containers/e-commerce/body-ad/BodyAd.vue";
import HorizontalAdBlock from "@/containers/e-commerce/horizontal-ads-block/HorizontalAdBlock.vue";
import ItemCarousel from "@/containers/e-commerce/item-carousel/ItemCarousel.vue";
import RecommendedItemCarousel from "@/containers/e-commerce/item-carousel/RecommendedItemCarousel";
import ItemRidingGearsCarousel from "@/containers/e-commerce/item-carousel/ItemRidingGearsCarousel";
import ItemHelmetCarousel from "@/containers/e-commerce/item-carousel/ItemHelmetCarousel";
import FeatureItemApparelsCarousel from "@/containers/e-commerce/item-carousel/FeatureItemApparelsCarousel";
import FeaturedAccessories from "@/containers/e-commerce/item-carousel/FeaturedAccessories";
import FlashSale from "@/containers/business/flash-sale/FlashSale.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import ECallouts from "@/containers/e-commerce/e-callouts/ECallouts.vue";
import BodyBanner from "@/containers/e-commerce/body-banner/BodyBanner.vue";
import AdvancedSearch from "@/containers/e-commerce/advanced-search/AdvancedSearch.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import ItemPopup from "../../components/shared-components/item-poup/ItemPopup.vue";
import { mapState } from "vuex";
import { useToast } from "vue-toastification";
import { helper } from "@/helper/Helper";
const toast = useToast();

export default {
  name: "Shop",
  components: {
    Breadcrumb,
    BusinessHeroSlider,
    CategoryTiles,
    BodyAd,
    HorizontalAdBlock,
    ItemCarousel,
    FlashSale,
    NewsletterSub,
    ECallouts,
    BodyBanner,
    AdvancedSearch,
    RecommendedItemCarousel,
    ItemRidingGearsCarousel,
    ItemHelmetCarousel,
    FeatureItemApparelsCarousel,
    FeaturedAccessories,
    ItemPopup,
  },

  data() {
    return {
      shopAdBlockDetail: ref(""),
      shopBodyBanner: ref(""),
    };
  },

  beforeCreate(){
    this.breadcrumb = [
      {label: 'Home', url: '/'},
      {label: 'Shop', url: '*'}
    ];
  },
  
  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    let helperObj = new helper();
    helperObj.resetLocalStorageProductDataFilter();
    this.getDetailOfShopAdBlock();
  },

  computed: {
    ...mapState({
      itemPopupVisible: (state) => state.itemPopup.itemPopupVisible,
    }),
  },

  methods: {
    async getDetailOfShopAdBlock() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-shop-ad-block";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let shopAdBlockData = response.data.data;
          if (response.data.status_code === 200 && shopAdBlockData !== "") {
            this.shopAdBlockDetail = shopAdBlockData;
            this.shopBodyBanner = shopAdBlockData.shopSectionBody;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    popupControl(option) {
      this.itemPopupVisible = option.visible;
      this.selectedItem = option.item;
    },
  },
};
</script>

<style lang="scss" scoped></style>
