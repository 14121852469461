<template>
  <section>
    <page-banner
        :image=bannerDetail.page_image_path
    >
      <div class="col-md-7">
        <banner-title
            :tagline=bannerDetail.page_title
            :title = bannerDetail.page_sub_title
            :description=bannerDetail.page_title_description
        />
      </div>
      <CCol md="9" class="slide-action px-3 py-2 text-center">
        <router-link to="/career" class="btn btn-primary">View Details</router-link>
      </CCol>

    </page-banner>
  </section>
</template>

<script>
import PageBanner from "@/components/shared-components/page-banner/PageBanner.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle";
import {useToast} from "vue-toastification";
const toast = useToast();
export default {
  components: {SectionTitle, BannerTitle, PageBanner },
  name: "CareerLinkBanner",

  data() {
    return {
      bannerDetail:ref(''),
    };
  },
  mounted() {
    this.getPageBanner();
  },

  methods: {
    async getPageBanner() {
      const url = UrlConfig.urlHelper.hostUrl + '/get-page-banner/career_list';
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios.get(url, header)
          .then(response => {
            let pageDetail = response.data.data;
            if (response.data.status_code === 200 && pageDetail !== '') {
              this.bannerDetail = pageDetail;
            }
          }).catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
