import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/business-views/Home.vue");
const About = () => import("../views/business-views/About.vue");
import PageLogin from "../views/auth-views/PageLogin.vue";
import PageRegister from "../views/auth-views/PageRegister.vue";
import UserRegistration from "../views/auth-views/UserRegistration.vue";
import ForgotPassword from "../views/auth-views/ForgotPassword.vue";
import ChangePassword from "../views/auth-views/ChangePassword.vue";
import Team from "../views/business-views/Team.vue";
import TeamDetail from "../views/business-views/TeamDetail.vue";
import Faqs from "../views/business-views/Faqs.vue";
import Career from "../views/business-views/Career.vue";
import CareerList from "../views/business-views/CareerList.vue";
import CareerDetail from "../views/business-views/CareerDetail.vue";
import Showrooms from "../views/business-views/Showrooms.vue";
import Blogs from "../views/business-views/Blogs.vue";
import BlogDetail from "../views/business-views/BlogDetail.vue";
import GeneralPage from "../views/business-views/GeneralPage.vue";
import ContactUs from "../views/business-views/ContactUs.vue";
import Csr from "../views/business-views/Csr.vue";
import RiderStory from "../views/business-views/RiderStory.vue";
import Profile from "../views/dashboard-views/Profile.vue";
import Settings from "../views/dashboard-views/Settings.vue";
import EditProfile from "../views/dashboard-views/EditProfile.vue";
import MyOrders from "../views/dashboard-views/MyOrders.vue";
import OrderDetail from "../views/dashboard-views/OrderDetail.vue";
import PastOrders from "../views/dashboard-views/PastOrders.vue";
import ReturnItem from "../views/dashboard-views/ReturnItem.vue";
import ReturnItemDetail from "../views/dashboard-views/ReturnItemDetail.vue";
import MyTickets from "../views/dashboard-views/MyTickets.vue";
import TicketDetail from "../views/dashboard-views/TicketDetail.vue";
import Notifications from "../views/dashboard-views/Notifications.vue";
import ItemDetail from "../views/ecommerce-views/ItemDetail.vue";
import Wishlist from "../views/ecommerce-views/Wishlist.vue";
import Cart from "../views/ecommerce-views/Cart.vue";
import Checkout from "../views/ecommerce-views/Checkout.vue";
import GiftCards from "../views/ecommerce-views/GiftCards.vue";
import ItemListing from "../views/ecommerce-views/ItemListing.vue";
import Shop from "../views/ecommerce-views/Shop.vue";
import Payment from "../views/ecommerce-views/Payment.vue";
import BrandPage from "../views/ecommerce-views/BrandPage.vue";
import ChangeEmail from "@/views/auth-views/ChangeEmail";
import PageNotFound from "@/views/business-views/PageNotFound";

import appLayout from "../layouts/AppLayout.vue";
import authLayout from "../layouts/AuthLayout.vue";
import dashboardLayout from "../layouts/DashboardLayout.vue";
import ecommerceLayout from "../layouts/EcommerceLayout.vue";
import VerifyEmail from "@/views/dashboard-views/VerifyEmail";

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/team/:id",
    name: "TeamDetail",
    component: TeamDetail,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: Faqs,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/career",
    name: "Career",
    component: Career,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/careers/:id",
    name: "CareerList",
    component: CareerList,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/career/:id",
    name: "CareerDetail",
    component: CareerDetail,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/showrooms",
    name: "Showrooms",
    component: Showrooms,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/blogs/:id",
    name: "BlogDetail",
    component: BlogDetail,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/general-page/:page_slug",
    name: "GeneralPage",
    component: GeneralPage,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },

  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/csr",
    name: "Csr",
    component: Csr,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/rider-story/:id",
    name: "RiderStory",
    component: RiderStory,
    meta: {
      requiresAuth: false,
      layout: appLayout,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: PageLogin,
    meta: {
      requiresAuth: false,
      layout: authLayout,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: PageRegister,
    meta: {
      requiresAuth: false,
      layout: authLayout,
    },
  },
  {
    path: "/register/:type",
    name: "UserRegistration",
    component: UserRegistration,
    meta: {
      requiresAuth: false,
      layout: authLayout,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
      layout: authLayout,
    },
  },
  {
    path: "/change-email",
    name: "ChangeEmail",
    component: ChangeEmail,
    meta: {
      requiresAuth: true,
      layout: authLayout,
    },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      requiresAuth: true,
      layout: authLayout,
    },
  },
  {
    path: "/dashboard/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  {
    path: "/dashboard/profile/edit",
    name: "EditProfile",
    component: EditProfile,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  {
    path: "/dashboard/my-orders",
    name: "MyOrders",
    component: MyOrders,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  {
    path: "/dashboard/order/:id",
    name: "OrderDetail",
    component: OrderDetail,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  {
    path: "/dashboard/past-orders",
    name: "PastOrders",
    component: PastOrders,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  {
    path: "/dashboard/:orderId/return/:productId",
    name: "ReturnItem",
    component: ReturnItem,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  // {
  //   path: "/dashboard/return",
  //   name: "ReturnItemDetail",
  //   component: ReturnItemDetail,
  //   meta:{
  //     requiresAuth:true
  //   }
  // },
  {
    path: "/dashboard/my-tickets",
    name: "MyTickets",
    component: MyTickets,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  {
    path: "/dashboard/my-tickets/:id",
    name: "TicketDetail",
    component: TicketDetail,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  {
    path: "/dashboard/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  {
    path: "/dashboard/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      requiresAuth: true,
      layout: dashboardLayout,
    },
  },
  {
    path: "/items/:id",
    name: "ItemDetail",
    component: ItemDetail,
    meta: {
      requiresAuth: false,
      layout: ecommerceLayout,
    },
  },

  {
    path: "/wishlist",
    name: "Wishlist",
    component: Wishlist,
    meta: {
      requiresAuth: true,
      layout: ecommerceLayout,
    },
  },

  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: {
      requiresAuth: true,
      layout: ecommerceLayout,
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      requiresAuth: true,
      layout: ecommerceLayout,
    },
  },
  {
    path: "/gift-cards",
    name: "GiftCards",
    component: GiftCards,
    meta: {
      requiresAuth: true,
      layout: ecommerceLayout,
    },
  },
  {
    path: "/category/:id",
    name: "Category",
    component: ItemListing,
    meta: {
      requiresAuth: false,
      layout: ecommerceLayout,
    },
  },

  {
    path: "/shop",
    name: "Shop",
    component: Shop,
    meta: {
      requiresAuth: false,
      layout: ecommerceLayout,
    },
  },

  {
    path: "/brand/:id",
    name: "Brand",
    component: BrandPage,
    meta: {
      requiresAuth: false,
      layout: ecommerceLayout,
    },
  },

  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    meta: {
      requiresAuth: true,
      layout: ecommerceLayout,
    },
  },

  {
    path: "/verify-email",
    name: "verify-email",
    component: VerifyEmail ,
    meta: {
      requiresAuth: true,
      layout: ecommerceLayout,
    },
  },

  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
    meta: {
      requiresAuth: false,
      layout: ecommerceLayout,
    },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !localStorage.getItem("token")) {
    return { name: "Login" };
  }
  // if(to.meta.requiresAuth === false && localStorage.getItem('token')){
  //   return { name : '/'}
  // }
});

export default router;
