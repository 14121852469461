<template>
  <CCard>
    <div class="item-card p-4">
      <template v-if="item.tag.type === 'new'">
        <div class="item-badge badge-new">{{ item.tag.name }}</div>
      </template>
      <template v-if="item.tag.type === 'sale'">
        <div class="item-badge badge-sale">{{ item.tag.name }}</div>
      </template>
      <div class="item-images text-center">
        <router-link :to="'/items/' + item.slug"
          ><img
            :src="activeImage === '' ? item.images[0]?.image : activeImage" 
            :alt="altTitle === undefined ? 'Featured Item' : altTitle"
            @load="onLoad"
          />
          <div v-if="!imageLoaded" class="fallback-image">
            <image-icon />
          </div>
        </router-link>
      </div>

      <div class="item-content text-center">
        <div class="item-colors mt-4">
          <span
            v-for="color in item.colors"
            class="color-palette"
            :key="color"
            :style="{
              backgroundImage: `linear-gradient(to right, ${color[0]} , ${color[1]})`,
            }"
            @mouseover="setActiveImage(color)"
            @mouseout="setActiveImage('')"
          >
          </span>
        </div>
        <div class="item-meta">
          <div class="item-reputation my-3">
            <div class="rating mr-3">
              <star-rating
                :rating="item.review_star"
                :increment="0.5"
                @update:rating="setRating"
                :show-rating="false"
                :read-only="true"
                :star-points="[
                  23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                  46, 19, 31, 17,
                ]"
                :star-size="15"
              ></star-rating>
            </div>
          </div>

          <div class="name">
            <router-link :to="'/items/' + item.slug"
              ><h5>{{ item.name }}</h5></router-link
            >
          </div>
          <div class="item-card-base">
            <div class="price mt-4">
              <span
                class="old-price text-muted me-3"
                v-show="item.oldPrice != item.newPrice"
                >Rs.{{ item.oldPrice }}</span
              >

              <span class="new-price"> Rs.{{ item.newPrice }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="floating-action">
        <div class="mb-2">
          <button @click.prevent="saveProductToWishlist(item)">
            <heart-white-icon />
          </button>
        </div>
        <div class="mb-2">
          <button
            @click="
              this.$store.dispatch('itemPopup/popupHandler', {
                popupVisible: true,
                slug: item.slug,
              })
            "
          >
            <eye-white-icon />
          </button>
        </div>
      </div>
    </div>
  </CCard>
</template>

<script>
import HeartWhiteIcon from "@/assets/svg/HeartWhiteIcon.vue";
import ImageIcon from "@/assets/svg/ImageIcon.vue";
import StarRating from "vue-star-rating";
import EyeWhiteIcon from "@/assets/svg/EyeWhiteIcon.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";

import router from "@/router";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "ItemCard",
  components: { StarRating, HeartWhiteIcon, EyeWhiteIcon, ImageIcon },
  props: ["item", "altTitle"],

  data() {
    return {
      time: 10000,
      activeImage: "",
      toastMessage: "Toast Message",
      toastColor: "success",
      imageLoaded: false,
      itemWishlistStatus: false,
    };
  },

  methods: {
    onLoad() {
      this.imageLoaded = true;
    },

    setActiveImage(color) {
      if (color === "") {
        this.activeImage = color;
      } else {
        const { image } = this.item.images.find(
          (img) => img.color[0] === color[0] && img.color[1] === color[1]
        );

        this.activeImage = image;
      }
    },

    async saveProductToWishlist(productDetail) {
      if (localStorage.getItem("token") == null) {
        router.push("/login");
      }
      if (
        productDetail.wishlist_status === true ||
        this.itemWishlistStatus === true
      ) {
        toast.error("Product already in wishlist !", {
          timeout: 2000,
        });
      } else {
        let data = {
          product_id: productDetail.id,
        };
        const path = UrlConfig.urlHelper.hostUrl + "/save-wishlist";
        const header = UrlConfig.authorizedUrlHeader;
        await axios
          .post(path, data, header)
          .then((response) => {
            if (
              response.data.status_code === 200 &&
              response.data.message !== ""
            ) {
              toast.success("Product Added To Wishlist Successfully.", {
                timeout: 2000,
              });
            }
            this.$store.dispatch("product/fetchWishlistItems");
            this.itemWishlistStatus = true;
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
