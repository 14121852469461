<template>
  <CContainer>
    <CRow>
      <Breadcrumb :key="componentKey" :data="breadcrumb" />
      <CCol md="6">
        <div class="image-swiper">
          <div class="item-main-img-holder">
            <swiper
              :breakpoints="swiperOptions"
              :autoHeight="false"
              :slides-per-view="1"
              :space-between="50"
              :autoplay="{
                delay: 4000,
                disableOnInteraction: false,
              }"
              :navigation="{
                nextEl: '.prevImageNextArrow',
                prevEl: '.prevImagePrevArrow',
              }"
            >
              <swiper-slide v-for="(image, index) in itemImage" :key="index">
                <div class="item-preview-img">
                  <img
                    :src="image.image_path"
                    :alt="itemDetail?.alternate_text"
                  />
                </div>
              </swiper-slide>
            </swiper>
          </div>

          <div class="text-center mt-4">
            <swiper-nav
              leftClassName="prevImagePrevArrow"
              RightClassName="prevImageNextArrow"
            />
          </div>
        </div>
      </CCol>
      <CCol md="6">
        <div class="item-detail-content">
          <div>
            <h1>{{ itemDetail?.title }} – {{ itemDetail?.sub_title }}</h1>
          </div>
          <div class="indicators">
            <CRow>
              <CCol md="4">
                <div class="category">
                  <div class="indi-text">Category</div>
                  <p>{{ itemDetail?.category_name }}</p>
                </div>
              </CCol>
              <CCol md="4" v-show="itemDetail?.brand_name !== ''">
                <div class="brand">
                  <div class="indi-text">Brand</div>
                  <p>
                    <img :src="itemDetail?.brand_image" alt="" />
                    {{ itemDetail?.brand_name }}
                  </p>
                </div>
              </CCol>
              <CCol md="4">
                <div class="item-ratings">
                  <star-rating
                    :rating="ratingPoint"
                    :increment="0.5"
                    @update:rating="setRating"
                    :show-rating="false"
                    :read-only="true"
                    :star-points="[
                      23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                      34, 46, 19, 31, 17,
                    ]"
                    :star-size="15"
                  ></star-rating>

                  <div>
                    <p>Reviews ({{ reviewDetail.length }})</p>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
          <div class="py-4 mb-4 border-top border-bottom">
            <div>
              <div class="mb-2">
                <span
                  class="item-price-old me-3"
                  v-show="
                    itemDetail?.old_product_price !=
                    itemDetail?.final_product_price
                  "
                >
                  ₨ {{ itemDetail?.old_product_price }}
                </span>
                <span class="item-price-new">
                  ₨ {{ itemDetail?.final_product_price }}
                </span>
              </div>
              <div class="stock-alerts">
                <span class="text-success"
                  >{{ itemStockQty }} left in stock</span
                >
                - Order Soon.
              </div>
            </div>

            <div class="attributes-meta mt-4">
              <CRow>
                <CCol md="6" v-show="item.sizes.length > 0">
                  <div>
                    <h6>Available Sizes</h6>
                    <div>
                      <span
                        v-for="size in item.sizes"
                        :class="
                          selectedAttributes.size === size.id
                            ? 'size-palatte size-palatte-selected  me-3 mb-2'
                            : 'size-palatte me-3 mb-2'
                        "
                        :key="size.id"
                        @click="() => sizeHandler(size)"
                        >{{ size.name }}
                      </span>
                    </div>
                  </div>
                </CCol>
                <CCol md="6" v-show="item.colors.length > 0">
                  <div>
                    <h6>Available Colors</h6>
                    <div>
                      <span
                        v-for="color in item.colors"
                        :key="color.id"
                        @click="() => colorHandler(color)"
                        :class="
                          selectedAttributes.color === color.id
                            ? 'color-palatte-holder color-palatte-selected me-3'
                            : 'color-palatte-holder me-3'
                        "
                      >
                        <span
                          class="color-palatte"
                          :style="{
                            background: `linear-gradient(to right, ${color.color[0]} , ${color.color[1]})`,
                          }"
                        ></span>
                      </span>
                    </div>
                  </div>
                </CCol>
              </CRow>

              <div class="item-qty mt-4" v-show="itemStockQty > 0">
                <div class="mb-3">
                  <h6>Quantity</h6>
                </div>
                <div class="d-flex">
                  <button
                    class="qty-ctrlor"
                    :disabled="btnDisable"
                    @click="decreaseItemQty"
                  >
                    <minus-icon />
                  </button>
                  <span class="qty-display mx-3" ref="qty">{{ qty }}</span>
                  <button
                    class="qty-ctrlor"
                    :disabled="btnDisable"
                    @click="increaseItemQty"
                  >
                    <plus-icon />
                  </button>
                </div>
              </div>

              <div class="item-purchase-actions mt-4">
                <CButton
                  v-show="itemStockQty > 0"
                  :disabled="addingToCart || btnDisable"
                  class="item-cart me-3"
                  @click="addToCartAPI"
                >
                  <CSpinner
                    v-if="addingToCart"
                    component="span"
                    size="sm"
                    aria-hidden="true"
                  />

                  {{ addingToCart ? "Adding..." : "Add To Cart" }}
                </CButton>

                <button
                  class="item-buynow me-3"
                  v-show="itemStockQty > 0"
                  @click="buyNow()"
                  :disabled="addingToCart || btnDisable || buying"
                >
                  <CSpinner
                    v-if="buying"
                    component="span"
                    size="sm"
                    aria-hidden="true"
                  />
                  {{ buying ? "wait..." : "Buy Now" }}
                </button>

                <button
                  class="item-wishlist"
                  @click.prevent="saveProductToWishlistAPI(itemDetail)"
                  title=""
                >
                  <heart-icon :color="itemWishlistStatus ? 'red' : 'black'" />
                </button>
              </div>
            </div>
          </div>

          <div class="about-item">
            <h6>About Item</h6>
            <div class="rich-text" v-html="itemDetail.details"></div>
          </div>

          <div class="item-spec mt-5" v-show="itemSpecificationSectionShow">
            <h6>Item Specifications</h6>
            <table class="table">
              <tbody>
                <tr v-show="itemDetail?.brand_name !== ''">
                  <td>Brand</td>
                  <td>{{ itemDetail?.brand_name }}</td>
                </tr>
                <tr v-show="itemDetail?.product_graphic !== ''">
                  <td>Graphics</td>
                  <td>{{ itemDetail?.product_graphic }}</td>
                </tr>
                <tr v-show="itemDetail?.product_model !== ''">
                  <td>Product Model</td>
                  <td>{{ itemDetail?.product_model }}</td>
                </tr>
                <tr v-show="itemDetail?.manufacture !== ''">
                  <td>Manufacturer</td>
                  <td>{{ itemDetail?.manufacture }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="give-review my-5" v-show="reviewSubmitView">
            <h6>Your Review</h6>

            <div class="mb-3">
              <div class="d-flex flex-wrap">
                <span class="me-2"> Rate this item: </span>
                <star-rating
                  :rating="submittedRating"
                  :increment="0.5"
                  @update:rating="setRating"
                  :show-rating="false"
                  :read-only="false"
                  :star-points="[
                    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                    46, 19, 31, 17,
                  ]"
                  :star-size="15"
                ></star-rating>
              </div>
            </div>
            <FormKit
              type="form"
              id="review"
              @submit="submitHandler"
              :actions="false"
            >
              <CRow>
                <CCol md="12" class="mb-4">
                  <FormKit
                    type="textarea"
                    name="review"
                    placeholder="Review"
                    validation="nullable"
                    validation-visibility="submit"
                  />
                </CCol>
              </CRow>
              <div class="">
                <button
                  class="btn btn-primary text-white py-2 px-3"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </FormKit>
          </div>

          <div class="item-reviews my-3" v-if="reviewDetail.length > 0">
            <h6>Reviews</h6>
            <div
              class="bg-light rounded p-3 mb-4"
              v-for="(datum, index) in reviewDetail"
            >
              <div class="d-flex">
                <div class="author-img me-3">
                  <img :src="datum.reviewer_profile" alt="" />
                </div>
                <div class="comment-meta">
                  <h6>{{ datum.reviewer_name }}</h6>
                  <p>{{ datum.review_date }}</p>
                  <p class="m-0">
                    {{ datum.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
    <section>
      <horizontal-ad-block />
    </section>
    <CToaster placement="bottom-end">
      <CToast :delay="time" v-for="(toast, index) in toasts" :key="index">
        <CAlert class="m-0" :color="toast.color"> {{ toast.content }} </CAlert>
      </CToast>
    </CToaster>
  </CContainer>
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import StarRating from "vue-star-rating";
import { Swiper, SwiperSlide } from "swiper/vue";
import ShowroomCardTwo from "@/components/shared-components/showroom-card/ShowroomCardTwo.vue";
import MinusIcon from "@/assets/svg/MinusIcon.vue";
import PlusIcon from "@/assets/svg/PlusIcon.vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import HeartIcon from "@/assets/svg/HeartIcon.vue";
import HorizontalAdBlock from "@/containers/e-commerce/horizontal-ads-block/HorizontalAdBlock.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import router from "@/router";

import { useToast } from "vue-toastification";
import product from "@/store/modules/product";
const toast = useToast();
export default {
  components: {
    StarRating,
    Swiper,
    SwiperSlide,
    ShowroomCardTwo,
    MinusIcon,
    PlusIcon,
    SwiperNav,
    HeartIcon,
    HorizontalAdBlock,
    Breadcrumb,
  },
  name: "ItemDetail",

  data() {
    return {
      componentKey: ref(0),
      time: 5000,
      toasts: [],
      toastMessage: "Toast Message",
      toastColor: "success",

      itemDetail: ref(""),
      itemImage: ref([]),
      activeImage: "",
      item: {
        colors: ref([]),
        sizes: ref([]),
      },
      selectedAttributes: {
        size: "",
        color: "",
        color_name: "",
        size_name: "",
      },
      qty: 1,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 2.5,
          spaceBetween: 30,
        },
      },
      submittedRating: 0,
      itemSpecificationSectionShow: true,
      itemStockQty: 0,
      ratingPoint: 0,
      reviewDetail: ref([]),
      addingToCart: false,
      reviewSubmitView: false,
      btnDisable: false,
      buying: false,
      itemWishlistStatus: false,
    };
  },

  mounted() {
    this.getItemDetailForCustomer(this.$route.params.id);
  },

  created() {
    this.breadcrumb = [
      { label: "Home", url: "/" },
      { label: "Shop", url: "/shop" },
    ];
  },

  updated() {
    //add alt to images
    window.imageALT();
  },

  methods: {
    setRating(rating) {
      this.submittedRating = rating;
    },

    submitHandler: function (values) {
      if (this.submittedRating > 0) {
        let formData = {
          review_star: this.submittedRating,
          review: values.review,
          product_slug: this.$route.params.id,
        };
        const path = UrlConfig.urlHelper.hostUrl + "/save-review";
        const header = UrlConfig.authorizedUrlHeader;
        axios
          .post(path, formData, header)
          .then((response) => {
            if (
              response.data.status_code === 200 &&
              response.data.message !== ""
            ) {
              this.$formkit.reset("review");
              this.submittedRating = 0;
              toast.success(response.data.message, {
                timeout: 2000,
              });
            }
          })
          .catch((error) => {
            toast.success(error.response.data.message, {
              timeout: 2000,
            });
          });
      } else {
        toast.success("product rating is required", {
          timeout: 2000,
        });
      }
    },

    decreaseItemQty: function () {
      this.btnDisable = true;
      if (this.qty <= 1) {
        this.btnDisable = false;
        return;
      }
      this.qty--;
      this.btnDisable = false;
    },

    increaseItemQty: function () {
      this.btnDisable = true;
      if (this.qty >= this.itemStockQty) {
        this.btnDisable = false;
        return;
      }
      this.qty++;
      this.btnDisable = false;
    },

    sizeHandler: function (size) {
      this.selectedAttributes = { ...this.selectedAttributes, size: size.id };
      this.selectedAttributes = {
        ...this.selectedAttributes,
        size_name: size.name,
      };
    },

    colorHandler: function (color) {
      this.activeImage = color.image;
      this.selectedAttributes = { ...this.selectedAttributes, color: color.id };
      this.selectedAttributes = {
        ...this.selectedAttributes,
        color_name: color.color_name,
      };
      this.itemStockQty = color.qty;
      this.qty = 1;
    },

    checkItemSpecification(item) {
      if (
        item.brand_name == "" &&
        item.product_graphic == "" &&
        item.product_model == "" &&
        item.manufacture == ""
      ) {
        this.itemSpecificationSectionShow = false;
      }
    },

    addToCartAPI() {
      this.btnDisable = true;
      if (localStorage.getItem("token") == null) {
        router.push("/login");
      }
      const payload = {
        cartItemDetail: [
          {
            product_id: this.itemDetail.id,
            quantity: this.qty,
            product_color_id: this.selectedAttributes.color,
            product_size_id: this.selectedAttributes.size,
            // product_color: this.selectedAttributes.color_name,
            product_size: this.selectedAttributes.size_name,
            note: "",
            product_custom_attributes: "",
            product_custom_attribute_value: "",
          },
        ],
      };

      this.addingToCart = true;

      this.$store.dispatch("product/addToCart", payload).then((res) => {
        this.addingToCart = false;
        toast.success("Item Added To Cart Successfully", {
          timeout: 2000,
        });
        this.btnDisable = false;
      });
    },

    buyNow() {
      if (localStorage.getItem("token") == null) {
        router.push("/login");
      }
      this.buying = true;
      this.btnDisable = true;
      const payload = {
        cartItemDetail: [
          {
            product_id: this.itemDetail.id,
            quantity: this.qty,
            product_color_id: this.selectedAttributes.color,
            product_size_id: this.selectedAttributes.size,
            product_size: this.selectedAttributes.size_name,
            note: "",
            product_custom_attributes: "",
            product_custom_attribute_value: "",
          },
        ],
      };
      this.$store.dispatch("product/addToCart", payload).then((res) => {
        this.buying = false;
        this.btnDisable = false;
        if (res.status == 200) {
          router.push("/cart");
        } else {
          toast.error(res.response.data.message, {
            timeout: 9000,
          });
        }
      });
    },

    async getItemDetailForCustomer(itemSlug) {
      this.btnDisable = true;

      const url =
        UrlConfig.urlHelper.hostUrl + "/get-product-detail/" + itemSlug;
      const header = UrlConfig.authorizedHeaderWithoutImage;
      await axios
        .get(url, header)
        .then((response) => {
          let responseDetail = response.data.data;
          let itemDetail = response.data.data.product;
          let reviewDetail = response.data.data.review;
          if (response.data.status_code === 200 && itemDetail !== "") {
            this.breadcrumb.push({ label: itemDetail.title, url: "*" });
            this.componentKey += 1;

            document
              .querySelector('meta[name="title"]')
              .setAttribute("content", itemDetail.title);
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", itemDetail.short_details);
            document
              .querySelector('meta[name="keywords"]')
              .setAttribute("content", itemDetail.sub_title);
            if (itemDetail.meta_title != null) {
              document
                .querySelector('meta[name="title"]')
                .setAttribute("content", itemDetail.meta_title);
            }
            if (itemDetail.meta_description != null) {
              document
                .querySelector('meta[name="description"]')
                .setAttribute("content", itemDetail.meta_description);
            }
            if (itemDetail.meta_keys != null) {
              document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content", itemDetail.meta_keys);
            }

            let itemImageWithColor = itemDetail.color_product_images;
            let itemSize = itemDetail.size;
            let productImages = itemDetail.product_images;
            this.itemDetail = itemDetail;
            this.ratingPoint = responseDetail.review_star;
            this.checkItemSpecification(itemDetail);
            this.itemWishlistStatus = itemDetail.wishlist_status;
            this.reviewSubmitView = this.checkLogin();

            if (itemImageWithColor.length > 0) {
              this.selectedAttributes.color = itemImageWithColor[0].id;
              this.selectedAttributes.color_name = itemImageWithColor[0].color;
              this.itemStockQty = itemImageWithColor[0].quantity;
              Object.entries(itemImageWithColor).forEach(([key, value]) => {
                this.item.colors.push({
                  id: value.id,
                  color_name: value.color,
                  image: value.image,
                  qty: value.quantity,
                  color: value.color,
                });
              });
            }

            if (productImages.length > 0) {
              Object.entries(productImages).forEach(([key, value]) => {
                this.itemImage.push({
                  id: key,
                  image_path: value.image,
                });
              });
            }

            if (itemSize.length > 0) {
              this.selectedAttributes.size = itemSize[0].product_size_id;
              this.selectedAttributes.size_name = itemSize[0].size_name;
              Object.entries(itemSize).forEach(([key, value]) => {
                this.item.sizes.push({
                  id: value.product_size_id,
                  name: value.size_name,
                });
              });
            }

            if (reviewDetail.length > 0) {
              this.reviewDetail = reviewDetail;
            }
            this.btnDisable = false;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    saveProductToWishlistAPI(productDetail) {
      if (localStorage.getItem("token") == null) {
        router.push("/login");
      }
      if (
        productDetail.wishlist_status === true ||
        this.itemWishlistStatus === true
      ) {
        toast.error("Product already in wishlist !", {
          timeout: 2000,
        });
      } else {
        let data = {
          product_id: productDetail.id,
        };
        this.$store
          .dispatch("product/addToWishlist", data)
          .then((data) => {
            toast.success("Product Added To Wishlist Successfully", {
              timeout: 2000,
            });
            this.itemWishlistStatus = true;
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
      }
    },

    checkLogin() {
      let token = localStorage.getItem("token");
      if (token !== null) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
