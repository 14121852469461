<template>
  <CContainer>
    <CRow class="align-items-center">
      <CCol :md="{ span: 4, offset: 2 }">
        <div class="body-banner-heading">
          <span class="tagline py-1 px-2 mb-4 d-inline-flex">{{bannerDetail.sub_title}}</span>
          <div class="slide-content">
            <h2>
             {{bannerDetail.title}}
            </h2>
            <p>{{bannerDetail.description}}</p>
          </div>
          <div class="slide-action">
            <a :href="bannerDetail.link" class="btn btn-primary px-3 py-2" target="_blank">View Detail</a>
          </div>
        </div>
      </CCol>
      <CCol md="6">
        <img class="img-fluid" :src="bannerDetail.image_path" alt="" />
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: "BodyBanner",
  props:['bannerDetail']
};
</script>

<style lang="scss" scoped></style>
