<template>
  <CContainer>
    <div class="row justify-content-md-center">
      <CCol md="7" lg="5">
        <div class="mb-4">
          <auth-title title="Verify Email"/>
        </div>
      </CCol>
    </div>

    <section class="change-pass-form">
      <FormKit
          type="form"
          :value="values"
          @submit="submitHandler"
          :actions="false"
      >
        <CCard class="p-4">
          <heading title="Verify Email"/>
          <div class="info-inputs mt-4">
            <CRow>
              <CCol md="6" class="mb-4">
                <FormKit
                    type="text"
                    name="email"
                    v-model="values.email"
                    label="Email"
                    placeholder="Enter Email"
                    validation="required"
                    validation-visibility="submit"
                />
              </CCol>
            </CRow>
          </div>

          <div class="mt-4">
            <button class="btn btn-primary text-white py-2 px-3" type="submit">
              <CSpinner
                  v-if="verifying"
                  component="span"
                  size="sm"
                  aria-hidden="true"
              />
              {{ verifying ? "verifying..." : "verify" }}
            </button>
          </div>
        </CCard>


      </FormKit>
    </section>
  </CContainer>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {reactive} from "vue";
import router from "@/router";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  name: "VerifyEmail",
  components: {Heading},

  data() {
    return {
      values: reactive({
        email: "",
      }),
      verifying: false
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    submitHandler: function (values) {
      this.verifying = true;
      const path = UrlConfig.urlHelper.hostUrl + "/get-email-verification";
      const header = UrlConfig.authorizedUrlHeader;
      let formData = {
        email: values.email,
      };
      axios
          .post(path, formData, header)
          .then((response) => {
            this.verifying = false;
            if (
                response.data.status_code === 200 &&
                response.data.message !== ""
            ) {
              toast.success(response.data.message, {
                timeout: 4000,
              });
              router.push("/dashboard/profile");
            }
          })
          .catch((error) => {
            this.verifying = false;
            let errorResponse = error.response.data;
            if (errorResponse.data !== "") {
              let loop = 0;
              Object.entries(errorResponse.data.error).forEach(([key, value]) => {
                loop = loop + 1;
                let errorTimeAdd = 1000 * parseInt(loop);
                toast.error(value, {
                  timeout: 2000 + parseInt(errorTimeAdd),
                });
              });
            } else {
              toast.error(errorResponse.message, {
                timeout: 2000,
              });
            }
          });
    },

  },
}
</script>

<style scoped>

</style>