<template>
  <Breadcrumb :key="componentKey" :data="breadcrumb"/>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="8">
        <div class="text-center mb-5 pt-5">
          <div>
            <h1>{{ blogDetail.blog_title }}</h1>
          </div>
        </div>

        <div class="d-flex justify-content-center mb-5">
          <avatar
            className="author-article-card"
            :image="blogDetail.image"
            :title="blogDetail.author_name"
            :subTile="blogDetail.read_time"
          />
        </div>
      </CCol>

      <CCol md="8">
        <div class="blog-detail-thumb">
          <img :src="blogDetail.blog_image" alt="" />
        </div>
      </CCol>
      <CCol md="6">
        <div class="rich-text mt-5">
          <p v-html="blogDetail.blog_description"></p>
        </div>
      </CCol>
    </CRow>
  </CContainer>

  <section class="py-5">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <section-title
            className="text-center"
            title="Related Blogs"
            subtitle=""
          />
        </CCol>
      </CRow>
    </CContainer>
  </section>

  <section class="related-blog mb-5">
    <CContainer>
      <CRow>
        <CCol md="4" class="mb-md-0 mb-4" v-for="blog in blogs" :key="blog.id">
          <article-card :article="blog" />
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import Avatar from "@/components/shared-components/avatar/Avatar.vue";
import ArticleCard from "@/components/shared-components/article-card/ArticleCard.vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: { Avatar, ArticleCard, SectionTitle, Breadcrumb},
  name: "BlogDetail",
  data() {
    return {
      id: ref(""),
      blogs: ref([]),
      blogDetail: ref(""),   
      componentKey: ref(0) 
    };
  },
  
  created(){  
    this.breadcrumb = [
                {label: 'Home', url: '/'},
                {label: 'Blogs', url: '/blogs'}
              ];  
  },

  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getBlogDetail(this.$route.params.id);
  },

  updated() {
    this.getBlogDetailOnly(this.$route.params.id);
  },

  methods: {
    getBlogDetail(blogId) {
      const url = UrlConfig.urlHelper.hostUrl + "/get-blog-detail/" + blogId;
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
        .get(url, header)
        .then((response) => {
          let blogDetail = response.data.data;
          if (response.data.status_code === 200 && blogDetail !== "") {
            (this.id = blogDetail.blog.blog_id),
              (this.blogDetail = {
                id: blogDetail.blog.blog_id,
                blog_title: blogDetail.blog.title,
                author_name: blogDetail.blog.blog_created_by,
                read_time: blogDetail.blog.blog_read_time,
                image: blogDetail.blog.blog_creator_image_path,
                blog_image: blogDetail.blog.blog_image_path,
                blog_description: blogDetail.blog.description,
                slug: blogDetail.blog.slug,
              });
              
              
              this.breadcrumb.push({label: this.blogDetail.blog_title, url: '*'});
              this.componentKey += 1;

            let relatedBlog = blogDetail.related_blog;
            if (relatedBlog.length > 0) {
              Object.entries(relatedBlog).forEach(([key, value]) => {
                this.blogs.push({
                  id: value.blog_id,
                  category: value.blog_category_name,
                  date: value.blog_publish_date,
                  title: value.title,
                  author: value.blog_created_by,
                  readTime: value.blog_read_time,
                  image: value.blog_image_path,
                  authorImage: value.blog_creator_image_path,
                  slug: value.slug,
                });
              });
            }
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            timeout: 2000,
          });
        });
    },  

    async getBlogDetailOnly(blogId) {
      const url = UrlConfig.urlHelper.hostUrl + "/get-blog-detail/" + blogId;
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let blogDetail = response.data.data;
          if (response.data.status_code === 200 && blogDetail !== "") {
            this.blogDetail = {
              id: blogDetail.blog.blog_id,
              blog_title: blogDetail.blog.title,
              author_name: blogDetail.blog.blog_created_by,
              read_time: blogDetail.blog.blog_read_time,
              image: blogDetail.blog.blog_creator_image_path,
              blog_image: blogDetail.blog.blog_image_path,
              blog_description: blogDetail.blog.description,
            };
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            timeout: 2000,
          });
        });
    },      
  },
};


</script>

<style lang="scss" scoped></style>
