<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4338 9.75058C19.4338 4.45939 15.1467 0.172363 9.85556 0.172363C4.56437 0.172363 0.277344 4.45939 0.277344 9.75058C0.277344 14.5312 3.77996 18.4938 8.35896 19.2129V12.5194H5.92579V9.75058H8.35896V7.64028C8.35896 5.23993 9.78797 3.91405 11.9767 3.91405C13.0249 3.91405 14.121 4.10098 14.121 4.10098V6.45691H12.9129C11.7233 6.45691 11.3522 7.19536 11.3522 7.95273V9.75058H14.0086L13.5837 12.5194H11.3522V19.2129C15.9312 18.4938 19.4338 14.5312 19.4338 9.75058Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "FacebookIcon",
};
</script>

<style lang="scss" scoped></style>
