<template>
  <page-banner-two>
    <div class="col-md-7">
      <banner-title
        :tagline="pageBanner.page_sub_title"
        :title="pageBanner.page_title"
        :description="pageBanner.page_title_description"
      />
    </div>
  </page-banner-two>
  <Breadcrumb :data="breadcrumb"/>
  <contact-area
    :showroom="showRoomDetail"
    :companyDetail="companyDetail"
    :contactUsSectionDetail="contactUsSectionDetail"
  />
  <newsletter-sub />
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import PageBannerTwo from "@/components/shared-components/page-banner/PageBannerTwo.vue";
import ContactArea from "@/containers/business/contact-form/ContactArea.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  components: {
    Breadcrumb,
    BannerTitle,
    PageBannerTwo,
    ContactArea,
    NewsletterSub,
  },
  name: "ContactUs",

  data() {
    return {
      pageBanner: ref(""),
      companyDetail: ref(""),
      showRoomDetail: ref([]),
      contactUsSectionDetail: ref(""),
    };
  },

  beforeCreate(){
    this.breadcrumb = [
      {label: 'Home', url: '/'},
      {label: 'Contact Us', url: '*'}
    ];
  },
  updated(){
    //add alt to images
    window.imageALT();
  },


  mounted() {
    window.scrollTo(0, 0);
    this.getContactUsPageBannerDetail();
    this.getContactUsPageDetail();
  },

  methods: {
    async getContactUsPageBannerDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-page-banner/contact_us";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let bannerData = response.data.data;
          if (response.data.status_code === 200 && bannerData !== "") {
            this.pageBanner = bannerData;
          }
          window.recreateBreadcrumb([
            {label: 'Home', url: '/'},
            {label: 'About', url: '/about'},
            {label: 'Contact', url: '*'}
          ]);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    async getContactUsPageDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-contact-us-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;

      await axios
        .get(url, header)
        .then((response) => {
          let contactUsPageDetail = response.data.data;
          if (response.data.status_code === 200 && contactUsPageDetail !== "") {
            if (contactUsPageDetail.company_detail !== "") {
              this.companyDetail = contactUsPageDetail.company_detail;
            }
            if (contactUsPageDetail.contactUsDetail !== "") {
              this.contactUsSectionDetail = contactUsPageDetail.contactUsDetail;
            }
            if (contactUsPageDetail.showroom.length > 0) {
              Object.entries(contactUsPageDetail.showroom).forEach(
                ([key, value]) => {
                  this.showRoomDetail.push(value);
                }
              );
            }
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
