<template>
  <svg
    width="6"
    height="9"
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.744734 8.01374C0.419318 7.6883 0.419318 7.16066 0.744734 6.83522L3.07215 4.50781L0.744734 2.1804C0.419318 1.85498 0.419318 1.32731 0.744734 1.0019C1.07015 0.676479 1.59782 0.676479 1.92323 1.0019L4.83991 3.91856C5.16534 4.24398 5.16534 4.77165 4.83991 5.09706L1.92323 8.01374C1.59782 8.33917 1.07015 8.33917 0.744734 8.01374Z"
      fill="#1B1C20"
    />
  </svg>
</template>

<script>
export default {
  name: "NextIcon",
};
</script>

<style lang="scss" scoped></style>
