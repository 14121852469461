<template>
  <section class="bg-gray py-5 mb-5">
    <CContainer>
      <CRow>
        <CCol md="12" lg="8">
          <CCard class="p-4">
            <heading
              title="Cart"
              :description="'There are ' + totalCartItem + ' products in  cart'"
            />

            <div class="cart-list-items">
              <div
                style="position: relative"
                class="cart-pg-item border-bottom py-3"
                v-for="(item, key) in cartItems"
              >
                <div
                  class="block-confirm bg-danger px-4"
                  v-if="selectedCartRow === key"
                >
                  <div class="text-white" v-if="deleting">Deleting...</div>
                  <div class="confirm-content text-white" v-else>
                    <div class="confirm-message">
                      Are you sure to delete this item from cart.
                    </div>
                    <div class="confirm-action">
                      <CButton
                        color="light"
                        variant="outline"
                        @click.prevent="deleteCartItem(item, key)"
                        >Delete</CButton
                      >
                      <CButton
                        class="ms-3"
                        color="light"
                        variant="outline"
                        @click="hanldeConfirmVisibility(null)"
                        >Cancel</CButton
                      >
                    </div>
                  </div>
                </div>

                <div class="item-left pe-4">
                  <CRow>
                    <CCol md="6" lg="6">
                      <div class="item-info d-flex">
                        <div class="item-image me-3">
                          <img :src="item.image" alt="" />
                        </div>
                        <div class="item-name">
                          {{ item.name }}

                          <div class="mt-2 mb-xl-0 mb-lg-3">
                            <div class="d-flex">
                              <div class="me-3">
                                {{ item.qty }}x{{ item.amount }}
                              </div>

                              <div>
                                <div class="item-attr">
                                  <div
                                    class="color-holder"
                                    v-if="item.product_color"
                                  >
                                    <div
                                      class="color"
                                      :style="`background: ${item.product_color}`"
                                    ></div>
                                  </div>
                                  <div
                                    class="size-holder ms-2"
                                    v-if="item.product_size"
                                  >
                                    <div class="size">
                                      {{ item.product_size }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CCol>
                    <CCol md="6" lg="12" xl="6">
                      <div class="item-meta">
                        <div class="d-flex align-items-center">
                          <div class="qty-controller">
                            <button
                              class="btn rounded"
                              @click.prevent="decrementCartItemQty(item)"
                              :disabled="btnMinusDisable"
                            >
                              <minus-icon />
                            </button>
                            <span class="item-qty">{{ item.qty }}</span>
                            <button
                              class="btn rounded"
                              @click.prevent="incrementCartItemQty(item)"
                              :disabled="btnDisable"
                            >
                              <plus-icon />
                            </button>
                          </div>
                          <div class="item-amount">
                            {{ item.qty * item.amount }}
                          </div>
                        </div>

                        <div class="item-action">
                          <button
                            :disabled="deleting"
                            @click="hanldeConfirmVisibility(key)"
                          >
                            <trash-icon />
                          </button>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </div>
          </CCard>
          <div class="my-4">
            <span class="w-sm-100 mb-3">
              <button
                class="btn btn-primary"
                :disabled="checkoutButton"
                @click.prevent="checkout"
              >
                Checkout
              </button>
            </span>
            <span class="mx-4 d-none d-sm-none d-md-inline-flex">|</span
            >Continue

            <span>
              <router-link to="/">Shopping</router-link> or Clear Cart
            </span>
          </div>
        </CCol>

        <CCol md="12" lg="4" v-show="checkUserType">
          <CCard class="p-4">
            <div class="cart-checkout">
              <div class="mb-4">
                <heading title="Order Summary" />
              </div>

              <div class="methods-delivery border-bottom pb-4 mb-4">
                <h5>Select Shipping Method</h5>

                <div>
                  <label>
                    <input
                      type="radio"
                      name="delivery_option"
                      v-model="selected"
                      @change="onChange($event)"
                      value="delivery"
                    />
                    Delivery
                  </label>
                </div>

                <div>
                  <label>
                    <input
                      type="radio"
                      name="delivery_option"
                      v-model="selected"
                      @change="onChange($event)"
                      value="pickUp"
                      required
                    />
                    Pick up
                  </label>
                </div>
              </div>

              <div
                class="methods-delivery border-bottom pb-4 mb-4"
                v-show="deliveryDetails.length > 0"
              >
                <h5>Select Shipping Location</h5>
                <select
                  class="form-control"
                  @change="shippingPrice"
                  v-model="shipping"
                >
                  <option
                    v-for="chargeDetail in deliveryDetails"
                    :key="chargeDetail.id"
                    :value="chargeDetail"
                  >
                    {{ chargeDetail.title }} - Rs.
                    {{ chargeDetail.delivery_charge_amount }}
                  </option>
                </select>
              </div>

              <div class="discount-code">
                <h5>Apply Discount Code</h5>

                <div class="code-apply">
                  <input
                    type="text"
                    v-model.lazy="discountCode"
                    placeholder="Enter discount code here..."
                  />
                </div>
                <button
                  class="mt-3 btn-apply-coupon"
                  @click.prevent="applyDiscount"
                >
                  APPLY DISCOUNT
                </button>
              </div>

              <div class="my-3">
                <div class="d-flex mb-2 justify-content-between">
                  <span class="fw-bold"> Sub Total </span>
                  <span class="fw-bold"> NRs. {{ totalCartItemPrice }} </span>
                </div>
                <div
                  class="d-flex justify-content-between"
                  v-show="shippingCharge > 0"
                >
                  <span class="fw-bold"> Shipping </span>
                  <span class="fw-bold"> NRs. {{ shippingCharge }} </span>
                </div>

                <div
                  class="d-flex justify-content-between mt-2"
                  v-show="discountAmount > 0"
                >
                  <span class="fw-bold"> Discount </span>
                  <span class="fw-bold"> NRs. {{ discountAmount }} </span>
                </div>
              </div>

              <div class="cart-total border-top py-3">
                <div class="d-flex mb-2 justify-content-between">
                  <span class="fw-bold"> Total Amount </span>
                  <span class="fw-bold">
                    NRs. {{ totalPriceWithShipping }}
                  </span>
                </div>
              </div>
              <div>
                <button
                  class="btn btn-primary w-100"
                  :disabled="checkoutButton"
                  @click.prevent="checkout"
                >
                  PROCEED TO CHECKOUT
                </button>
              </div>
            </div>
          </CCard>
        </CCol>

        <CCol md="12" lg="4" v-show="!checkUserType">
          <CCard class="p-4">
            <div class="cart-checkout">
              <div class="mb-4">
                <heading title="Order Summary" />
              </div>

              <div class="my-3">
                <div class="d-flex mb-2 justify-content-between">
                  <span class="fw-bold"> Total Amount </span>
                  <span class="fw-bold"> NRs. {{ totalCartItemPrice }} </span>
                </div>
                <div class="d-flex justify-content-between">
                  <span class="fw-bold"> Delivery Type </span>
                  <span class="fw-bold"> PickUp </span>
                </div>
              </div>

              <div class="cart-total border-top py-3">
                <div class="d-flex mb-2 justify-content-between">
                  <span class="fw-bold"> Total Amount </span>
                  <span class="fw-bold">
                    NRs. {{ totalPriceWithShipping }}
                  </span>
                </div>
              </div>
              <div>
                <button
                  class="btn btn-primary w-100"
                  :disabled="checkoutButton"
                  @click.prevent="checkout"
                >
                  PROCEED TO CHECKOUT
                </button>
              </div>
            </div>
          </CCard>
        </CCol>
      </CRow>
      <CToaster placement="top-end">
        <CToast :delay="time" v-for="(toast, index) in toasts" :key="index">
          <CAlert class="m-0" :color="toast.color">
            {{ toast.content }}
          </CAlert>
        </CToast>
      </CToaster>
    </CContainer>
  </section>

  <section class="pb-5">
    <recommended-item-carousel
      sectionTitle="Recommended Items"
      subTitle=" own it now, up to 6 months interest free!"
      prevEl="recomPrevArrow"
      nextEl="recomNextArrow"
    />
  </section>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import TrashIcon from "@/assets/svg/TrashIcon.vue";
import RecommendedItemCarousel from "@/containers/e-commerce/item-carousel/RecommendedItemCarousel";
import MinusIcon from "@/assets/svg/MinusIcon.vue";
import PlusIcon from "@/assets/svg/PlusIcon.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import router from "@/router";
import LaravelVuePagination from "laravel-vue-pagination";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "Cart",
  components: {
    Heading,
    TrashIcon,
    MinusIcon,
    PlusIcon,
    RecommendedItemCarousel,
  },

  data() {
    return {
      cartId: ref(""),
      totalCartItem: 0,
      cartNumber: ref(""),
      totalPrice: ref(""),
      cartItems: ref([]),
      shipping: "",
      shippingCharge: 0,
      discountAmount: 0,
      deliveryDetails: ref([]),
      discountCode: "",
      selected: "",
      deliveryType: ref(""),
      appliedCoupon: ref(""),
      checkoutButton: false,
      cartTotal: 0,

      time: 3000,
      toasts: [],
      toastMessage: "Toast Message",
      toastColor: "success",
      btnDisable: false,
      btnMinusDisable: false,
      selectedCartRow: null,
      deleting: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getCartDetail();
  },

  methods: {
    incrementCartItemQty(product) {
      this.btnDisable = true;
      if (product.stockQty > product.qty) {
        product.qty++;
        this.updateCartItemQty(product).then((res) => {
          this.btnDisable = false;
          this.btnMinusDisable = false;
          this.applyDiscount();
        });
      }
    },

    decrementCartItemQty(product) {
      this.btnMinusDisable = true;
      if(product.qty > 1){
        if (this.checkUserType) {
          product.qty > 1 ? product.qty-- : "";
          this.updateCartItemQty(product).then((res) => {
            this.btnMinusDisable = false;
            this.btnDisable = false;
            this.applyDiscount();
          });
        } else {
          product.qty > product.min_order_qty ? product.qty-- : "";
          this.updateCartItemQty(product).then((res) => {
            this.btnMinusDisable = false;
            this.btnDisable = false;
          });
        }
      }
    },

    getCartDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-cart-details";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {};
      axios
        .post(url, data, header)
        .then((response) => {
          let cartDetail = response.data.data;

          if (response.data.status_code === 200 && cartDetail !== "") {
            this.cartId = cartDetail.cart_id;
            this.totalCartItem = cartDetail.total_cart_item ?? 0;
            this.cartNumber = cartDetail.cart_number;
            this.totalPrice = cartDetail.total_price;
            let cartItemsDetail = cartDetail.cartItems;
            if (cartItemsDetail.length > 0) {
              Object.entries(cartItemsDetail).forEach(([key, value]) => {
                this.cartItems.push({
                  id: value.product_id,
                  productCartId: value.id,
                  name: value.name,
                  qty: value.qty,
                  amount: value.amount,
                  date: value.date,
                  image: value.image,
                  stockQty: value.product_quantity,
                  productSizeId: value.product_size_id,
                  productSize: value.product_size,
                  productColor: value.product_color,
                  productColorId: value.product_color_id,
                  productCustomAttributeValue: value.product_custom_attribute_value,
                  productCustomAttribute: value.product_custom_attributes,
                  min_order_qty: value.minimum_order_quantity,
                  product_color: value.product_color,
                  product_size: value.product_size,
                });
              });
            }
          }
        })
        .catch((error) => {
          let errorResponse = error.response.data;
          if (errorResponse.data !== "") {
            let loop = 0;
            Object.entries(errorResponse.data.error).forEach(([key, value]) => {
              loop = loop + 1;
              let errorTimeAdd = 1000 * parseInt(loop);
              toast.error(value, {
                timeout: 2000 + parseInt(errorTimeAdd),
              });
            });
          } else {
            toast.error(errorResponse.message, {
              timeout: 2000,
            });
          }
        });
    },

    async deleteCartItem(product, key) {
      this.deleting = true;
      const url = UrlConfig.urlHelper.hostUrl + "/delete-cart-item";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {
        cart_number: this.cartNumber,
        cart_product_id: product.productCartId,
        product_id: product.id,
      };
      await axios
        .post(url, data, header)
        .then((response) => {
          if (response.data.status_code === 200) {
            this.cartItems.splice(key, 1);
            if (this.cartItems.length > 0) {
              this.applyDiscount();
            }
            if (this.cartItems.length <= 0) {
              this.totalCartItem = 0;
              this.discountAmount = 0;
              this.shippingCharge = 0;
              this.shipping = "";
              this.discountCode = "";
              this.selected = false;
            }
          }
          this.selectedCartRow = null;
          this.deleting = false;
          this.$store.dispatch("product/fetchCartItems");
        })
        .catch((error) => {
          let errorResponse = error.response.data;
          if (errorResponse.data !== "") {
            let loop = 0;
            Object.entries(errorResponse.data.error).forEach(([key, value]) => {
              loop = loop + 1;
              let errorTimeAdd = 1000 * parseInt(loop);
              toast.error(value, {
                timeout: 2000 + parseInt(errorTimeAdd),
              });
            });
          } else {
            toast.error(errorResponse.message, {
              timeout: 2000,
            });
          }
        });
    },

    async updateCartItemQty(product) {
      const url = UrlConfig.urlHelper.hostUrl + "/update-cart-item";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {
        cart_product_id: product.productCartId,
        quantity: product.qty,
        product_color_id: "",
        product_size_id: "",
        product_custom_attributes: "",
        product_custom_attribute_value: "",
        product_size: "",
        product_color: "",
      };
      return await axios
        .post(url, data, header)
        .then((response) => {
          if (response.data.status_code === 200) {
            return response;
          }
        })
        .catch((error) => {
          let errorResponse = error.response.data;
          if (errorResponse.data !== "") {
            let loop = 0;
            Object.entries(errorResponse.data.error).forEach(([key, value]) => {
              loop = loop + 1;
              let errorTimeAdd = 1000 * parseInt(loop);
              toast.error(value, {
                timeout: 2000 + parseInt(errorTimeAdd),
              });
            });
          } else {
            toast.error(errorResponse.message, {
              timeout: 2000,
            });
          }
        });
    },

    onChange(event) {
      let deliveryType = event.target.value;
      this.deliveryType = deliveryType;
      if (deliveryType === "delivery") {
        this.checkoutButton = true;
        const path = UrlConfig.urlHelper.hostUrl + "/get-delivery-charge";
        const header = UrlConfig.authorizedUrlHeader;
        axios
          .get(path, header)
          .then((response) => {
            let deliveryDetailData = response.data.data;
            if (
              response.data.status_code === 200 &&
              deliveryDetailData.length > 0
            ) {
              Object.entries(deliveryDetailData).forEach(([key, value]) => {
                this.deliveryDetails.push(value);
              });
              this.applyDiscount();
            }
          })
          .catch((error) => {
            let errorResponse = error.response.data;
            if (errorResponse.data !== "") {
              let loop = 0;
              Object.entries(errorResponse.data.error).forEach(
                ([key, value]) => {
                  loop = loop + 1;
                  let errorTimeAdd = 1000 * parseInt(loop);
                  toast.error(value, {
                    timeout: 2000 + parseInt(errorTimeAdd),
                  });
                }
              );
            } else {
              toast.error(errorResponse.message, {
                timeout: 2000,
              });
            }
          });
      } else {
        this.deliveryDetails = [];
        this.shipping = "";
        this.shippingCharge = 0;
        this.checkoutButton = false;
      }
    },

    shippingPrice(e) {
      let shippingDetail = this.shipping;
      if (shippingDetail !== "") {
        this.checkoutButton = false;
        this.shippingCharge = shippingDetail.delivery_charge_amount;
      }
    },

    applyDiscount() {
      let coupon = this.discountCode ?? "";
      if (coupon !== "") {
        this.appliedCoupon = coupon;
        const url = UrlConfig.urlHelper.hostUrl + "/check-coupon";
        const header = UrlConfig.authorizedUrlHeader;
        let data = {
          campaign_code: coupon,
        };
        axios
          .post(url, data, header)
          .then((response) => {
            let discountDetail = response.data.data;
            if (response.data.status_code === 200 && discountDetail !== "") {
              this.discountAmount = discountDetail.discount_amount;
            }
          })
          .catch((error) => {
            let errorResponse = error.response.data;
            if (errorResponse.data !== "") {
              let loop = 0;
              Object.entries(errorResponse.data.error).forEach(
                ([key, value]) => {
                  loop = loop + 1;
                  let errorTimeAdd = 1000 * parseInt(loop);
                  toast.error(value, {
                    timeout: 2000 + parseInt(errorTimeAdd),
                  });
                }
              );
            } else {
              toast.error(errorResponse.message, {
                timeout: 2000,
              });
            }
          });
      } else {
        this.discountAmount = 0;
      }
    },

    checkout() {
      if (
        this.deliveryType == "" &&
        localStorage.getItem("user_type") === "customer"
      ) {
        toast.error("Please Select The Shipping Method", {
          timeout: 2000,
        });
      } else {
        const cartDetail = {
          cartId: this.cartId,
          cartItems: this.cartItems,
          deliveryType: this.checkUserType ? this.deliveryType : "pickUp",
          shippingId: this.checkUserType ? this.shipping.id : "",
          shippingCharge: this.checkUserType ? this.shippingCharge : 0,
          cartItemPrice: this.totalPrice,
          couponApplied: this.checkUserType ? this.appliedCoupon : "",
          discount: this.checkUserType ? this.discountAmount : 0,
          netTotal: this.cartTotal,
        };
        localStorage.setItem("cartData", JSON.stringify(cartDetail));
        router.push("/checkout");
      }
    },

    hanldeConfirmVisibility(index) {
      this.selectedCartRow = index;
    },
  },

  watch: {
    shippingCharge(newValue) {
      if (newValue === false) this.shipping = "";
    },
  },

  computed: {
    totalCartItemPrice() {
      let subtotal = 0;
      this.cartItems.forEach((item, i) => {
        subtotal += item.amount * item.qty;
      });
      this.totalPrice = subtotal;
      return subtotal;
    },

    totalPriceWithShipping() {
      let total = 0;
      this.cartItems.forEach((item, i) => {
        total += item.amount * item.qty;
      });
      let netTotal =
        parseInt(total) +
        parseInt(this.shippingCharge) -
        parseInt(this.discountAmount);
      this.cartTotal = netTotal;
      return netTotal;
    },

    checkUserType() {
      if (localStorage.getItem("user_type") === "vendor") {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
