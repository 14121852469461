<template>
  <div id="breadcrumb">
    <ul class="site-breadcrumb">
      <li v-for="d in data" :key="d.label">
        <router-link v-if="d.url != '*'" :to="d.url">{{ d.label }}</router-link>
        <span v-else>{{ d.label }}</span>        
      </li>
    </ul>
  </div>
</template>

<script>
export default  {
  name: "Breadcrumb",
  props: ["data"]
};
</script>
<style lang="scss" scoped></style>
