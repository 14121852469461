<template>
  <div class="nav-item">
    <ul class="parent-nav-items">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/about">About</router-link>
      </li>
      <li>
        <router-link to="/shop">Shop</router-link>
      </li>
      <li>
        <router-link to="/blogs">Blogs</router-link>
      </li>
      <li>
        <router-link to="/contact-us">Contact Us</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";
import {useToast} from "vue-toastification";
const toast = useToast();
export default {
  name: "Navigation",
  props: ["isShop"],

  data() {
    return {
      categoryDetail: ref([]),
    };
  },

  mounted() {
    if (this.categoryDetail.length < 1) {
      this.getCategoryDetail();
    }
  },

  methods: {
    getCategoryDetail() {
      try {
        this.$store
          .dispatch("categories/fetchProductCategory")
          .then((categoryData) => {
            if (categoryData.length > 0) {
              Object.entries(categoryData).forEach(([key, value]) => {
                this.categoryDetail.push({
                  id: ++key,
                  name: value.name,
                  slug: value.slug,
                  child_category: value.child_category
                    ? value.child_category
                    : [],
                  image: value.image_path,
                });
              });
            }
          });
      } catch (e) {
        toast.error('Sorry! something went wrong', {
          timeout: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
