<template>
  <div class="user-profile-content">
    <CCard class="p-4">
      <div class="user-infos">
        <heading title="Account Information">
          <template #extra>
            <router-link to="/dashboard/profile/edit">Edit</router-link>
          </template>
        </heading>
        <div
          class="mb-5"
          v-if="
            customerProfileDetail.profile_image === '' ||
            customerProfileDetail.profile_image === undefined ||
            customerProfileDetail.profile_image === null
          "
        >
          Please upload your profile image by clicking on Edit button on the top
          right.
        </div>
        <div class="user-profile-imag mb-5" v-else>
          <img :src="customerProfileDetail.profile_image" alt="alt" />
        </div>
        <CRow>
          <CCol md="6">
            <info-tile title="User Detail">
              <template #tile-content>
                <p class="m-0">
                  {{ customerProfileDetail.first_name }}
                  {{ customerProfileDetail.last_name }}
                </p>
                <p class="m-0">{{ customerProfileDetail.email }}</p>
                <!--                  <p class="m-0">{{ customerProfileDetail.mobile }}</p>-->
              </template>
            </info-tile>
          </CCol>
          <CCol md="6">
            <info-tile title="">
              <template #tile-content>
                <p class="m-0">
                  User Type: {{ customerProfileDetail.user_type }}
                </p>

                <div class="mt-2 " v-show="showVerifyButton">
                  <span class="w-sm-100 mb-3">
                    <router-link to="/verify-email">
                      <button class="btn btn-primary">
                           Verify Email
                    </button>
                    </router-link>
                  </span>
                </div>
              </template>

            </info-tile>
          </CCol>
        </CRow>
      </div>

      <div class="addresses mt-5">
        <heading title="Delivery Address Book"> </heading>
        <CRow>
          <CCol md="6">
            <info-tile title="Address 1">
              <template #tile-content>
                <p class="m-0">
                  {{
                    customerProfileDetail.address_line1 !== ""
                      ? customerProfileDetail.address_line1
                      : "N/A"
                  }}
                </p>
              </template>
            </info-tile>
          </CCol>
          <CCol md="6">
            <info-tile title="Address 2">
              <template #tile-content>
                <p class="m-0">
                  {{
                    customerProfileDetail.address_line2 !== ""
                      ? customerProfileDetail.address_line2
                      : "N/A"
                  }}
                </p>
              </template>
            </info-tile>
          </CCol>
        </CRow>
      </div>

      <div class="addresses mt-5">
        <heading title="Contact Phone Book"> </heading>
        <CRow>
          <CCol md="6">
            <info-tile title="Primary Phone 1">
              <template #tile-content>
                <p class="m-0">
                  {{
                    customerProfileDetail.primary_contact_1 !== ""
                      ? customerProfileDetail.primary_contact_1
                      : "N/A"
                  }}
                </p>
              </template>
            </info-tile>
          </CCol>
          <CCol md="6">
            <info-tile title="Primary Phone 2">
              <template #tile-content>
                <p class="m-0">
                  {{
                    customerProfileDetail.primary_contact_2 !== ""
                      ? customerProfileDetail.primary_contact_2
                      : "N/A"
                  }}
                </p>
              </template>
            </info-tile>
          </CCol>

          <CCol md="6" class="my-3">
            <info-tile title="Secondary Phone 1">
              <template #tile-content>
                <p class="m-0">
                  {{
                    customerProfileDetail.secondary_contact_1 !== ""
                      ? customerProfileDetail.secondary_contact_1
                      : "N/A"
                  }}
                </p>
              </template>
            </info-tile>
          </CCol>
          <CCol md="6" class="my-3">
            <info-tile title="Secondary Phone 2">
              <template #tile-content>
                <p class="m-0">
                  {{
                    customerProfileDetail.secondary_contact_2 !== ""
                      ? customerProfileDetail.secondary_contact_2
                      : "N/A"
                  }}
                </p>
              </template>
            </info-tile>
          </CCol>
        </CRow>
      </div>
    </CCard>
  </div>

</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import InfoTile from "@/components/shared-components/info-tile/InfoTile.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {useToast} from "vue-toastification";
const toast = useToast();
export default {
  components: { Heading, InfoTile },
  name: "Profile",

  data() {
    return {
      customerProfileDetail: ref(""),
      showVerifyButton: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getUserProfileDetail();
  },

  updated() {
    this.getUserProfileDetail();
  },

  methods: {
    getUserProfileDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-customer-details";
      const header = UrlConfig.authorizedUrlHeader;
      let data = {};
      axios
        .post(url, data, header)
        .then((response) => {
          let profileDetail = response.data.data;
          if (response.data.status_code === 200 && profileDetail !== "") {
            this.customerProfileDetail = profileDetail;
            this.showVerifyButton = profileDetail.email_status ? profileDetail.email_status : true;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
