<template>
  <section class="py-5">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <div class="text-center">
            <section-title
              tagline="NEWSLETTER SUBSCRIPTION"
              :title=newsLetterDetail.newsletter_title
              :subtitle=newsLetterDetail.newsletter_description
            />
          </div>
        </CCol>
      </CRow>
      <CRow class="justify-content-center">
        <CCol md="6">
          <div class="nwesletter-sub-form mt-4">
            <FormKit type="form" id="newsLetterForm" @submit="newLetterSubmitHandler" :actions="false">
              <CRow>
                <CCol md="12" lg="8" class="mb-lg-0 mb-4">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    v-model.lazy="newsletterEmail"
                    ref="newsletterEmail"
                  />
                </CCol>
                <CCol md="12" lg="4">
                  <button
                    class="btn btn-primary text-white py-2 px-3 w-100"
                    type="submit"
                  >
                    <CSpinner
                        v-if="newsletterSubscribing"
                        component="span"
                        size="sm"
                        aria-hidden="true"
                    />

                    {{ newsletterSubscribing ? "Subscribing..." : "Subscribe" }}
                  </button>
                </CCol>
              </CRow>
            </FormKit>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: { SectionTitle },
  name: "NewsletterSub",

  data() {
    return {
      newsLetterDetail:ref(''),
      newsletterEmail : ref(''),
      newsletterSubscribing: false
    };
  },

  mounted() {
    this.getNewsletterSectionDetail();
  },

  methods: {
    async getNewsletterSectionDetail(){
      const url = UrlConfig.urlHelper.hostUrl +'/get-about-us-detail' ;
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios.get(url,header)
          .then(response => {
            let newsletterSectionData = response.data.data;
            if(response.data.status_code === 200 && newsletterSectionData !== ''){
              this.newsLetterDetail = newsletterSectionData;
            }
          }).catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
      });
    },

    newLetterSubmitHandler: function (values) {
      this.newsletterSubscribing = true
        let formData = {
          email: this.newsletterEmail
        };
        const path = UrlConfig.urlHelper.hostUrl +'/save-newsletter-sub';
        const header = UrlConfig.unAuthorizedUrlHeader;
        axios.post(path,formData,header)
            .then(response => {
              let data = response.data;
              if(data.status_code === 200 &&  data.message !== '' ) {
                this.$refs.newsletterEmail.value = '';
                toast.success('Newsletter subscribed successfully.', {
                  timeout: 2000,
                });
                this.newsletterSubscribing = false
              }
            })
            .catch((error) => {
              this.newsletterSubscribing = false
              let errorResponse = error.response.data;
              if(errorResponse.data !== ''){
                Object.entries(errorResponse.data.error).forEach(([key, value]) => {
                  toast.error(value, {
                    timeout: 2000,
                  });
                });
              }else{
                toast.error(errorResponse.message, {
                  timeout: 2000,
                });
              }
            });
    },

  },
};
</script>

<style lang="scss" scoped></style>
