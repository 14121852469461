<template>
  <svg
    width="33"
    height="39"
    viewBox="0 0 33 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0696 8.04527C11.0696 4.90072 13.6188 2.35156 16.7633 2.35156C19.9079 2.35156 22.457 4.90072 22.457 8.04527V9.44317H11.0696V8.04527ZM9.56964 10.9432V14.489C9.56964 14.9032 9.90542 15.239 10.3196 15.239C10.7338 15.239 11.0696 14.9032 11.0696 14.489V10.9432H22.457V14.489C22.457 14.9032 22.7928 15.239 23.207 15.239C23.6213 15.239 23.957 14.9032 23.957 14.489V10.9432H28.9562L30.9887 37.3659H2.53794L4.57045 10.9432H9.56964ZM9.56964 9.44317V8.04527C9.56964 4.07229 12.7904 0.851562 16.7633 0.851562C20.7363 0.851562 23.957 4.07229 23.957 8.04527V9.44317H29.6507C30.0427 9.44317 30.3685 9.7449 30.3985 10.1356L32.5464 38.0584C32.5625 38.2667 32.4909 38.4722 32.3489 38.6255C32.207 38.7788 32.0076 38.8659 31.7987 38.8659H1.72803C1.51912 38.8659 1.31968 38.7788 1.17774 38.6255C1.0358 38.4722 0.964216 38.2667 0.980239 38.0584L3.12814 10.1356C3.1582 9.7449 3.48403 9.44317 3.87593 9.44317H9.56964Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "BagIcon",
};
</script>

<style lang="scss" scoped></style>
