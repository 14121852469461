<template>
  <CContainer>
    <div class="row justify-content-md-center">
      <CCol md="7" lg="5">
        <CCard class="p-4">
          <div class="d-flex flex-wrap justify-content-center">
            Not a member yet?
            <span>              &nbsp;
              <router-link to="/register">Register now!</router-link>
            </span>
          </div>
          <CDropdownDivider class="my-4" />
          <FormKit type="form" @submit="submitHandler" :actions="false">
            <div class="mb-4">
              <FormKit
                type="text"
                name="email"
                label="Email Address"
                placeholder="Email Address"
                validation="required"
                validation-visibility="submit"
              />
            </div>
            <div class="mb-4">
              <FormKit
                type="password"
                name="password"
                label="Password"
                placeholder="Password"
                validation="required"
                validation-visibility="submit"
              />
            </div>
            <div
              class="d-flex flex-wrap align-items-center justify-content-between"
            >
              <router-link class="fw-bold" to="/forgot-password"
                >Forgot password?</router-link
              >
              <button
                class="btn btn-primary text-white py-2 px-3"
                type="submit"
              >
                <CSpinner
                  v-if="loggingIn"
                  component="span"
                  size="sm"
                  aria-hidden="true"
                />

                {{ loggingIn ? "wait..." : " Sign In" }}
              </button>
            </div>
          </FormKit>

          <CDropdownDivider class="my-4" />
            <div class="mb-2">
              Or sign in with
            </div>
            <div class="social-buttons">
              <GoogleLogin :callback="callback"/>
              <CButton @click.prevent="logInWithFacebook" class="sigin-facebook ms-3" size="lg">
                <facebook-icon />
                <span class="ms-2"> Facebook </span>
              </CButton>
            </div>
          </CCard>
      </CCol>
    </div>
  </CContainer>
</template>

<script>
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CDropdownDivider,
  CButton,
} from "@coreui/vue";
import GoogleIcon from "@/assets/svg/GoogleIcon.vue";
import FacebookIcon from "@/assets/svg/FacebookIcon.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import router from "@/router";
import { useToast } from "vue-toastification";
import urlConfig from "@/config/UrlConfig";

const toast = useToast();

  export default {
    components: {
      CContainer,
      CRow,
      CCol,
      CCard,
      CDropdownDivider,
      CButton,
      GoogleIcon,
      FacebookIcon,
    },
    name: "LoginForm",

    data() {
      return {
        loggingIn: false,
      };
    },

    created() {
      this.initFacebook();
      this.loadFacebookSDK(document, "script", "facebook-jssdk");
      this.checkIfUserIsLoggedIn();
    },

    methods: {
      submitHandler: function (values) {
        this.loggingIn = true;
        let formData = {
          email: values.email,
          password: values.password,
        };
        const path = UrlConfig.urlHelper.hostUrl + "/customer-login";
        const header = UrlConfig.unAuthorizedUrlHeader;
        axios.post(path, formData, header)
            .then((response) => {
              this.loggingIn = false
              setUserDetailInLocalStorage(response);
              this.$router.go();
              router.push("/");
            })
            .catch((error) => {
              let errorResponse = error.response.data;
              this.loggingIn = false
              showErrorResponse(errorResponse);
            });
      },

      async logInWithFacebook() {
        window.FB.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            let token = response.authResponse.accessToken;
            window.FB.api('/me', function(response) {
              toast.success('Logging In as ' + response.name, {
                timeout: 5000,
              });
            });
            socialAuthLogin(token,'facebook');
          }else{
            window.FB.login(function(response) {
                if (response.authResponse) {
                  let token  = response.authResponse.accessToken;
                  socialAuthLogin(token,'facebook');
                }
            },{scope: 'public_profile,email'});
          }
        },true);
      },


      initFacebook() {
        const facebookId = urlConfig.socialLogin.facebook_app_id;
        window.fbAsyncInit = function() {
          window.FB.init({
            appId: facebookId,
            cookie: true,
            xfbml: true,
            version: "v13.0",
            status: true,
          });
        };
      },

      loadFacebookSDK(d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      },

      checkIfUserIsLoggedIn() {
        if (localStorage.getItem("token") !== null) {
          router.push("/");
        }
      },
    }
  };

  function socialAuthLogin(token,type){
    let path = '';
    if(type === 'facebook'){
      path = UrlConfig.socialLogin.facebook_login_url+"?code="+token;
    }else{
      path = UrlConfig.socialLogin.google_login_url+"?code="+token
    }
    const header = UrlConfig.unAuthorizedUrlHeader;
    axios.get(path,header)
        .then((response) => {
          setUserDetailInLocalStorage(response);
          router.go();
          router.push("/");
        })
        .catch((error) => {
          toast.error('something went wrong', {
            timeout: 2000,
          });
        });
  }

  function setUserDetailInLocalStorage(response)  {
    let userDetail = response.data.data;
    if (response.data.status_code === 200 && userDetail !== "") {
      const userToken = userDetail.token;
      const avatar = userDetail.profile_image_path;
      const userType = userDetail.user_type;
      localStorage.setItem(
          "USERDETAILS",
          JSON.stringify({
            firstName: userDetail.first_name,
            lastName: userDetail.last_name,
          })
      );
      localStorage.setItem("token", userToken);
      localStorage.setItem("avatar", avatar);
      localStorage.setItem("user_type", userType);
    }
  }

  function showErrorResponse(errorResponse){
    if (errorResponse.data !== "") {
      let loop = 0;
      Object.entries(errorResponse.data.error).forEach(([key, value]) => {
        loop = loop + 1;
        let errorTimeAdd = 1000 * parseInt(loop);
        toast.error(value, {
          timeout: 2000 + parseInt(errorTimeAdd),
        });
      });
    } else {
      toast.error(errorResponse.message ? errorResponse.message : 'something went wrong', {
        timeout: 8000,
      });
    }
  }

</script>

<script setup>

  const callback = (response) => {
    let token = response.credential;
    if(token){
      socialAuthLogin(token,'google');
    }else{
      toast.error('something went wrong', {
        timeout: 2000,
      });
    }
  }

</script>

<style lang="scss" scoped></style>
