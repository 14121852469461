<template>
  <shop-header />
  <slot></slot>
  <app-footer />
</template>

<script>
import AppHeader from "@/components/layout-components/app-header/AppHeader.vue";
import AppFooter from "@/components/layout-components/app-footer/AppFooter.vue";
import ShopHeader from "../components/layout-components/app-header/ShopHeader.vue";
export default {
  components: { AppHeader, AppFooter, ShopHeader },
  name: "EcommerceLayout",
};
</script>

<style lang="scss" scoped></style>
