<template>
  <div class="user-profile-content">
    <CCard class="p-4">
      <heading title="Notifications"/>
      <div>
        <ul v-for="(datum, key) in notifications">
          <li
              class="d-flex align-items-center justify-content-between notification-pg-list"
          >
            <div class="d-flex">
              <div class="notif-icon-holder me-2">
                <user-icon/>
              </div>
              <div class="notif-content">
                <div class="notif-heading">{{ datum.details }}.</div>
                <p>({{ datum.date }})</p>
              </div>
            </div>
            <div class="btn-clear">
              <button @click.prevent="deleteNotifications(datum, key)">
                x
              </button>
            </div>
          </li>
        </ul>

        <div class="text-center" v-show="notifications.length > 0">
          <button class="btn btn-text" @click.prevent="deleteAllNotifications">
            Clear All
          </button>
        </div>
      </div>
      <Pagination
          :data="pageDetail"
          @pagination-change-page="getNotificationDetail"
      />
    </CCard>
  </div>
  <CToaster placement="bottom-end">
    <CToast :delay="time" v-for="(toast, index) in toasts" :key="index">
      <CAlert class="m-0" :color="toast.color"> {{ toast.content }}</CAlert>
    </CToast>
  </CToaster>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import InfoTile from "@/components/shared-components/info-tile/InfoTile.vue";
import WishlistIcon from "@/assets/svg/header-icons/WishlistIcon.vue";
import NotificationIcon from "@/assets/svg/header-icons/NotificationIcon.vue";
import UserIcon from "@/assets/svg/header-icons/UserIcon.vue";
import {ref} from "vue";
import Pagination from "laravel-vue-pagination";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  components: {
    Heading,
    InfoTile,
    WishlistIcon,
    NotificationIcon,
    UserIcon,
    Pagination,
  },
  name: "Notifications",

  data() {
    return {
      time: 4000,
      toasts: [],
      toastMessage: "Toast Message",
      toastColor: "success",
      notifications: ref([]),
      pageDetail: ref(""),
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getNotificationDetail();
  },

  methods: {
    async getNotificationDetail(page = 1) {
      const url =
          UrlConfig.urlHelper.hostUrl + "/get-notification?page=" + page;
      const header = UrlConfig.authorizedUrlHeader;
      await axios
          .get(url, header)
          .then((response) => {
            let notificationDetail = response.data.data;
            if (response.data.status_code === 200 && notificationDetail !== "") {
              this.notifications = [];
              let notificationData = notificationDetail.notification;
              this.pageDetail = notificationDetail.page_details;
              Object.entries(notificationData).forEach(([key, value]) => {
                this.notifications.push(value);
              });
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
    },

    async deleteNotifications(notification, key) {
      const url = UrlConfig.urlHelper.hostUrl + "/delete-notification";
      const header = UrlConfig.authorizedUrlHeader;
      const payload = {
        toast: this.toasts,
      };
      let data = {
        notification_id: notification.id,
      };
      await axios
          .post(url, data, header)
          .then((response) => {
            if (response.data.status_code === 200) {
              this.notifications.splice(key, 1);
            }
            this.$store.dispatch("notification/fetchNotificationDetail", payload);
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
    },

    async deleteAllNotifications() {
      const url = UrlConfig.urlHelper.hostUrl + "/clear-notification";
      const header = UrlConfig.authorizedUrlHeader;
      const payload = {
        toast: this.toasts,
      };
      let data = {};
      await axios
          .post(url, data, header)
          .then((response) => {
            if (response.data.status_code === 200) {
              this.notifications = [];
              this.pageDetail = "";
              this.$store.dispatch(
                  "notification/fetchNotificationDetail",
                  payload
              );
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
    },
  },
};
</script>

<style lang="scss" scoped></style>
