<template>
  <CContainer>
    <CRow>
      <CCol md="6" class="mb-md-0 mb-4">
        <ad-horizontal :adData="shopAdDetailOne"/>

      </CCol>
      <CCol md="6">
        <ad-horizontal :adData="shopAdDetailTwo" />
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import AdHorizontal from "@/components/shared-components/ad-horizontal/AdHorizontal.vue";

export default {
  name: "HorizontalAdBlock",
  components: { AdHorizontal },
  props: ['shopAdDetailOne','shopAdDetailTwo']
};
</script>

<style lang="scss" scoped></style>
