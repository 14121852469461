<template>
  
  <item-block v-if="userType === 'CUSTOMER'" />
  <vendor-item-block v-else />

  <template v-if="userType === 'CUSTOMER'">
    <section class="my-5">
      <recommended-item-carousel
        :items="itemsList"
        sectionTitle="Recommended Items"
        subTitle=" own it now, up to 6 months interest free!"
        prevEl="mayLikePrevArrow"
        nextEl="mayLikeNextArrow"
      />
    </section>

    <CModal
      :visible="itemPopupVisible"
      size="lg"
      scrollable="true"
      @close="
        this.$store.dispatch('itemPopup/popupHandler', {
          popupVisible: false,
        })
      "
    >
      <CModalHeader>
        <CModalTitle>Product name</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <item-popup :item="adData" />
      </CModalBody>
    </CModal>
  </template>
</template>

<script>
import { itemsList } from "@/assets/data/itemListing";
import ItemBlock from "@/containers/e-commerce/item-detail/ItemBlock.vue";
import VendorItemBlock from "@/containers/e-commerce/item-detail/VendorItemBlock.vue";
import ItemCarousel from "@/containers/e-commerce/item-carousel/ItemCarousel.vue";
import { ref } from "vue";
import RecommendedItemCarousel from "@/containers/e-commerce/item-carousel/RecommendedItemCarousel";
import { mapState } from "vuex";
import ItemPopup from "../../components/shared-components/item-poup/ItemPopup.vue";

export default {
  components: {
    RecommendedItemCarousel,
    ItemBlock,
    VendorItemBlock,
    ItemCarousel,
    ItemPopup
  },
  name: "ItemDetail",

  data() {
    return {
      userType: ref(""),
      itemsList,   
      componentKey: ref(0) 
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  created() {
    if (
      localStorage.getItem("user_type") !== "" &&
      localStorage.getItem("user_type") === "vendor"
    ) {
      this.userType = "VENDOR";
    } else {
      this.userType = "CUSTOMER";
    }    
  },

  method: {
    popupControl(option) {
      this.itemPopupVisible = option.visible;
      this.selectedItem = option.item;
    },
  },

  computed: {
    ...mapState({
      itemPopupVisible: (state) => state.itemPopup.itemPopupVisible,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
