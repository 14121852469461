<template>
  <svg
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.8021 14.1701C31.095 13.8772 31.095 13.4023 30.8021 13.1094C30.5092 12.8166 30.0344 12.8166 29.7415 13.1094L30.8021 14.1701ZM15.9401 27.9715L15.4097 28.5018C15.7026 28.7947 16.1775 28.7947 16.4704 28.5018L15.9401 27.9715ZM10.3281 21.299C10.0352 21.0061 9.56037 21.0061 9.26748 21.299C8.97459 21.5919 8.97459 22.0668 9.26749 22.3596L10.3281 21.299ZM29.7415 13.1094L15.4097 27.4412L16.4704 28.5018L30.8021 14.1701L29.7415 13.1094ZM9.26749 22.3596L15.4097 28.5018L16.4704 27.4412L10.3281 21.299L9.26749 22.3596ZM37.7113 19.7819C37.7113 29.5444 29.7973 37.4584 20.0349 37.4584V38.9584C30.6257 38.9584 39.2113 30.3728 39.2113 19.7819H37.7113ZM20.0349 37.4584C10.2724 37.4584 2.3584 29.5444 2.3584 19.7819H0.858398C0.858398 30.3728 9.44399 38.9584 20.0349 38.9584V37.4584ZM2.3584 19.7819C2.3584 10.0195 10.2724 2.10547 20.0349 2.10547V0.605469C9.44399 0.605469 0.858398 9.19106 0.858398 19.7819H2.3584ZM20.0349 2.10547C29.7973 2.10547 37.7113 10.0195 37.7113 19.7819H39.2113C39.2113 9.19106 30.6257 0.605469 20.0349 0.605469V2.10547Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "ConfirmIcon",
};
</script>

<style lang="scss" scoped></style>
