<template>
  <p>{{ item }}</p>
  <section class="bg-gray py-5 mb-5">
    <CContainer>
      <CRow>
        <CCol sm="12" lg="3">
          <CCard>
            <ul class="dashboard-side-nav">
              <li v-for="item in navItems" :key="item.id">
                <router-link :to="item.path">{{ item.name }}</router-link>
              </li>
            </ul>
          </CCard>

          <div class="my-4">
            <ad-vertical />
          </div>
        </CCol>
        <CCol class="order-lg-last order-sm-first" sm="12" lg="9">
          <CCard class="p-4">
            <div class="mb-4">
              <heading
                title="Wishlist"
                :description="
                  'There are ' + wishlistData.length + ' products in wishlist'
                "
              />
            </div>

            <div
              v-for="(item, key) in wishlistData"
              class="wishlist-pg-item border-bottom py-3"
            >
              <!-- confirmation -->
              <div
                class="block-confirm bg-danger px-4"
                v-if="selectedCartRow === key"
              >
                <div class="text-white" v-if="deleting">Deleting...</div>
                <div class="confirm-content text-white" v-else>
                  <div class="confirm-message">
                    Are you sure to delete this item from cart.
                  </div>
                  <div class="confirm-action">
                    <CButton
                      color="light"
                      variant="outline"
                      @click.prevent="deleteItemFromWishList(item.id, key)"
                      >Delete</CButton
                    >
                    <CButton
                      class="ms-3"
                      color="light"
                      variant="outline"
                      @click="hanldeConfirmVisibility(null)"
                      >Cancel</CButton
                    >
                  </div>
                </div>
              </div>
              <!-- confirmation -->

              <CRow>
                <CCol md="5">
                  <div>
                    <div class="d-flex">
                      <div class="me-3 item-image">
                        <img :src="item.image" alt="" />
                      </div>
                      <div>
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </CCol>
                <CCol md="7">
                  <div class="items-meta-actions">
                    <div class="items-meta pe-4">
                      <div>
                        {{ item.status }}
                      </div>
                    </div>

                    <div class="action-btns">
                      <button
                        class="btn btn-text me-2"
                        @click="redirectToItemDetailPage(item.slug)"
                      >
                        <cart-icon />
                      </button>

                      <button
                        class="btn btn-text"
                        :disabled="deleting"
                        @click="hanldeConfirmVisibility(key)"
                      >
                        <trash-icon />
                      </button>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </div>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </section>
  <section class="mb-5">
    <recommended-item-carousel
      sectionTitle="Recommended Items"
      subTitle=" own it now, up to 6 months interest free!"
      prevEl="mayLikePrevArrow"
      nextEl="mayLikeNextArrow"
    />
  </section>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import AdverticalTwo from "@/components/shared-components/ad-vertical/AdverticalTwo.vue";
import CartIcon from "@/assets/svg/CartIcon.vue";
import TrashIcon from "@/assets/svg/TrashIcon.vue";
import RecommendedItemCarousel from "@/containers/e-commerce/item-carousel/RecommendedItemCarousel";
import ItemCarousel from "@/containers/e-commerce/item-carousel/ItemCarousel.vue";
import AdVertical from "@/components/shared-components/ad-vertical/AdVertical";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import router from "@/router";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "Wishlist",
  components: {
    AdVertical,
    Heading,
    AdverticalTwo,
    CartIcon,
    TrashIcon,
    ItemCarousel,
    RecommendedItemCarousel,
  },
  data() {
    return {
      navItems: [
        { id: 1, name: "Wishlist", path: "/wishlist" },
        { id: 2, name: "Cart", path: "/cart" },
        { id: 3, name: "Profile", path: "/dashboard/profile" },
        { id: 3, name: "My Orders", path: "/dashboard/my-orders" },
      ],
      wishlistData: ref([]),
      deleting: false,
      selectedCartRow: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getWishListItemDetail();
  },

  methods: {
    hanldeConfirmVisibility(index) {
      this.selectedCartRow = index;
    },
    async getWishListItemDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-wishlist";
      const header = UrlConfig.authorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let wishlistDetail = response.data.data;
          if (response.data.status_code === 200 && wishlistDetail.length > 0) {
            Object.entries(wishlistDetail).forEach(([key, value]) => {
              this.wishlistData.push(value);
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    deleteItemFromWishList(productId, key) {
      this.deleting = true;
      this.$store
        .dispatch("product/removeFromWishlist", {
          product_id: productId,
        })
        .then((data) => {
          this.deleting = false;
          this.selectedCartRow = null;
          this.wishlistData = data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    redirectToItemDetailPage(slug) {
      router.push("/items/" + slug);
    },
  },
};
</script>

<style lang="scss" scoped></style>
