<template>
  <section class="pt-5">

    <CContainer>
      <CRow>
        <CCol md="6">
          <div class="mb-5">
            <h6>{{contactUsSectionDetail.contactUs_title}}</h6>
            <p>{{contactUsSectionDetail.contactUs_description}}</p>
          </div>

          <div class="form">

            <FormKit type="form" id="contactUs" @submit="submitHandler" :actions="false">
              <CRow>
                <CCol md="6" class="mb-4">
                  <FormKit
                    type="text"
                    name="fname"
                    label="First Name"
                    placeholder="First Name"
                    validation="required"
                    validation-visibility="submit"
                  />
                </CCol>
                <CCol md="6" class="mb-4">
                  <FormKit
                    type="text"
                    name="lname"
                    label="Last Name"
                    placeholder="Last Name"
                    validation="required"
                    validation-visibility="submit"
                  />
                </CCol>
                <CCol md="6" class="mb-4">
                  <FormKit
                    type="text"
                    name="email"
                    label="Email Address"
                    placeholder="Email Address"
                    validation="required|email"
                    validation-visibility="submit"
                  />
                </CCol>
                <CCol md="6" class="mb-4">
                  <FormKit
                    type="text"
                    name="phone_number"
                    label="Mobile number"
                    placeholder="Phone Number"
                    validation="required|length:10"
                    validation-visibility="submit"
                  />
                </CCol>
                <CCol md="12" class="mb-4">
                  <FormKit
                    type="textarea"
                    name="message"
                    label="Message"
                    placeholder="Message"
                    validation="required"
                    validation-visibility="submit"
                  />
                </CCol>
              </CRow>
              <div class="">
                <button
                  class="btn btn-primary text-white py-2 px-3"
                  type="submit"
                >
                  <CSpinner
                      v-if="sendingMessage"
                      component="span"
                      size="sm"
                      aria-hidden="true"
                  />

                  {{ sendingMessage ? "sending..." : " Send Message" }}
                </button>
              </div>
            </FormKit>
          </div>
        </CCol>
        <CCol md="6">
          <div class="contact-info mb-4">
            <h4>Head Office</h4>
            <div>Address: {{companyDetail.address}}</div>
            <div>Phone: {{companyDetail.contact_no}}</div>
            <div>Email: {{companyDetail.email}}</div>
            <div v-html="companyDetail.google_map_link">
            </div>
          </div>
        </CCol>
      </CRow>
      <section class="mt-5">
        <CRow>
          <CCol md="4" v-for="(value,index) in showroom">
            <div class="contact-info mb-4">
              <h4>{{value.name}}</h4>
              <div>Address: {{value.address}}</div>
              <div>Email: {{value.email}}</div>
              <div v-html="value.google_map_link" class="mt-4" >
              </div>
            </div>
          </CCol>

          <CCol md="4">
            <div class="contact-info mb-4">
              <h4>{{contactUsSectionDetail.contactUsGetInTouch_title}}</h4>
              <p>
                {{contactUsSectionDetail.contactUsGetInTouch_description}}
              </p>
            </div>
          </CCol>
        </CRow>
      </section>
    </CContainer>
  </section>
</template>

<script>
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "ContactArea",
  props: {
    showroom: Array,
    companyDetail: Object,
    contactUsSectionDetail: Object
  },

  data() {
    return {
      sendingMessage:false,
    };
  },

  methods: {
    submitHandler: function (values) {
      this.sendingMessage = true;
      let formData = {
        first_name: values.fname,
        last_name: values.lname,
        email: values.email,
        phone: values.phone_number,
        message: values.message,
      };
      const path = UrlConfig.urlHelper.hostUrl +'/send-message-contact-us';
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios.post(path,formData,header)
          .then(response => {
            if(response.data.status_code === 200 &&  response.data.message !== '' ) {
              this.$formkit.reset('contactUs')
              toast.success(response.data.message, {
                timeout: 2000,
              });
              this.sendingMessage = false;
            }
          })
          .catch((error) => {
            this.sendingMessage = false;
            let errorResponse = error.response.data;
            if(errorResponse.data !== ''){
              let loop = 0;
              Object.entries(errorResponse.data.error).forEach(([key, value]) => {
                loop = loop +1
                let errorTimeAdd =  1000 * parseInt(loop);
                  toast.error(value, {
                  timeout: 2000 + parseInt(errorTimeAdd) ,
                });
              });
            }else{
              toast.error(errorResponse.message, {
                timeout: 2000,
              });
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped></style>
