<template>
  <svg
    width="20"
    height="27"
    viewBox="0 0 20 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.32422 9.75906C2.32422 7.21334 3.19881 5.33058 4.5364 4.07969C5.88463 2.81885 7.78544 2.12402 9.95926 2.12402C12.1307 2.12402 14.0318 2.82446 15.3812 4.08944C16.7204 5.34485 17.5943 7.2286 17.5943 9.75906C17.5943 13.4597 15.5902 17.2542 13.4648 20.2111C12.4151 21.6714 11.3636 22.8895 10.5745 23.7422C10.3445 23.9908 10.1372 24.2079 9.95925 24.3903C9.78135 24.2079 9.57405 23.9908 9.34398 23.7422C8.55488 22.8895 7.50339 21.6714 6.45374 20.2111C4.32835 17.2542 2.32422 13.4597 2.32422 9.75906ZM9.27963 26.5291C9.27988 26.5293 9.28009 26.5295 9.95926 25.7956L9.28009 26.5295C9.66341 26.8842 10.2551 26.8842 10.6384 26.5295L9.95926 25.7956C10.6384 26.5295 10.6386 26.5293 10.6389 26.5291L10.6395 26.5285L10.6414 26.5267L10.6476 26.521L10.6691 26.5009C10.6874 26.4837 10.7137 26.4588 10.7474 26.4266C10.8148 26.3621 10.9119 26.2681 11.0345 26.1465C11.2796 25.9034 11.6269 25.5496 12.0424 25.1006C12.8724 24.2037 13.9797 22.9214 15.0888 21.3784C17.2809 18.3286 19.5943 14.1049 19.5943 9.75906C19.5943 6.73843 18.5351 4.30465 16.7491 2.63032C14.9732 0.96556 12.5568 0.124023 9.95926 0.124023C7.36407 0.124023 4.94736 0.957075 3.17032 2.61893C1.38264 4.29074 0.324219 6.7255 0.324219 9.75906C0.324219 14.1049 2.63761 18.3286 4.82974 21.3784C5.93884 22.9214 7.04611 24.2037 7.87608 25.1006C8.29157 25.5496 8.6389 25.9034 8.884 26.1465C9.00659 26.2681 9.10371 26.3621 9.17111 26.4266C9.20482 26.4588 9.23111 26.4837 9.24944 26.5009L9.27092 26.521L9.27707 26.5267L9.27898 26.5285L9.27963 26.5291ZM7.25853 9.75906C7.25853 8.26749 8.46768 7.05833 9.95926 7.05833C11.4508 7.05833 12.66 8.26749 12.66 9.75906C12.66 11.2506 11.4508 12.4598 9.95926 12.4598C8.46768 12.4598 7.25853 11.2506 7.25853 9.75906ZM9.95926 5.05833C7.36311 5.05833 5.25853 7.16292 5.25853 9.75906C5.25853 12.3552 7.36311 14.4598 9.95926 14.4598C12.5554 14.4598 14.66 12.3552 14.66 9.75906C14.66 7.16292 12.5554 5.05833 9.95926 5.05833Z"
      fill="#CDCDCD"
    />
  </svg>
</template>

<script>
export default {
  name: "MapMutedIcon",
};
</script>

<style lang="scss" scoped></style>
