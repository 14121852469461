<template>
  <div class="mobile-menu-content">
    <div class="text-end mb-4 mob-nav-close">
      <button @click="handleMenu()">
        <cross-icon-two />
      </button>
    </div>

    <div class="mobile-nav-items p-4">
      <ul class="site-nav">
        <li @click="handleMenu">
          <router-link to="/">Home</router-link>
        </li>

        <li @click="handleMenu">
          <router-link to="/about">About</router-link>
        </li>

        <li @click="handleMenu">
          <router-link to="/shop">Shop</router-link>

          <div class="sub-menus" v-for="(category, index) in categoryDetail">
            <h6>
              <router-link :to="'/category/' + category.slug">
                {{ category.name }}</router-link
              >
            </h6>
            <ul>
              <li v-for="child in category.child_category">
                <router-link :to="'/category/' + child.slug">{{
                  child.name
                }}</router-link>
              </li>
            </ul>
          </div>
        </li>

        <li @click="handleMenu">
          <router-link to="/blogs">Blogs</router-link>
        </li>
        <li @click="handleMenu">
          <router-link to="/contact-us">Contact Us</router-link>
        </li>

        <li @click="handleMenu" v-if="loggedIn">
          <router-link to="/dashboard/settings">Settings</router-link>
        </li>
      </ul>

      <div class="border-top mob-logout-btn" v-if="loggedIn" >
        <button @click.prevent="logoutUser">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>
import CrossIconCricle from "@/assets/svg/CrossIconCricle.vue";
import CrossIconTwo from "@/assets/svg/CrossIconTwo.vue";
import CrossIcon from "@/assets/svg/CrossIcon.vue";
import { ref } from "vue";

export default {
  components: { CrossIconCricle, CrossIconTwo, CrossIcon },
  name: "MobileMenuContent",
  props: ["loggedIn"],
  emits: ['logoutUserMobile'],

  data() {
    return {
      categoryDetail: ref([]),
    };
  },

  mounted() {
    if (this.categoryDetail.length < 1) {
      this.getCategoryDetail();
    }
  },

  methods: {
    getCategoryDetail() {
      try {
        this.$store
          .dispatch("categories/fetchProductCategory")
          .then((categoryData) => {
            if (categoryData.length > 0) {
              Object.entries(categoryData).forEach(([key, value]) => {
                this.categoryDetail.push({
                  id: ++key,
                  name: value.name,
                  slug: value.slug,
                  child_category: value.child_category
                    ? value.child_category
                    : [],
                  image: value.image_path,
                });
              });
            }
          });
      } catch (e) {}
    },

    handleMenu() {
      this.$emit("handleMenu");
      document.body.style.overflow = "";
    },

    logoutUser() {
      this.$emit("logoutUserMobile");
    },
  },
};
</script>

<style lang="scss" scoped></style>
