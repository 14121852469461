<template>
  <section class="item-listing bg-gray pb-5 mb-5">
    <CContainer>
      <CRow>
        <div class="mb-4 category-banner-img">
          <CCol md="12">
            <img :src="shopPageBannerImage" alt="" />
          </CCol>
        </div>
        <Breadcrumb :key="componentKey" :data="breadcrumb"/>

        <div class="mt-4" v-show="isHelmetListing">
          <CCol md="12">
            <div class="border p-4 bg-white mb-4">
              <h6>What type of helmet are you looking for?</h6>

              <div class="category-list-hm-holder mt-3">
                <div v-for="cat in helmetCatList" class="category-list-hm">
                  <img :src="cat.image_path" alt="" />
                  <div class="cat-text mt-3">
                    <div class="cat-text-ttl">
                      {{ cat.name }}
                    </div>
                    <div>
                      <a
                        href=""
                        @click.prevent="getItemByProductSubCat(cat.slug)"
                        >View All</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CCol>
        </div>
        

        <CCol md="4" lg="3">
          <a
            href="javascript:void(0);"
            class="btn btn-primary w-100 xs-show-filter"
            >Show Filters</a
          >
          <CCard class="p-3 mb-4 item-filter">
            <div class="mb-3">
              <h6>Filters</h6>
            </div>
            <!-- checkbox -->

            <div
              class="mb-3 border-bottom pb-4"
              v-if="productCategory.length > 0"
            >
              <div class="ms-2 pt-2">
                <div class="mb-3">
                  <h6>Choose Subcategory</h6>
                </div>
                <div class="checks-holder">
                  <div class="filter-list" v-for="category in productCategory">
                    <label>
                      <input
                        type="checkbox"
                        v-model="category.selected"
                        @change="subcategoryCheck"
                      />
                      {{ category.name }}
                    </label>
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3 border-bottom pb-4" v-if="brands.length > 0">
              <div class="ms-2 pt-2">
                <div class="mb-3">
                  <h6>Choose Brand</h6>
                </div>

                <div class="checks-holder">
                  <div class="filter-list" v-for="brand in brands">
                    <label>
                      <input type="checkbox" v-model="brand.selected" />
                      {{ brand.name }}
                    </label>
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="mb-3 border-bottom pb-4"
              v-if="productModels.length > 0"
            >
              <div class="ms-2 pt-2">
                <div class="mb-3">
                  <h6>Choose Model</h6>
                </div>
                <div class="checks-holder">
                  <div
                    class="filter-list"
                    v-for="productModel in productModels"
                  >
                    <label>
                      <input
                        type="checkbox"
                        v-model="productModel.selected"
                        @change="productModelCheck"
                      />
                      {{ productModel.name }}
                    </label>
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="mb-3 border-bottom pb-4"
              v-if="productColors.length > 0"
            >
              <div class="ms-2 pt-2">
                <div class="mb-3">
                  <h6>Choose Color</h6>
                </div>
                <div class="checks-holder">
                  <div class="filter-list" v-for="datum in productColors">
                    <span
                      class="color-palette-holder color-palette-selected"
                      :style="{ background: `#ff5900` }"
                    ></span>

                    <label>
                      <input type="checkbox" v-model="datum.selected" />
                      {{ datum.name }}
                    </label>
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3 border-bottom pb-4">
              <div class="ms-2 pt-2">
                <div class="mb-3">
                  <h6>Choose Price Range</h6>
                </div>
                <MultiRangeSlider
                  baseClassName="multi-range-slider-bar-only"
                  :minValue="barMinValue"
                  :maxValue="barMaxValue"
                  :max="200000"
                  :min="0"
                  :step="500"
                  :rangeMargin="3000"
                  :ruler="false"
                  minCaption="Min"
                  maxCaption="Max"
                  @input="UpdateValues"
                />

                <div class="d-flex justify-content-between price-indication">
                  <div>Rs. {{ barMinValue }}</div>
                  <div>Rs. {{ barMaxValue }}</div>
                </div>
              </div>
            </div>

            <div class="">
              <CButton
                class="w-100"
                component="input"
                type="button"
                color="primary"
                value="Filter"
                @click.prevent="filterData"
              />
            </div>
          </CCard>
          <div>
            <ad-vertical image="/img/advrt/advrt01.png" />
          </div>
        </CCol>

        <CCol md="8" lg="9">
          <CRow>
            <CCol lg="5" class="mb-lg-0 mb-2">
              <CCol lg="6" class="mb-lg-0 mb-2">
                <select
                  id="priceOrder"
                  name="sortBy"
                  v-model="sortBy"
                  v-on:change="sortByP"
                >
                  <option value="">Choose Order</option>
                  <option value="asc">Price: Low to High</option>
                  <option value="desc">Price: High to Low</option>
                </select>
              </CCol>
            </CCol>
          </CRow>

          <div class="mt-4">
            <CRow>
              <CCol
                class="mb-4"
                sm="6"
                md="6"
                lg="4"
                v-for="(item, index) in categoryItemDetail"
                :key="index"
              >
                <item-card :item="item" />
              </CCol>

              <div class="text-center" v-if="categoryItemDetail.length == 0">
                <div class="no-order-display mx-auto mb-5">
                  <img src="/img/no-order.svg" alt="" />
                </div>

                <div class="greet-text text-center">
                  <p>Product not found !!</p>
                </div>
              </div>

              <div class="loading-overlay" v-if="verifyingSection">
                <h5>
                  <span class="me-3"> <CSpinner color="light" /></span>
                  Searching...
                </h5>
              </div>
            </CRow>
            <Pagination
              :data="pageDetail"
              @pagination-change-page="getCategoryItemByCategorySlug"
            />
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>

  <section class="mb-5">
    <recommended-item-carousel
      sectionTitle="Recommended Items"
      subTitle="own it now, up to 6 months interest free!"
      prevEl="mayLikePrevArrow"
      nextEl="mayLikeNextArrow"
    />
  </section>

  <CModal
    :visible="itemPopupVisible"
    size="lg"
    scrollable="true"
    @close="
      this.$store.dispatch('itemPopup/popupHandler', {
        popupVisible: false,
      })
    "
  >
    <CModalHeader>
      <CModalTitle>Product name</CModalTitle>
    </CModalHeader>

    <CModalBody>
      <item-popup :item="itemDetail" />
    </CModalBody>
  </CModal>
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import AdVertical from "@/components/shared-components/ad-vertical/AdVertical.vue";
import ItemCard from "@/components/shared-components/item-card/ItemCard.vue";
import ItemCarousel from "@/containers/e-commerce/item-carousel/ItemCarousel.vue";
import RecommendedItemCarousel from "@/containers/e-commerce/item-carousel/RecommendedItemCarousel";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import Pagination from "laravel-vue-pagination";
import { useToast } from "vue-toastification";
import MultiRangeSlider from "multi-range-slider-vue";
import ItemPopup from "../../components/shared-components/item-poup/ItemPopup.vue";
import { mapState } from "vuex";
import { useRoute } from "vue-router";
import { helper } from "@/helper/Helper";
import router from "@/router";

const toast = useToast();

export default {
  name: "ItemListing",
  components: {
    AdVertical,
    ItemCard,
    ItemCarousel,
    RecommendedItemCarousel,
    Pagination,
    MultiRangeSlider,
    ItemPopup,
    Breadcrumb,
  },

  data() {
    return {
      categoryItemDetail: ref([]),
      pageDetail: ref(""),
      productCategory: ref([]),
      productSubcategory: ref([]),
      brands: ref([]),
      productModels: ref([]),
      helmetCatList: ref([]),
      productColors: ref([]),
      viewAllCategory: ref([]),
      queryModelData: ref([]),
      queryColorData: ref([]),
      queryBrandData: ref([]),
      queryCategoryData: ref([]),
      barMinValue: 0,
      barMaxValue: 200000,

      sortBy: "",
      verifyingSection: false,
      mainCatId: this.$route.params.id,
      isHelmetListing: false,
      shopPageBannerImage: ref(""),
      pageNo: localStorage.getItem("itemListPage") ?? 1,
      filter: false,   
      componentKey: ref(0)
    };
  },  

  created() {
    this.getPageBanner();
    const route = useRoute();
    this.queryModelData = route.query.model ?? [];
    this.queryColorData = route.query.color ?? [];
    this.queryBrandData = route.query.brand ?? [];
    this.queryCategoryData = route.query.category ?? [];
   
    this.breadcrumb = [
      {label: 'Home', url: '/'},
      {label: 'Shop', url: '/'}
    ];
  },

  updated(){
    //add alt to images
    window.imageALT();
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getCategoryItemByCategorySlug();
  },

  updated() {
    this.getCategoryItemByCategorySlug();
  },

  methods: {
    async sortByP() {
      this.pageDetail = ref("");
      this.getCategoryItemByCategorySlug();
    },

    async getPageBanner() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-page-banner/item_listing";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let pageDetail = response.data.data;
          if (response.data.status_code === 200 && pageDetail !== "") {
            this.shopPageBannerImage = pageDetail.page_image_path;
          }
        })
        .catch((error) => {
          toast.error("Sorry ! some error occurred", {
            timeout: 2000,
          });
        });
    },

    UpdateValues(e) {
      this.barMinValue = e.minValue;
      this.barMaxValue = e.maxValue;
    },

    async getCategoryItemByCategorySlug(page = this.pageNo) {
      localStorage.setItem("itemListPage", page);

      let itemPage = localStorage.getItem("itemListPage");
      this.verifyingSection = true;
      this.categoryItemDetail = [];

      let localStorageCategoryData =
        JSON.parse(localStorage.getItem("category")) ?? [];
      let category =
        this.selectedCategory.length > 0
          ? this.selectedCategory
          : localStorageCategoryData;
      let selectedCategory;
      let selectedModel;
      let selectedBrand;
      let selectedColor;

      if (this.filter) {
        selectedCategory = category?.length > 0 ? category : [];
      } else {
        selectedCategory =
          category?.length > 0
            ? category
            : Array.isArray(this.queryCategoryData)
            ? this.queryCategoryData
            : [this.queryCategoryData];
      }
      let categorySlug =
        selectedCategory.length > 0 ? selectedCategory : [this.mainCatId];

      //console.log("selectedCategory", selectedCategory);

      //categorySlug = [this.mainCatId];

      let model =
        this.selectedProductModel.length > 0
          ? this.selectedProductModel
          : JSON.parse(localStorage.getItem("model"));
      let brand =
        this.selectedBrand.length > 0
          ? this.selectedBrand
          : JSON.parse(localStorage.getItem("brand"));
      let color =
        this.selectedColor.length > 0
          ? this.selectedColor
          : JSON.parse(localStorage.getItem("color"));

      let queryModelData = Array.isArray(this.queryModelData)
        ? this.queryModelData
        : [this.queryModelData];
      let queryBrandData = Array.isArray(this.queryBrandData)
        ? this.queryBrandData
        : [this.queryBrandData];
      let queryColorData = Array.isArray(this.queryColorData)
        ? this.queryColorData
        : [this.queryColorData];

      if (this.filter) {
        selectedModel = model?.length > 0 ? model : [];
        selectedBrand = brand?.length > 0 ? brand : [];
        selectedColor = color?.length > 0 ? color : [];
      } else {
        selectedModel = model?.length > 0 ? model : queryModelData;
        selectedBrand = brand?.length > 0 ? brand : queryBrandData;
        selectedColor = color?.length > 0 ? color : queryColorData;
      }

      const url = UrlConfig.urlHelper.hostUrl + "/get-product-by-category";

      if(this.breadcrumb.length > 2)
      {
        this.breadcrumb.pop();
      }
      this.breadcrumb.push({label: this.mainCatId, url: '*'});
      this.componentKey += 1;

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      await axios
        .get(url, {
          params: {
            page: itemPage,
            product_model_id: selectedModel,
            brand_id: selectedBrand,
            color_id: selectedColor,
            slug: categorySlug,
            min_price: this.barMinValue,
            max_price: this.barMaxValue,
            sort_by_price: this.sortBy,
            search: "",
          },
        })
        .then((response) => {
          localStorage.setItem("model", JSON.stringify(selectedModel));
          localStorage.setItem("brand", JSON.stringify(selectedBrand));
          localStorage.setItem("color", JSON.stringify(selectedColor));
          localStorage.setItem("category", JSON.stringify(selectedCategory));

          let categoryItems = response.data.data.product;
          this.pageDetail = response.data.data.page_details;
          let brands = response.data.data.brand;
          let category = response.data.data.category;
          let productModel = response.data.data.productModel;
          let colorList = response.data.data.color;
          this.helmetCatList = response.data.data.helmet_category_list;
          this.isHelmetListing = response.data.data.is_helmet;

          if (response.data.status_code == 200 && categoryItems.length > 0) {
            Object.entries(categoryItems).forEach(([key, value]) => {
              this.categoryItemDetail.push(value);
            });
          }

          if (category.length > 0) {
            if (this.productCategory.length == 0) {
              let previousFilterCategory =
                JSON.parse(localStorage.getItem("category")) ?? [];
              Object.entries(category).forEach(([key, value]) => {
                this.productCategory.push({
                  key: value.slug,
                  name: value.name,
                  image: value.image_path,
                  selected:
                    previousFilterCategory.length > 0
                      ? this.checkValueInArray(
                          value.slug,
                          previousFilterCategory
                        )
                      : false,
                });
              });
            }
          }

          if (brands.length > 0) {
            if (this.brands.length == 0) {
              let previousFilterBrand =
                JSON.parse(localStorage.getItem("brand")) ?? [];
              Object.entries(brands).forEach(([key, value]) => {
                this.brands.push({
                  key: value.key,
                  name: value.name,
                  selected:
                    previousFilterBrand.length > 0
                      ? this.checkValueInArray(value.key, previousFilterBrand)
                      : false,
                });
              });
            }
          }

          if (productModel.length > 0) {
            if (this.productModels.length == 0) {
              let previousFilterModel =
                JSON.parse(localStorage.getItem("model")) ?? [];
              Object.entries(productModel).forEach(([key, value]) => {
                this.productModels.push({
                  key: value.key,
                  name: value.name,
                  selected:
                    previousFilterModel.length > 0
                      ? this.checkValueInArray(value.key, previousFilterModel)
                      : false,
                });
              });
            }
          }

          if (colorList.length > 0) {
            if (this.productColors.length == 0) {
              let previousFilterColor =
                JSON.parse(localStorage.getItem("color")) ?? [];
              Object.entries(colorList).forEach(([key, value]) => {
                this.productColors.push({
                  key: value.key,
                  name: value.name,
                  selected:
                    previousFilterColor.length > 0
                      ? this.checkValueInArray(value.key, previousFilterColor)
                      : false,
                });
              });
            }
          }

          this.verifyingSection = false;

          const query = {
            ...this.$route.query,
            category: categorySlug,
            brand: selectedBrand,
            model: selectedModel,
            color: selectedColor,
          };
          this.$router.replace({ query });
        })
        .catch((error) => {
          this.verifyingSection = false;
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    async getItemByProductSubCat(subCategorySlug) {
      this.filter = true;
      let category = [];
      category.push(subCategorySlug);
      let helperObj = new helper();
      helperObj.resetLocalStorageProductDataFilter();
      localStorage.setItem("category", JSON.stringify(category));
      this.pageNo = 1;
      this.productCategory = [];
      this.brands = [];
      this.productModels = [];
      this.productColors = [];
      await this.getCategoryItemByCategorySlug();
    },

    checkValueInArray(value, array) {
      return array.includes(value);
    },

    async filterData(e) {
      e.preventDefault();
      this.filter = true;
      let helperObj = new helper();
      helperObj.resetLocalStorageProductDataFilter();
      this.pageNo = 1;
      await this.getCategoryItemByCategorySlug();
    },
  },

  computed: {
    ...mapState({
      itemPopupVisible: (state) => state.itemPopup.itemPopupVisible,
    }),

    selectedCategory() {
      return this.productCategory
        .filter((category) => category.selected)
        .map((category) => category.key);
    },

    selectedProductModel() {
      return this.productModels
        .filter((model) => model.selected)
        .map((model) => model.key);
    },

    selectedBrand() {
      return this.brands
        .filter((brand) => brand.selected)
        .map((brand) => brand.key);
    },

    selectedColor() {
      return this.productColors
        .filter((colors) => colors.selected)
        .map((colors) => colors.key);
    },
  },
};
</script>

<style lang="scss" scoped></style>
