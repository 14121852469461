import axios from "axios";
import UrlConfig from "@/config/UrlConfig";
export default {
  namespaced: true,
  state: {
    itemPopupVisible: false,
    currentItem: {},
  },
  getters: {},
  actions: {
    popupHandler: async (context, payload) => {
      let response;
      if (payload.slug) {
        const url =
          UrlConfig.urlHelper.hostUrl + "/get-product-detail/" + payload.slug;
        const header = UrlConfig.unAuthorizedUrlHeader;
        response = await axios.get(url, header);
      }

      // const data = {
      //   popupVisible: payload.popupVisible,
      //   item: response.data.data,
      // };

      context.commit("SET_ITEMVISIBLE", {
        popupVisible: payload.popupVisible,
        currentItem: response ? response.data.data : undefined,
      });
    },
  },
  mutations: {
    SET_ITEMVISIBLE: (state, data) => {
      state.itemPopupVisible = data.popupVisible;
      state.currentItem = data.currentItem;
    },
  },
};
