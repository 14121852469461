<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.298828"
      y="1.43652"
      width="2"
      height="17.1841"
      rx="1"
      transform="rotate(-45 0.298828 1.43652)"
      fill="black"
    />
    <rect
      width="2"
      height="17.1841"
      rx="1"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 14.0514 1.43652)"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "SearchIcon",
};
</script>
