<template>
  <div class="hn-pagination">
    <ul>
      <li class="pagination-items mx-2 active">1</li>
      <li class="pagination-items mx-2">2</li>
      <li class="pagination-items mx-2">3</li>
      <li class="pagination-items mx-2">4</li>
      <li class="pagination-items mx-2">5</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pagination",
};
</script>

<style lang="scss" scoped></style>
