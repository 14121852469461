<template>
  <section class="pt-5">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <Breadcrumb :key="componentKey" :data="breadcrumb" />
          <div class="text-center">
            <div>
              <h1>{{ generalPageDetail.title }}</h1>
            </div>
          </div>
        </CCol>
        <CCol md="6">
          <div class="rich-text mt-3">
            <p v-html="generalPageDetail.details"></p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
  <newsletter-sub />
</template>

<script>
import Breadcrumb from "@/components/shared-components/breadcrumb/Breadcrumb.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "GeneralPage",
  components: { NewsletterSub, Breadcrumb },

  data() {
    return {
      generalPageDetail: ref(""),
      componentKey: ref(0)
    };
  },

  beforeCreate() {
    this.breadcrumb = [
      { label: "Home", url: "/" }
    ];
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getPageDetail(this.$route.params.page_slug);
  },

  methods: {
    getPageDetail(page_slug) {
      const url =
        UrlConfig.urlHelper.hostUrl + "/get-common-page-details/" + page_slug;
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
        .get(url, header)
        .then((response) => {
          let pageDetail = response.data.data;
          if (response.data.status_code === 200 && pageDetail !== "") {
            this.generalPageDetail = pageDetail;

            this.breadcrumb.push({label: pageDetail.title, url: '*'});
            this.componentKey += 1;

          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
