


export class helper {

    checkLogin() {
        let token = localStorage.getItem('token');
        if(token !== null){
            return true
        }else{
            return false
        }
    }

    resetLocalStorageProductDataFilter(){
        localStorage.removeItem('category');
        localStorage.removeItem('model');
        localStorage.removeItem('color');
        localStorage.removeItem('brand');
        localStorage.removeItem('itemListPage');
    }

    removeUrlQueryParams(){
        const query = this.$route.query;
        delete query.category;
        delete query.model;
        delete query.brand;
        delete query.color;
        // const query = { ...this.$route.query,
        //     category: [],
        //     brand: [],
        //     model: [],
        //     color: [] };
        this.$router.push({ query });
    }
}



