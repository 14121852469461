<template>
  <CContainer>
    <div class="row justify-content-md-center">
      <div class="mb-4">
        <auth-title title="Become a customer" />
      </div>
      <CCol md="7" lg="5">
        <CCard class="p-4">
          <div class="d-flex flex-wrap justify-content-center">
            Already Have an Account?
            <span>              &nbsp;
              <router-link to="/login">Login now!</router-link>
            </span>
          </div>
          <CDropdownDivider class="my-4" />
          <FormKit type="form" @submit="submitHandler" :actions="false">
            <div class="mb-4">
              <FormKit
                type="text"
                name="firstName"
                label="First Name"
                placeholder="First Name"
                validation="required"
                validation-visibility="submit"
              />
            </div>
            <div class="mb-4">
              <FormKit
                  type="text"
                  name="middleName"
                  value=""
                  label="Middle Name"
                  placeholder="Middle Name"
                  validation="nullable"
                  validation-visibility="submit"
              />
            </div>

            <div class="mb-4">
              <FormKit
                type="text"
                name="LastName"
                label="Last Name"
                placeholder="Last Name"
                validation="required"
                validation-visibility="submit"
              />
            </div>
            <div class="mb-4">
              <FormKit
                type="text"
                name="email"
                label="Email Address"
                placeholder="Email Address"
                validation="required"
                validation-visibility="submit"
              />
            </div>
            <div class="mb-4">
              <FormKit
                type="text"
                name="phone"
                label="Phone Number"
                placeholder="Phone Number"
                validation="required"
                validation-visibility="submit"
              />
            </div>
            <div class="mb-4">
              <FormKit
                type="password"
                name="password"
                label="Password"
                placeholder="Password"
                validation="required"
                validation-visibility="submit"
              />
            </div>
            <div class="mb-4">
              <FormKit
                type="password"
                name="password2"
                label="Confirm Password"
                placeholder="Password"
                validation="required"
                validation-visibility="submit"
              />
            </div>
            <div
              class="d-flex flex-wrap align-items-center justify-content-between"
            >
              <router-link class="fw-bold" to="/register"
                >Go back Register</router-link
              >
              <button
                class="btn btn-primary text-white py-2 px-3"
                type="submit"
              >
                Sign Up
              </button>
            </div>
          </FormKit>

<!--            <CDropdownDivider class="my-4" />-->
<!--            <div class="mb-2">Or Register with</div>-->
<!--            <div class="social-buttons">-->
<!--              <CButton class="sigin-google" size="lg">-->
<!--                <google-icon />-->
<!--                <span class="ms-2"> Google </span>-->
<!--              </CButton>-->
<!--              <CButton class="sigin-facebook ms-3" size="lg">-->
<!--                <facebook-icon />-->
<!--                <span class="ms-2"> Facbook </span>-->
<!--              </CButton>-->
<!--            </div>-->
        </CCard>
      </CCol>
    </div>

    <div class="loading-overlay" v-if="registeringNewCustomer">
      <h5>
        <span class="me-3"> <CSpinner color="light" /></span>
        Registering...
      </h5>
    </div>


  </CContainer>

</template>

<script>
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CDropdownDivider,
  CButton,
} from "@coreui/vue";
import GoogleIcon from "@/assets/svg/GoogleIcon.vue";
import FacebookIcon from "@/assets/svg/FacebookIcon.vue";
import AuthTitle from "@/components/shared-components/auth-title/AuthTitle.vue";
import router from "@/router";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    CContainer,
    CRow,
    CCol,
    CCard,
    CDropdownDivider,
    CButton,
    GoogleIcon,
    FacebookIcon,
    AuthTitle,
  },
  name: "CustomerForm",

  data() {
    return {
      registeringNewCustomer: false
    };
  },

  methods: {
    submitHandler: function (values) {
      this.registeringNewCustomer = true;
      let formData = {
            first_name: values.firstName,
            middle_name: values.middleName ?? '',
            last_name: values.LastName,
            email: values.email,
            primary_contact_1: values.phone,
            password: values.password,
            password_confirmation: values.password2,
            user_type: 'customer',
      };
      const path = UrlConfig.urlHelper.hostUrl +'/customer-register';
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios.post(path,formData,header)
      .then(response => {
        const data = response.data.data
        this.registeringNewCustomer = false;
        toast.success('Registered successfully.Please verify your email sent in your mail address before login.', {
          timeout: 10000,
        });
        setTimeout( () => this.$router.push({ path: '/login'}), 10000);
      })
      .catch((error) => {
        let errorResponse = error.response.data;
        this.registeringNewCustomer = false;
        if(errorResponse.data !== ''){
          let loop = 0;
          Object.entries(errorResponse.data.error).forEach(([key, value]) => {
            loop = loop +1
            let errorTimeAdd =  1000 * parseInt(loop);
            toast.error(value, {
              timeout: 2000 + parseInt(errorTimeAdd) ,
            });
          });
        }else{
          toast.error(errorResponse.message, {
            timeout: 2000,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
