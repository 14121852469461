<template>
  <svg
    width="6"
    height="9"
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.83925 8.01374C5.16467 7.6883 5.16467 7.16066 4.83925 6.83522L2.51183 4.50781L4.83925 2.1804C5.16467 1.85498 5.16467 1.32731 4.83925 1.0019C4.51383 0.676479 3.98617 0.676479 3.66075 1.0019L0.744075 3.91856C0.418642 4.24398 0.418642 4.77165 0.744075 5.09706L3.66075 8.01374C3.98617 8.33917 4.51383 8.33917 4.83925 8.01374Z"
      fill="#1B1C20"
    />
  </svg>
</template>

<script>
export default {
  name: "PrevIcon",
};
</script>

<style lang="scss" scoped></style>
