<template>
  <page-banner-two>
    <div class="col-md-7">
      <banner-title
        tagline="KNOW IN DETAIL"
        title="Gift Cards"
        description="Lorem ipsum dolor consectetur adipiscing elit eiusmod tempor elit eiusmod tempor."
      />
    </div>
  </page-banner-two>
  <gift-cards-display />
  <gift-card-things-to-know />
</template>

<script>
import PageBannerTwo from "@/components/shared-components/page-banner/PageBannerTwo.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import GiftCardThingsToKnow from "@/containers/e-commerce/giftcard-things-to-know/GiftCardThingsToKnow.vue";
import GiftCardsDisplay from "@/containers/e-commerce/gift-cards-display/GiftCardsDisplay.vue";
export default {
  components: {
    PageBannerTwo,
    BannerTitle,
    GiftCardThingsToKnow,
    GiftCardsDisplay,
  },
  name: "GiftCards",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
