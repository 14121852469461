<template>
  <div class="notification-drop">
    <ul v-for="(notification, key) in notificationData.slice(0, 4)">
      <li>
        <div class="notif-icon-holder me-2 bg-info"><user-icon /></div>
        <div class="notif-content">
          <div class="notif-heading">{{ notification?.details }}</div>
          <p>({{ notification?.date }})</p>
        </div>
      </li>
    </ul>

    <div class="text-center my-3">
      <router-link to="/dashboard/notifications">View All</router-link>
    </div>
  </div>
</template>

<script>
import WishlistIcon from "@/assets/svg/header-icons/WishlistIcon.vue";
import NotificationIcon from "@/assets/svg/header-icons/NotificationIcon.vue";
import UserIcon from "@/assets/svg/header-icons/UserIcon.vue";
export default {
  name: "NotificationDropdown",
  components: {
    WishlistIcon,
    NotificationIcon,
    UserIcon,
  },

  props: ["notificationData"],
};
</script>

<style lang="scss" scoped></style>
