<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.936 6.55599C20.936 13.2907 11.2297 18.959 11.2297 18.959C11.2297 18.959 1.52258 13.2069 1.52258 6.56963C1.52258 3.85968 3.67963 1.70264 6.37593 1.70264C9.07224 1.70264 11.2293 4.9382 11.2293 4.9382C11.2293 4.9382 13.3863 1.70264 16.0826 1.70264C18.7789 1.70264 20.936 3.85968 20.936 6.55599Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WishlistInactiveIcon",
};
</script>

<style lang="scss" scoped></style>
