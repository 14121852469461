<template>
  <footer class="app-footer pb-5">
    <CContainer>
      <CRow>
        <CCol md="6">
          <CRow>
            <CCol md="4">
              <logo :logo="footerDetail.logo_image_path" class="footer-logo" />
            </CCol>
            <CCol md="8">
              <div class="footer-office-info">
                <span class="tagline">Head Office</span>
                <p>
                  {{ footerDetail.address }}
                </p>
              </div>

              <div class="medium">{{ footerDetail.contact_no }}</div>
              <div class="medium">
                <a :href="'mailto:' + footerDetail.email">
                  {{ footerDetail.email }}</a
                >
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="6">
          <CRow>
            <CCol md="6">
              <div class="mb-4">
                <h6>Quick Links</h6>
              </div>
              <div class="footer-menu">
                <ul>
                  <li>
                    <router-link to="/">Home</router-link>
                  </li>
                  <li>
                    <router-link to="/about">About</router-link>
                  </li>
                  <li>
                    <router-link to="/shop">Shop</router-link>
                  </li>
                  <li>
                    <router-link to="/faqs">Faqs</router-link>
                  </li>
                  <li>
                    <router-link to="/contact-us">Contact</router-link>
                  </li>
                  <li>
                    <router-link to="/blogs">Blogs</router-link>
                  </li>
                </ul>
              </div>
            </CCol>
            <CCol md="6">
              <div class="mb-4">
                <h6>Social</h6>
              </div>
              <div class="footer-menu">
                <ul>
                  <li v-if="footerDetail.facebook_link">
                    <a :href="footerDetail.facebook_link">Facebook</a>
                  </li>

                  <li v-if="footerDetail.twitter_link">
                    <a :href="footerDetail.twitter_link">Twitter</a>
                  </li>

                  <li v-if="footerDetail.instagram_link">
                    <a :href="footerDetail.instagram_link">Instagram</a>
                  </li>

                  <li v-if="footerDetail.youtube_link">
                    <a :href="footerDetail.youtube_link">Youtube</a>
                  </li>
                </ul>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <div class="scroll-up" @click="scrollUp">
        <up-arrow-icon />
      </div>
      <div class="mt-3 text-center">
        <p>
          Copyright © {{ new Date().getFullYear() }} Helmets Nepal. All rights
          reserved.
        </p>
      </div>
    </CContainer>

<!-- template for the modal component -->

      <div v-if="showModal" class="modal-mask hp-modal">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <slot name="header">
                  <button class="modal-default-button" @click="showModal = false">
                     <span>&#10006;</span>
                  </button>
                </slot>
              </div>
              

              <div class="modal-body">
                <slot name="body">
                  <img :src="imagePath" alt="Banner Image" class="hp-banner-image"/>              
                  <h3 v-if="title">{{title}}</h3>
                  <h4 v-if="sub_title">{{sub_title}} </h4>
                  <p v-if="description">
                    {{ description }}
                  </p>                  
                </slot>
              </div>

              <div class="modal-footer">
                <a v-if="link" :href="link">Read More</a>
              </div>
            </div>
          </div>
        </div>




  </footer>
</template>

<style type="text/css">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container img{width: 100%;}

.modal-container {
  width: 40%;
  margin: 0px auto;
  xpadding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  xmargin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.hp-modal img.hp-banner-image{
  width: 100%;
  display: block;
  margin: auto;
  border-radius: 5px;
}

.hp-modal .modal-header{
  justify-content: flex-end;
  padding: 0;
  border: none;
}

.hp-modal .modal-body h3{
  font-weight: 600;
  margin-top: 20px;
}

.hp-modal .modal-body p{
  margin-bottom: 0;
}

.hp-modal .modal-footer{
  border: none;
  justify-content: flex-start;
  padding-top: 0;
  padding-bottom: 0;
}

.hp-modal .modal-footer a{
  background-color: #ec1b23;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.hp-modal .modal-footer a:hover{
  background-color: #bd161c;
}

.hp-modal .modal-header button{
  border: none;
  background-color: transparent;
}

.hp-modal .modal-header button span{
  font-size: 18px;
  position: relative;
  top: 8px;
  right: 10px;
}

@media only screen and (max-width: 1092px) {
  .modal-container {
  width: 80%;
}
}

@media only screen and (max-width: 767px) {
  .modal-container {
  width: 94%;
}
}
</style>

<script>
import UpArrowIcon from "@/assets/svg/UpArrowIcon.vue";
import Logo from "../Logo.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: { Logo, UpArrowIcon },
  name: "AppFooter",

  data() {
    return {
      footerDetail: ref(""),
      showModal: false,
      imagePath: '',
      title: ref(""),
      sub_title: ref(""),
      description: ref(""),
      link: ref(""),
    };
  },

  close(){
    this.showModal = false;
  },

  created() {
    this.getFooterSectionDetail();
    this.getPopupDetail();
  },

  methods: {
    scrollUp: function () {
      window.scrollTo(0, 0);
    },

    getFooterSectionDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-company-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
        .get(url, header)
        .then((response) => {
          let footerData = response.data.data;
          if (response.data.status_code === 200 && footerData !== "") {
            this.footerDetail = footerData;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    getPopupDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-popup-ad-block";
      const header = UrlConfig.unAuthorizedUrlHeader;

      if(this.$route.path === '/'){
        axios
        .get(url, header)
        .then((response) => {
          let footeraData = response.data.data;
          if (response.data.status_code === 200 && footeraData !== "") { 
            if(footeraData.websitePopup.status === "active"){
              this.showModal = true;
              this.imagePath = footeraData.websitePopup.image_path;
              this.title = footeraData.websitePopup.title;
              this.sub_title = footeraData.websitePopup.sub_title;
              this.description = footeraData.websitePopup.description;
              this.link = footeraData.websitePopup.link;
            }           
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
      }      
    },


  },
};
</script>

<style lang="scss" scoped></style>
