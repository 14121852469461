<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="5">
          <div class="image-swiper">
            <div class="item-main-img-holder">
              <swiper
                :breakpoints="swiperOptions"
                :autoHeight="false"
                :slides-per-view="1"
                :space-between="50"
                :navigation="{
                  nextEl: '.prevImageNextArrow',
                  prevEl: '.prevImagePrevArrow',
                }"
              >
                <swiper-slide
                  v-for="(imageDetail, index) in currentItem.product_images"
                  :key="index"
                >
                  <div class="item-preview-img">
                    <img :src="imageDetail.image" alt="" />
                  </div>
                </swiper-slide>
              </swiper>
            </div>

            <div class="text-center mt-4">
              <swiper-nav
                leftClassName="prevImagePrevArrow"
                RightClassName="prevImageNextArrow"
              />
            </div>
          </div>
        </CCol>
        <CCol md="7">
          <div class="item-detail-content">
            <div>
              <h1>
                {{ currentItem?.title }} {{ currentItem.sub_title ? "-" : "" }}
                {{ currentItem?.sub_title }}
              </h1>
            </div>
            <div class="indicators">
              <CRow>
                <CCol md="6">
                  <div class="category">
                    <div class="indi-text">Category</div>
                    <p>{{ currentItem?.category_name }}</p>
                  </div>
                </CCol>
                <CCol md="6" v-show="currentItem?.brand_name !== ''">
                  <div class="brand">
                    <div class="indi-text">Brand</div>
                    <p>
                      <img :src="currentItem?.brand_image" alt="" />
                      {{ currentItem?.brand_name }}
                    </p>
                  </div>
                </CCol>
                <CCol md="12">
                  <div class="item-ratings">
                    <star-rating
                      :rating="2.5"
                      :increment="0.5"
                      @update:rating="setRating"
                      :show-rating="false"
                      :read-only="true"
                      :star-points="[
                        23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                        34, 46, 19, 31, 17,
                      ]"
                      :star-size="15"
                    ></star-rating>

                    <div>
                      <p>Reviews (4)</p>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </div>
            <div class="py-4 mb-4 border-top border-bottom">
              <div>
                <div class="mb-2">
                  <span class="item-price-old me-3">
                    ₨ {{ currentItem?.old_product_price }}
                  </span>
                  <span class="item-price-new">
                    ₨ {{ currentItem?.final_product_price }}
                  </span>
                </div>
                <div class="stock-alerts">
                  <span class="text-success"
                    >{{ itemStockQty }} left in stock</span
                  >
                  - Order Soon.
                </div>
              </div>
            </div>
            <div class="attributes-meta mt-4" >
              <div class="mb-3" v-show="item.sizes.length > 0">
                <div>
                  <h6>Available Sizes</h6>
                  <div>
                    <span
                      v-for="size in item.sizes"
                      :class="
                        selectedAttributes.size === size.id
                          ? 'size-palatte size-palatte-selected  me-3 mb-2'
                          : 'size-palatte me-3 mb-2'
                      "
                      :key="size.id"
                      @click="() => sizeHandler(size)"
                      >{{ size.name }}
                    </span>
                  </div>
                </div>
              </div>
              <div  v-show="item.colors.length > 0">
                <h6>Available Colors</h6>
                <div>
                  <span
                    v-for="color in item.colors"
                    :key="color.id"
                    @click="() => colorHandler(color)"
                    :class="
                      selectedAttributes.color === color.id
                        ? 'color-palatte-holder color-palatte-selected me-3'
                        : 'color-palatte-holder me-3'
                    "
                  >

                    <span
                        class="color-palatte"
                        :style="{
                            background: `linear-gradient(to right, ${color.color[0]} , ${color.color[1]})`,
                          }"
                    ></span>
                  </span>
                </div>
              </div>

              <div class="item-qty mt-4" v-show="itemStockQty > 0">
                <div class="mb-3">
                  <h6>Quantity</h6>
                </div>
                <div class="d-flex">
                  <button class="qty-ctrlor" @click="decreaseItemQty">
                    <minus-icon />
                  </button>
                  <span class="qty-display mx-3" ref="qty">{{ qty }}</span>
                  <button class="qty-ctrlor" @click="increaseItemQty">
                    <plus-icon />
                  </button>
                </div>
              </div>
              <div class="item-purchase-actions mt-4">
                <CButton
                  v-show="itemStockQty > 0"
                  :disabled="addingToCart || buying"
                  class="item-cart me-3"
                  @click="addToCartAPI"
                >
                  <CSpinner
                    v-if="addingToCart"
                    component="span"
                    size="sm"
                    aria-hidden="true"
                  />

                  {{ addingToCart ? "Adding..." : "Add To Cart" }}
                </CButton>

                <button
                    class="item-buynow me-3"
                    v-show="itemStockQty > 0"
                    @click="buyNow()"
                    :disabled="addingToCart || buying"
                >
                  <CSpinner
                      v-if="buying"
                      component="span"
                      size="sm"
                      aria-hidden="true"
                  />  {{ buying ? "wait..." : "Buy Now" }}
                </button>
              </div>
            </div>
          </div>

          <div class="about-item mt-3">
            <h6>About Item</h6>
            <div class="rich-text" v-html="currentItem.details"></div>
          </div>
        </CCol>
      </CRow>
      <CToaster placement="top-end">
        <CToast :delay="time" v-for="(toast, index) in toasts" :key="index">
          <CAlert class="m-0" :color="toast.color">
            {{ toast.content }}
          </CAlert>
        </CToast>
      </CToaster>
    </CContainer>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import MinusIcon from "@/assets/svg/MinusIcon.vue";
import PlusIcon from "@/assets/svg/PlusIcon.vue";
import WishlistActiveIcon from "../../../assets/svg/WishlistActiveIcon.vue";
import WishlistInactiveIcon from "../../../assets/svg/WishlistInactiveIcon.vue";
import { mapState } from "vuex";
import { ref } from "vue";
import router from "@/router";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    Swiper,
    SwiperSlide,
    WishlistActiveIcon,
    WishlistInactiveIcon,
    MinusIcon,
    PlusIcon,
    SwiperNav,
  },
  props: ["item", "popupControl"],
  data() {
    return {
      time: 5000,
      toasts: [],
      toastMessage: "Toast Message",
      toastColor: "success",
      itemImage: ref([]),
      activeImage: "",
      item: {
        colors: ref([]),
        sizes: ref([]),
      },
      selectedAttributes: {
        size: "",
        color: "",
        color_name: "",
        size_name: "",
      },
      qty: 1,
      itemStockQty: 0,
      addingToCart: false,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 2.5,
          spaceBetween: 30,
        },
      },
      buying: false,
    };
  },

  mounted() {
    this.currentItemDetail();
  },

  computed: {
    ...mapState({
      itemPopupVisible: (state) => state.itemPopup.itemPopupVisible,
      currentItem: (state) => state.itemPopup.currentItem.product,
    }),
  },

  methods: {
    currentItemDetail() {
      let itemImageWithColor = this.currentItem.color_product_images;
      let itemSize = this.currentItem.size;
      let productImages = this.currentItem.product_images;

      if (itemImageWithColor.length > 0) {
        this.selectedAttributes.color = itemImageWithColor[0].id;
        this.selectedAttributes.color_name = itemImageWithColor[0].color;
        this.itemStockQty = itemImageWithColor[0].quantity;
        Object.entries(itemImageWithColor).forEach(([key, value]) => {
          this.item.colors.push({
            id: value.id,
            color_name: value.color,
            image: value.image,
            qty: value.quantity,
            color: value.color,
          });
        });
      }

      if (productImages.length > 0) {
        Object.entries(productImages).forEach(([key, value]) => {
          this.itemImage.push({
            id: key,
            image_path: value.image,
          });
        });
      }

      if (itemSize.length > 0) {
        this.selectedAttributes.size = itemSize[0].product_size_id;
        this.selectedAttributes.size_name = itemSize[0].size_name;
        Object.entries(itemSize).forEach(([key, value]) => {
          this.item.sizes.push({
            id: value.product_size_id,
            name: value.size_name,
          });
        });
      }
    },

    decreaseItemQty: function () {
      if (this.qty <= 1) return;
      this.qty--;
    },

    increaseItemQty: function () {
      if (this.qty >= this.itemStockQty) {
        return;
      }
      this.qty++;
    },

    sizeHandler: function (size) {
      this.selectedAttributes = { ...this.selectedAttributes, size: size.id };
      this.selectedAttributes = {
        ...this.selectedAttributes,
        size_name: size.name,
      };
    },

    colorHandler: function (color) {
      this.activeImage = color.image;
      this.selectedAttributes = { ...this.selectedAttributes, color: color.id };
      this.selectedAttributes = {
        ...this.selectedAttributes,
        color_name: color.color_name,
      };
      this.itemStockQty = color.qty
      this.qty = 1
    },

    addToCartAPI() {
      if (localStorage.getItem("token") == null) {
        router.push("/login");
      }
      const payload = {
        cartItemDetail: [
          {
            product_id: this.currentItem.id,
            quantity: this.qty,
            product_color_id: this.selectedAttributes.color,
            product_size_id: this.selectedAttributes.size,
            // product_color: this.selectedAttributes.color_name,
            product_size: this.selectedAttributes.size_name,
            note: "",
            product_custom_attributes: "",
            product_custom_attribute_value: "",
          },
        ],
      };

      this.addingToCart = true;

      this.$store.dispatch("product/addToCart", payload).then((res) => {
        this.addingToCart = false;
        toast.success("Item Added To Cart Successfully", {
          timeout: 2000,
        });
      });
    },

    buyNow(){
      if (localStorage.getItem("token") == null) {
        router.push("/login");
      }
      this.buying = true;
      const payload = {
        cartItemDetail: [
          {
            product_id: this.currentItem.id,
            quantity: this.qty,
            product_color_id: this.selectedAttributes.color,
            product_size_id: this.selectedAttributes.size,
            // product_color: this.selectedAttributes.color_name,
            product_size: this.selectedAttributes.size_name,
            note: "",
            product_custom_attributes: "",
            product_custom_attribute_value: "",
          },
        ],
      };
      this.$store.dispatch("product/addToCart", payload).then((res) => {
        this.buying = false;
        if(res.status == 200){
          router.push("/cart");
        }else{
          toast.error(res.response.data.message, {
            timeout: 9000,
          });
        }
      });
    },
  },

  created() {
    if (
      localStorage.getItem("user_type") !== "" &&
      localStorage.getItem("user_type") === "vendor"
    ) {
      this.userType = "VENDOR";
    } else {
      this.userType = "CUSTOMER";
    }
  },
};
</script>

<style lang="scss" scoped></style>
