<template>
  <CCard class="p-4">
    <heading
      title="Checkout"
      description="Please select your preferred options"
    />

    <div class="mb-4" v-show="deliveryAddressSectionShow">
      <h6>Select Delivery Address</h6>
      <CRow>
        <CCol
          v-for="address in addresses"
          :key="address.type"
          md="6"
          class="mb-md-0 mb-4"
          @click="() => handleAddress(address.type, address.place)"
        >
          <CCard v-if="address.place"
            :class="selectedAddress === address.type ? 'selected-block' : ''"
          >
            <div class="d-flex block-card p-4">
              <div class="me-3">
                <map-muted-icon />
              </div>
              <div>
                <p class="m-0">{{ address.place }}</p>
              </div>
            </div>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <div class="mb-4" v-show="contacts.length > 0">
      <h6>Select Contact Number</h6>
      <CRow>
        <CCol
          v-for="contact in contacts"
          :key="contact.type"
          md="6"
          class="mb-md-0 mb-4 mt-2"
          @click="() => handleContact(contact.type, contact.number)"
        >

          <CCard v-if="contact.number"
            :class="selectedContact === contact.type ? 'selected-block' : ''"
          >
            <div class="d-flex block-card p-4" >

              <div class="me-3">
                <phone-muted-icon />
              </div>
              <div>
                <h6>{{ contact.name }}</h6>
                <p class="m-0">{{ contact.number }}</p>
              </div>
            </div>
          </CCard>
        </CCol>
      </CRow>
    </div>

    <div class="my-4">
      <label>
        <input
          type="checkbox"
          name="termAndCondition"
          v-model="termAndCondition"
        />
        By making this purchase you agree to our
        <router-link :to="'/general-page/terms-and-conditions'"
          >terms and conditions</router-link
        >.
      </label>
    </div>

    <div v-show="placeOrderSection">
      <button
        class="btn btn-primary"
        :disabled="!isActive"
        @click.prevent="proceedToPay"
      >
        <CSpinner
          v-if="proceedToPayment"
          component="span"
          size="sm"
          aria-hidden="true"
        />

        {{ proceedToPayment ? "PROCEEDING..." : "PROCEED TO PAYEMENT" }}
      </button>
      <span class="mx-4">|</span> or Continue
      <router-link to="/">Shopping</router-link>
    </div>
  </CCard>
</template>

<script>
import Heading from "@/components/shared-components/heading/Heading.vue";
import MapMutedIcon from "@/assets/svg/MapMutedIcon.vue";
import PhoneMutedIcon from "@/assets/svg/PhoneMutedIcon.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import router from "@/router";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    Heading,
    MapMutedIcon,
    PhoneMutedIcon
  },
  name: "LoggedInCheckout",

  data() {
    return {
      selectedAddress: "primary",
      selectedContact: "primary_1",
      selectedPlace: ref(""),
      selectedContactNumber: ref(""),
      selectedPaymentType: ref(""),
      addresses: ref([]),
      contacts: ref([]),
      termAndCondition: false,
      placeOrderSection: true,
      proceedToPayment: false,
    };
  },

  mounted() {
    this.getCustomerDeliveryLocationDetail();
  },

  methods: {
    handleAddress(type, place) {
      this.selectedAddress = type;
      this.selectedPlace = place;
    },

    handleContact(type, contactNumber) {
      this.selectedContact = type;
      this.selectedContactNumber = contactNumber;
    },

    async getCustomerDeliveryLocationDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-customer-address";
      const header = UrlConfig.authorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let locationDetail = response.data.data;
          if (response.data.status_code === 200 && locationDetail !== "") {
            let addresses = locationDetail.addresses;
            let contacts = locationDetail.contacts;
            if (addresses.length > 0) {
              this.selectedPlace = addresses[0].place;
              Object.entries(addresses).forEach(([key, value]) => {
                this.addresses.push(value);
              });
            }
            if (contacts.length > 0) {
              this.selectedContactNumber = contacts[0].number;
              Object.entries(contacts).forEach(([key, value]) => {
                this.contacts.push(value);
              });
            }
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    proceedToPay() {
      this.proceedToPayment = true;
      if (this.selectedContactNumber !== "") {
        let cartData = JSON.parse(localStorage.getItem("cartData"));
        let orderItems = [];
        Object.entries(cartData.cartItems).forEach(([key, value]) => {
          orderItems.push({
            product_id: value.id,
            quantity: value.qty,
            product_custom_attributes: value.productCustomAttribute,
            product_custom_attribute_value: value.productCustomAttributeValue,
            product_color_id: value.productColorId,
            product_color: value.productColor,
            product_size_id: value.productSizeId,
            product_size: value.productSize,
          });
        });

        let formData = {
          customer_phone: this.selectedContactNumber,
          customer_address: cartData.deliveryType !== "pickUp" ? this.selectedPlace : "",
          payment_method_name: '',
          payment_transaction_id: "",
          campaign_code: cartData.couponApplied,
          coupon_amount: cartData.discount,
          deliveryCharge_id: cartData.shippingId ?? "",
          deliveryCharge_amount: cartData.shippingCharge,
          sub_total: cartData.cartItemPrice,
          total_amount: cartData.netTotal,
          order_product: orderItems,
        };
        const path = UrlConfig.urlHelper.hostUrl + "/save-order";
        const header = UrlConfig.authorizedHeaderWithoutImage;
        axios
          .post(path, formData, header)
          .then((response) => {
            if (
              response.data.status_code === 200 &&
              response.data.message !== ""
            ) {
              localStorage.removeItem("cartData");
              this.placeOrderSection = false;
              let orderDetail =  response.data.data;
              localStorage.setItem('orderDetail',JSON.stringify(orderDetail))
              router.push('/payment');
            }
            this.proceedToPayment = false;
            this.$store.dispatch("product/clearCart");
          })
          .catch((error) => {
            this.proceedToPayment = false;
            if (error.response.data.message !== "") {
              toast.error(error.response.data.message, {
                timeout: 2000,
              });
            }
          });
      } else {
        this.proceedToPayment = false;
        toast.error("Contact number is missing...", {
          timeout: 3000,
        });
      }
    },

  },

  computed: {
    isActive() {
      if(this.termAndCondition == true) {
        return true;
      }
      return false;
    },

    deliveryAddressSectionShow() {
      let cartData = localStorage.getItem("cartData")
        ? JSON.parse(localStorage.getItem("cartData"))
        : "";
      return cartData.deliveryType == "pickUp" || cartData == '' ? false : true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
