<template>
  <section class="py-5">
    <div class="mb-5">
      <CContainer>
        <CRow class="align-items-end">
          <CCol md="6">
            <CRow>
              <CCol xl="9" xxl="9">
                <section-title
                    tagline="Our Team"
                    :subtitle="subtitle"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="6" class="text-end">
            <router-link to="/career" class="btn btn-primary"
            >Join Our Team
            </router-link
            >
          </CCol>
        </CRow>
      </CContainer>
    </div>
    <CContainer>
      <CRow>
        <CCol
            xs="12"
            sm="6"
            md="3"
            v-for="teamMember in teamMembers"
            :key="teamMember.id"
        >
          <team-member :member="teamMember"/>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import TeamMember from "@/components/shared-components/team-member/TeamMember.vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {useToast} from "vue-toastification";

const toast = useToast();


export default {
  name: "TeamList",
  components: {TeamMember, SectionTitle},


  data() {
    return {
      title: ref(''),
      subtitle: ref(''),
      teamMembers: ref([])
    };
  },

  mounted() {
    this.getTeamListSectionDetail();
    this.getTeamList();
  },

  methods: {
    getTeamListSectionDetail() {
      const url = UrlConfig.urlHelper.hostUrl + '/get-about-us-detail';
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios.get(url, header)
          .then(response => {
            let sectionDetail = response.data.data;
            if (response.data.status_code === 200 && sectionDetail !== '') {
              this.title = sectionDetail.team_title;
              this.subtitle = sectionDetail.team_description;

            }
          }).catch((error) => {
        toast.error(error.response.data.message, {
          timeout: 2000,
        });
      });
    },

    getTeamList() {
      const url = UrlConfig.urlHelper.hostUrl + '/get-team';
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios.get(url, header)
          .then(response => {
            let teamDetail = response.data.data;
            if (response.data.status_code === 200 && teamDetail.length > 0) {
              Object.entries(teamDetail).forEach(([key, value]) => {
                this.teamMembers.push({
                  id: ++key,
                  name: value.name,
                  slug: value.slug,
                  designation: value.designation_name,
                  image: value.image_path,
                });
              });
            }
          }).catch((error) => {
                toast.error('something went wrong', {
                timeout: 2000,
              });
          });
    }
  },
};
</script>

<style lang="scss" scoped></style>
