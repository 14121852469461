export const giftCardsData = [
  {
    id: 1,
    amount: "Rs. 2500.00",
    image: "img/gift-cards/gift-card01.png",
  },
  {
    id: 2,
    amount: "Rs. 4500.00",
    image: "img/gift-cards/gift-card02.png",
  },
  {
    id: 3,
    amount: "Rs. 6500.00",
    image: "img/gift-cards/gift-card03.png",
  },
  {
    id: 4,
    amount: "Rs. 7500.00",
    image: "img/gift-cards/gift-card04.png",
  },
];
