<template>
  <section class="pb-5">
    <CContainer>
      <CRow>
        <CCol class="mb-4" md="4" v-for="career in careerData" :key="career.id">
          <career-card :career="career" />
        </CCol>
      </CRow>
    </CContainer>
<!--    <CContainer>-->
<!--      <CRow>-->
<!--        <CCol md="12" class="mt-4 text-center">-->
<!--          <pagination />-->
<!--        </CCol>-->
<!--      </CRow>-->
<!--    </CContainer>-->
  </section>
  <CToaster placement="top-end">
    <CToast :delay="time" v-for="(toast, index) in toasts" :key="index">
      <CAlert class="m-0" :color="toast.color"> {{ toast.content }} </CAlert>
    </CToast>
  </CToaster>
</template>

<script>
import CareerCard from "@/components/shared-components/career/career-card/CareerCard.vue";
import Pagination from "@/components/shared-components/pagination/Pagination.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "CareerCategories",
  components: { CareerCard, Pagination },

  data() {
    return {
      careerData : ref([])
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getCareerDepartmentDetail();
  },

  methods: {
    getCareerDepartmentDetail(){
      const url = UrlConfig.urlHelper.hostUrl +'/get-career-department-list';
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios.get(url,header)
          .then(response => {
            let careerDepartmentDetail = response.data.data;
            if(response.data.status_code === 200 && careerDepartmentDetail.length > 0){
              Object.entries(careerDepartmentDetail).forEach(([key, value]) => {
                this.careerData.push({
                  id: value.id,
                  title: value.title,
                  openPositions: value.career_count,
                  image: value.image_path
                });
              })
            }
          }).catch((error) => {
              toast.error(error.response.data.message, {
                timeout: 2000,
              });
      });
  }
  },

};
</script>

<style lang="scss" scoped></style>
