<template>
  <header class="app-header">
    <CContainer v-if="showSearch">
      <CRow>
        <CCol md="12">
          <nav-search @show-search="handleShowSearch" />
        </CCol>
      </CRow>
    </CContainer>
    <CContainer v-else>
      <CRow>
        <CCol md="12">
          <div class="header-content">
            <div class="heaader-left">
              <logo :logo="logo" class="header-logo" />
            </div>
            <div class="header-right">
              <shop-nav />
              <header-actions @show-search="handleShowSearch" />
              <header-actions-mobile @show-search="handleShowSearch" />
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </header>
</template>

<script>
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import Logo from "../Logo.vue";
import NavSearch from "../nav-search/NavSearch.vue";
import HeaderActions from "./HeaderActions.vue";
import HeaderActionsMobile from "./HeaderActionsMobile.vue";
import Navigation from "./Navigation.vue";
import { useToast } from "vue-toastification";
import ShopNav from "./ShopNav.vue";
const toast = useToast();

export default {
  components: {
    Logo,
    Navigation,
    HeaderActions,
    HeaderActionsMobile,
    NavSearch,
    ShopNav,
  },
  name: "ShopHeader",

  data() {
    return {
      showSearch: false,
      logo: "",
      isShop: false,
    };
  },

  created() {
    this.getFooterSectionDetail();
  },

  methods: {
    //To handle show/hide Nav search box
    handleShowSearch(value) {
      this.showSearch = value;
    },

    getFooterSectionDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-company-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios
        .get(url, header)
        .then((response) => {
          let footerData = response.data.data;
          if (response.data.status_code === 200 && footerData !== "") {
            this.logo = response.data.data.logo_image_path;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
