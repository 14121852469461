<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4932 6.12776C20.4932 12.8624 10.7869 18.5308 10.7869 18.5308C10.7869 18.5308 1.07983 12.7786 1.07983 6.14141C1.07983 3.43146 3.23688 1.27441 5.93318 1.27441C8.62949 1.27441 10.7865 4.50998 10.7865 4.50998C10.7865 4.50998 12.9436 1.27441 15.6399 1.27441C18.3362 1.27441 20.4932 3.43146 20.4932 6.12776Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WishlistActiveIcon",
};
</script>

<style lang="scss" scoped></style>
