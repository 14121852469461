<template>
  <svg
    width="19"
    height="13"
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.40503 10.9741C0.852745 10.9741 0.405029 11.4218 0.405029 11.9741C0.405029 12.5264 0.852745 12.9741 1.40503 12.9741V10.9741ZM17.405 12.9741C17.9573 12.9741 18.405 12.5264 18.405 11.9741C18.405 11.4218 17.9573 10.9741 17.405 10.9741V12.9741ZM1.40503 5.97412C0.852745 5.97412 0.405029 6.42184 0.405029 6.97412C0.405029 7.52641 0.852745 7.97412 1.40503 7.97412V5.97412ZM17.405 7.97412C17.9573 7.97412 18.405 7.52641 18.405 6.97412C18.405 6.42184 17.9573 5.97412 17.405 5.97412V7.97412ZM1.40503 0.974121C0.852745 0.974121 0.405029 1.42184 0.405029 1.97412C0.405029 2.52641 0.852745 2.97412 1.40503 2.97412V0.974121ZM17.405 2.97412C17.9573 2.97412 18.405 2.52641 18.405 1.97412C18.405 1.42184 17.9573 0.974121 17.405 0.974121V2.97412ZM1.40503 12.9741H17.405V10.9741H1.40503V12.9741ZM1.40503 7.97412H17.405V5.97412H1.40503V7.97412ZM1.40503 2.97412H17.405V0.974121H1.40503V2.97412Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "MenuBarIcon",
};
</script>

<style lang="scss" scoped></style>
