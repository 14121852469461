<template>
    <!-- <brands /> -->
  <section class="item-listing bg-gray pb-5 mb-5">
    <CContainer>
      <CRow>
        <div class="mb-4 category-banner-img brand-banner-img">
          <CCol md="12">
            <!-- <img src="/img/itemban.png" alt="" /> -->
            <img :src="imagePath" alt="Brand Image" />

          </CCol>

          <CCol md="12">
            <h2>
              {{ b_title }}</h2>
          </CCol>

          <CCol md="12">
            {{ b_description }}
          </CCol>

        </div>
        <CCol md="12" lg="12">
          <div class="mt-4">
            <CRow>
              <CCol
                class="mb-4"
                sm="6"
                md="6"
                lg="4"
                v-for="(item, index) in brandItemDetail"
                :key="index"
              >
                <item-card :item="item" />
              </CCol>

              <div class="text-center" v-if="brandItemDetail.length == 0">
                <div class="no-order-display mx-auto mb-5">
                  <img src="/img/no-order.svg" alt="" />
                </div>

                <div class="greet-text text-center">
                  <p>Product not found !!</p>
                </div>
              </div>

              <div class="loading-overlay" v-if="searching">
                <h5>
                  <span class="me-3"> <CSpinner color="light" /></span>
                  Searching...
                </h5>
              </div>
            </CRow>
            <Pagination
              :data="pageDetail"
              @pagination-change-page="getBrandItemByBrandSlug"
            />
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>
<style>
.brand-banner-img img{
  width: 200px;
  margin-bottom: 20px;
}
</style>
<script>
import Brands from "@/containers/business/brands/Brands.vue";
import AdVertical from "@/components/shared-components/ad-vertical/AdVertical.vue";
import ItemCard from "@/components/shared-components/item-card/ItemCard.vue";
import ItemCarousel from "@/containers/e-commerce/item-carousel/ItemCarousel.vue";
import RecommendedItemCarousel from "@/containers/e-commerce/item-carousel/RecommendedItemCarousel";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import Pagination from "laravel-vue-pagination";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "BrandPage",
  components: {
    Brands,
    AdVertical,
    ItemCard,
    ItemCarousel,
    RecommendedItemCarousel,
    Pagination,
  },

  data() {
    return {
      b_title: ref(""),
      b_description: ref(""),
      imagePath: '',
      brandItemDetail: ref([]),
      pageDetail: ref(""),
      searching: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getBrandItemByBrandSlug();
  },

  updated() {
    this.getBrandItemByBrandSlug();
  },

  methods: {
    async getBrandItemByBrandSlug(page = 1) {
      this.searching = true;
      this.brandItemDetail = [];
      let brand_id = this.$route.params.id;
      const url = UrlConfig.urlHelper.hostUrl + "/get-product-by-brand";
      const header = UrlConfig.authorizedHeaderWithoutImage;
       await axios.get(
           url,
           {
             params: {
               slug: brand_id,
               page: page
             },
           },
           header
       ).then((response) => {
          if(response.data.status_code === 200 ){
            let brandItems = response.data.data.product;
            this.pageDetail = response.data.data.page_details;

            this.b_title = response.data.data.brand.name;
            this.b_description = response.data.data.brand.description;
            this.imagePath = response.data.data.brand.image;
            if (brandItems.length > 0) {
              Object.entries(brandItems).forEach(([key, value]) => {
                this.brandItemDetail.push(value);
              });
            }
            this.searching = false;
          }
        })
        .catch((error) => {
          this.searching = false;
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
