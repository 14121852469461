<template>
  <page-banner-two>
    <div class="col-md-7">
      <banner-title
          :tagline="bannerDetail.page_title"
          :title="bannerDetail.page_sub_title"
          :description="bannerDetail.page_title_description"
      />
    </div>
  </page-banner-two>
</template>

<script>
import PageBannerTwo from "@/components/shared-components/page-banner/PageBannerTwo.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  components: {
    PageBannerTwo,
    BannerTitle,
  },
  name: "TeamBanner",

  data() {
    return {
      bannerDetail: ref(""),
      banner_image: ref(""),
    };
  },

  mounted() {
    this.getPageBanner();
  },

  methods: {
    async getPageBanner() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-page-banner/team_detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
          .get(url, header)
          .then((response) => {
            let pageDetail = response.data.data;
            if (response.data.status_code === 200 && pageDetail !== "") {
              (this.bannerDetail = pageDetail),
                  (this.banner_image = pageDetail.page_image_path);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
          });
    },
  },
};
</script>

<style lang="scss" scoped></style>
