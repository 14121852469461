<template>
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.98551 5.66102L1.21783 5.02019L1.98551 5.66102ZM18.4508 5.66102L17.6831 6.30186L17.6831 6.30186L18.4508 5.66102ZM18.4508 10.7823L17.6831 10.1415L17.6831 10.1415L18.4508 10.7823ZM1.98551 10.7823L1.21783 11.4232L1.98551 10.7823ZM2.75319 6.30186C4.35892 4.37828 6.91051 2.22168 10.2181 2.22168V0.22168C6.01099 0.22168 2.9494 2.94588 1.21783 5.02019L2.75319 6.30186ZM10.2181 2.22168C13.5258 2.22168 16.0774 4.37828 17.6831 6.30186L19.2185 5.02019C17.4869 2.94588 14.4253 0.22168 10.2181 0.22168V2.22168ZM17.6831 10.1415C16.0774 12.0651 13.5258 14.2217 10.2181 14.2217V16.2217C14.4253 16.2217 17.4869 13.4975 19.2185 11.4232L17.6831 10.1415ZM10.2181 14.2217C6.91051 14.2217 4.35892 12.0651 2.75319 10.1415L1.21783 11.4232C2.9494 13.4975 6.01099 16.2217 10.2181 16.2217V14.2217ZM17.6831 6.30186C18.6255 7.4308 18.6255 9.01256 17.6831 10.1415L19.2185 11.4232C20.7804 9.55208 20.7804 6.89128 19.2185 5.02019L17.6831 6.30186ZM1.21783 5.02019C-0.344094 6.89128 -0.344095 9.55208 1.21783 11.4232L2.75319 10.1415C1.81079 9.01256 1.81079 7.4308 2.75319 6.30186L1.21783 5.02019ZM12.2181 8.22168C12.2181 9.32625 11.3227 10.2217 10.2181 10.2217V12.2217C12.4273 12.2217 14.2181 10.4308 14.2181 8.22168H12.2181ZM10.2181 10.2217C9.11357 10.2217 8.21814 9.32625 8.21814 8.22168H6.21814C6.21814 10.4308 8.009 12.2217 10.2181 12.2217V10.2217ZM8.21814 8.22168C8.21814 7.11711 9.11357 6.22168 10.2181 6.22168V4.22168C8.009 4.22168 6.21814 6.01254 6.21814 8.22168H8.21814ZM10.2181 6.22168C11.3227 6.22168 12.2181 7.11711 12.2181 8.22168H14.2181C14.2181 6.01254 12.4273 4.22168 10.2181 4.22168V6.22168Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "EyeWhiteIcon",
};
</script>

<style lang="scss" scoped></style>
